import * as React from 'react';
import { AgentViewOutput } from '@coverright/data-access/types/enrollment';
import { PropsWithChildren, useContext, useState } from 'react';

interface IAgentContext {
  agent?: AgentViewOutput,
  setAgent: (agent: AgentViewOutput) => void,
  applyDefaultAgent: () => void,
}

const defaultAgent: AgentViewOutput = {
  id: '',
  email: 'richardc@coverright.com',
  firstName: 'Richard',
  lastName: 'Chan',
  phone: '+1 (646) 315-6206',
  picUrl: 'https://cdn.coverright.com/agent-avatar/2426decb-7529-4648-a4ce-42bb4f1d35e9.png',
  enrollmentMeetingUrl: 'https://calendly.com/coverright-advisors/enrollment',
  planReviewUrl: 'https://calendly.com/coverright-advisors/medicare-consultation-client',
  consultationUrl: 'https://calendly.com/coverright-advisors/medicare-consultation-client',
}

export const AgentContext = React.createContext<IAgentContext>({
  setAgent: () => {},
  applyDefaultAgent: () => {}
});

export function AgentContextProvider({children}: PropsWithChildren) {
  const [agent, setAgent] = useState<AgentViewOutput>();

  const applyDefaultAgent = () => {
    setAgent(defaultAgent);
  }

  return <AgentContext.Provider value={{agent, setAgent, applyDefaultAgent}}>
    {children}
  </AgentContext.Provider>
}

export const useClientAgent = () => {
  return useContext(AgentContext);
}
