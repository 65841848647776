import {keycloakConfig} from './config';
import Keycloak, {KeycloakInitOptions} from 'keycloak-js'
import * as _ from "lodash";

export let keycloak = new Keycloak(keycloakConfig);

keycloak.onAuthRefreshError = () => {
  console.log('onAuthRefreshError');
  // it made infinite reload.
  // keycloak.logout();
};

keycloak.onTokenExpired = () => {
  keycloak.updateToken(10)
};

export const initKeycloak = async (options: KeycloakInitOptions = {}) => {
  addTokensToSessionStorageFromUrl();

  await keycloak.init({
    token: sessionStorage.getItem('token') || undefined,
    refreshToken: sessionStorage.getItem('refreshToken') || undefined,
    idToken: sessionStorage.getItem('idToken') || undefined,
    onLoad: "check-sso",
    checkLoginIframe: sessionStorage.getItem('clif') !== 'false',
    silentCheckSsoRedirectUri: window.location.origin,
    ...options
  });

  (window as any).keycloak = keycloak;

  return keycloak;
}

export const initKeycloakIfAuthenticated = async (options: KeycloakInitOptions = {}) => {
  const value = await initKeycloak(options);

  if (value.authenticated) {
    (window as any).keycloak = value;
    return value;
  } else {
    keycloak = new Keycloak(keycloakConfig);
    (window as any).keycloak = keycloak;
    keycloak.onAuthRefreshError = () => {
      console.log('onAuthRefreshError');
      // it made infinite reload.
      // keycloak.logout();
    };

    keycloak.onTokenExpired = () => {
      keycloak.updateToken(10)
    };
    return keycloak;
  }
}

export const initKeycloakAndAuthorize = async (options: KeycloakInitOptions = {}) => {
  const value = await initKeycloak({ ...options, checkLoginIframe: false });

  const token = options.token || keycloak.token;

  if (!token) {
    throw new Error('Unable to initialize keycloak token');
  }

  return new Promise<Keycloak>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${keycloakConfig.url}realms/${keycloakConfig.realm}/set-cookie-by-token`, true);
    xhr.setRequestHeader('authorization', 'Bearer ' + token);
    xhr.withCredentials = true;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          resolve(value)
        } else {
          // Oh no! There has been an error with the request!
          reject(xhr.responseText)
        }
      }
    };
    xhr.send();
  })
}

export const getHash = () => {
  if (keycloak.authenticated) {
    return `#at=${keycloak?.token}&rt=${keycloak?.refreshToken}&it=${keycloak?.idToken}`
  }
  return '';
}

export const getIsAdmin = (keycloak: Keycloak) => keycloak.hasRealmRole(KeycloakRoles.ROLE_ADMIN) || keycloak.hasRealmRole(KeycloakRoles.ROLE_TECH_STAFF)

const addTokensToSessionStorageFromUrl = () => {
  if (isAuthInHash()) {
    const auth = _.fromPairs(document.location.hash.slice(1).split('&').map(v => v.split('=')));
    sessionStorage.setItem('token', auth.at);
    sessionStorage.setItem('refreshToken', auth.rt);
    sessionStorage.setItem('idToken', auth.it);

    if (auth.clif) {
      sessionStorage.setItem('clif', auth.clif);
    }

    window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
  }
}

const isAuthInHash = (): boolean => {
  return document.location.hash.indexOf('at=') >= 0 && document.location.hash.indexOf('rt=') >= 0 && document.location.hash.indexOf('it=') >= 0
}


export enum KeycloakRoles {
  ROLE_PUBLIC_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ALFA = 'ROLE_ALFA',
  ROLE_TECH_STAFF = 'ROLE_TECH_STAFF',
}

export const getToken = () => {
  return new Promise<string | undefined>((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch((e: any) => {
        reject(e);
      })
  });
}

export const createLoginUrl = (redirectUri = document.location.href) => {
  return  `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/auth?client_id=${keycloakConfig.clientId}&redirect_uri=${encodeURI(redirectUri)}&response_mode=fragment&response_type=code&scope=openid`
}
