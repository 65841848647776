import { DrugDosage, PreferredDrugOutput } from './types';
import {
  CalcDrugFrequencyPeriodInput,
  FrequencyPeriodInput,
  PreferredDrugInput,
} from '@coverright/data-access/types/enrollment';
import {
  CalcDrugFrequencyPeriod,
  DrugInfoWithPack,
  DrugOutput,
} from '@coverright/data-access/types/medicare';
import { DrugInfoWithPackagesFlags } from '@coverright/data-access/types/medicare';

export function convertPreferredDrugInput(
  drugInfo: PreferredDrugOutput
): PreferredDrugInput {
  return {
    dosageRxcui: drugInfo.dosageRxcui,
    productRxcui: drugInfo.productRxcui,
    quantity: drugInfo.quantity,
    frequency: drugInfo.frequency as unknown as CalcDrugFrequencyPeriodInput,
    purchaseFrequency:
      drugInfo.purchaseFrequency as unknown as FrequencyPeriodInput,
  };
}

export const toPreferredDrugOutput = (
  drug: DrugOutput
): PreferredDrugOutput => ({
  productRxcui: drug.rxcui,
  dosageRxcui: drug.packRxcui,
  frequency: drug.frequency,
  purchaseFrequency: drug.purchaseFrequency,
  quantity: drug.quantity,
});

export const hasNoPackages = (
  isGeneric = false,
  drugPackagesInfo?: DrugInfoWithPackagesFlags | null
) =>
  (!isGeneric && !drugPackagesInfo?.hasBrandedPackages) ||
  (isGeneric && !drugPackagesInfo?.hasGenericPackages);

export const toOutput = (
  result: DrugDosage,
  drugsWithPackage: DrugInfoWithPack
): PreferredDrugOutput => ({
  frequency: result.frequency as CalcDrugFrequencyPeriod,
  quantity: result.quantity as number,
  purchaseFrequency: result.purchaseFrequency,
  dosageRxcui: drugsWithPackage.packRxcui as string,
  productRxcui: drugsWithPackage.rxcui as string,
});
