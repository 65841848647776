import { ProviderOutput } from '@coverright/data-access/types/medicare';
import { Box, styled, Typography } from '@mui/material';
import { PlusCircle } from '@phosphor-icons/react';
import * as React from 'react';

type Props = {
  doctor: ProviderOutput;
  onClick: () => void;
};

export function DoctorAutocompleteOption(props: Props) {
  return (
    <Wrapper
      data-test={'doctor_card_' + props.doctor.npi}
      onClick={props.onClick}
    >
      <div>
        <Typography className={`bold lh-24 doctor-name mb-4`}>
          {props.doctor.name}
        </Typography>
        <Typography className={'lh-24'} color={'textSecondary'}>
          {props.doctor.addresses[0]?.specialty}
        </Typography>
      </div>
      <Box
        className={'add-icon'}
        sx={{ alignItems: 'center', display: 'none', gap: 1 }}
      >
        <PlusCircle size={16} color="#1C434F" weight="regular" />
        <Typography className={'medium'} sx={{ color: '#1C434F' }}>
          Add
        </Typography>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 16,
  '&:hover': {
    background: '#F5F5F5',
    '& .add-icon': {
      display: 'flex',
    },
  },
});
