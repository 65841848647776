import { DrugOutput } from '@coverright/data-access/types/medicare';
import { Box, Button, IconButton, styled, Typography } from '@mui/material';
import * as _ from 'lodash';
import { XCircle } from '@phosphor-icons/react';
import * as React from 'react';

type Drug = DrugOutput & { notSupported: boolean };

type Props = {
  drug: Drug;
  onEditDrugClick: (drug: Drug) => void;
  onDeleteClick: (drug: Drug) => void;
};

export const DrugCard = React.memo(
  ({ drug, onEditDrugClick, onDeleteClick }: Props) => {
    return (
      <Wrapper
        data-test={'drug_card_' + drug.name}
        sx={{ opacity: drug.notSupported ? 0.3 : 1 }}
      >
        <Content>
          <Box>
            <Typography className={`bold lh-24 drug-name mb-8`}>
              {drug.name} {drug.notSupported ? '(not supported)' : ''}
            </Typography>
            <Typography sx={{ maxWidth: 500 }} className={'lh-24'}>
              {drug.packName}
            </Typography>
          </Box>
          {drug.quantity && (
            <AdditionalContent>
              <Typography className={`lh-24 mb-8`}>
                {drug.isGeneric ? 'Generic' : drug.genericName || 'Branded'}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'center', md: 'flex-end' },
                  gap: { xs: 1, md: 0.5 },
                  flexDirection: { md: 'column' },
                }}
              >
                <Typography className={`lh-24`}>
                  {drug.quantity} {_.upperFirst(_.lowerCase(drug.frequency))}
                </Typography>

                {!drug.notSupported && (
                  <Button
                    variant={'text'}
                    size={'small'}
                    onClick={() => onEditDrugClick(drug)}
                    id={'edit-drug-button'}
                    data-test={'edit-drug-button'}
                  >
                    Edit drug
                  </Button>
                )}
              </Box>
            </AdditionalContent>
          )}
        </Content>
        <IconButton
          onClick={() => onDeleteClick(drug)}
          data-test={'delete-button'}
        >
          <XCircle size={24} color="#460C1D" weight="fill" />
        </IconButton>
      </Wrapper>
    );
  }
);

const Wrapper = styled(Box, {
  name: 'DrugSelector',
  slot: 'drugCard',
})({
  display: 'flex',
  padding: '16px 16px 12px',
  borderRadius: '8px',
  alignItems: 'center',
  gap: 16,
  position: 'relative',
});

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flex: 8,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const AdditionalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 24,
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
    textAlign: 'right',
    marginTop: 0,
  },
}));
