import React from "react";
import * as QueryString from "query-string";

export function useUrlParams(params: string[]) {

  const [values, setValues] = React.useState<IValues>({})
  const parsedQuery = QueryString.parse(document.location.search);
  React.useEffect(() => {
    setValues(params.reduce((prev: IValues, key: string) => {
      return {...prev, [key]: parsedQuery[key] || undefined } as IValues
    }, {} as IValues))
  }, [])

  return values;
}

interface IValues {
  [key: string]: string | undefined | string[]
}
