import { MenuItem } from '@mui/material';
import { SelectInput } from '@coverright/ui/inputs';
import * as React from 'react';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  defaultValue: string;
  onChange: (value: string) => void;
  options: { countyname: string; state: string }[];
}

export function CountySelect(props: Props): React.ReactElement {
  const { error, options, onChange, defaultValue, loading, disabled } = props;
  return (
    <SelectInput
      fullWidth
      hideTick
      disabled={disabled}
      error={!!error}
      helperText={error}
      label={'County'}
      defaultValue={defaultValue}
      data-test={'county'}
      placeholder={'Select county'}
      loading={loading}
      onChange={(event: any) => {
        onChange(event.target.value);
      }}
    >
      {options.map((city) => (
        <MenuItem
          key={city.countyname}
          data-test={'county-item'}
          value={city.countyname}
        >
          {city.countyname}, {city.state}
        </MenuItem>
      ))}
    </SelectInput>
  );
}
