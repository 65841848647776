import { DrugInfoWithPack } from '@coverright/data-access/types/medicare';
import * as React from 'react';
import { useDrugsQuery } from '@coverright/data-access/medicare';
import { CRAutocomplete } from '@coverright/ui/inputs';
import { DrugOption } from './drug-option';
import { DrugAutocompleteInput } from './drug-autocomplete-input';
import { useMemo } from 'react';
import { useDebouncedMemo } from '@coverright/utils';
import { CircularProgress } from '@mui/material';

export function DrugAutocomplete(props: {
  label?: string;
  onSelect: (drug: DrugInfoWithPack) => void;
}) {
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const deferredValue = useDebouncedMemo(autocompleteValue, 300);
  const { data, loading } = useDrugsQuery({
    variables: { name: deferredValue },
    skip: !deferredValue,
  });

  const [open, setOpen] = React.useState(true);

  const autocompleteOpen = useMemo(() => {
    return !!autocompleteValue && !loading && !!data?.drugs && open;
  }, [autocompleteValue, loading, data, open]);

  return (
    <CRAutocomplete
      fullWidth
      open={autocompleteOpen}
      onClose={() => {
        setTimeout(() => setOpen(false), 300); // todo: hack - fix closing autocomplete by blur
      }}
      onFocus={() => setOpen(true)}
      options={data?.drugs || []}
      inputValue={autocompleteValue}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        setAutocompleteValue(newInputValue);
      }}
      popupIcon={loading ? <CircularProgress size={20} sx={{ mr: 1 }} /> : null}
      getOptionLabel={(d: any) => d?.name || ''}
      renderOption={(e, drug: any) => (
        <DrugOption
          key={drug.rxcui}
          onClick={() => props.onSelect(drug)}
          drug={drug}
        />
      )}
      renderInput={(params) => (
        <DrugAutocompleteInput
          params={params}
          label={props.label || 'Search drug name:'}
        />
      )}
    />
  );
}
