import { HttpLink, ApolloClient, InMemoryCache, from, createQueryPreloader, InMemoryCacheConfig } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { errorTracker, graphQLErrorHandler } from './GraphQLErrorHandler';
import {getToken} from "@coverright/shared/keycloak";

const httpLink = new HttpLink({
  uri: process.env.NX_GRAPHQL,
  headers: {
    "content-type": "application/json",
     // version: config.buildVersion,
  }
});


const authLink = setContext(async (_, { headers }: any) => {
  let token: string;
  try {
    token = await getToken() as string
  } catch (e) {
    token = '';
  }
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export enum GraphQLErrorType {
  NO_CONNECTION = 'NO_CONNECTION',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  INVALID_EMAIL = 'INVALID_EMAIL',
  ERROR_STATUS = 'ERROR_STATUS'
}

export const getApolloClient = (getTokenFunc: () => Promise<string | undefined>, uri?: string, inMemoryCacheConfig: InMemoryCacheConfig = {}) => {
  const httpLink = new HttpLink({
    uri: uri || process.env.NX_GRAPHQL,
    headers: {
      "content-type": "application/json",
    }
  });

  const auth = setContext(async (_, { headers }: any) => {
    let token: string | undefined;
    try {
      token = await getTokenFunc();
    } catch (e) {
      token = '';
    }
    if (token) {
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          ...headers,
        }
      };
    } else {
      return {
        headers
      };
    }
  });
  const link =
    from([
      auth,
  //    elink,
      httpLink,
    ])
  ;

  const cache = new InMemoryCache(inMemoryCacheConfig);

  const clientInstance = new ApolloClient({
    link,
    cache,
    connectToDevTools: true
  });
  const preloadQuery = createQueryPreloader(clientInstance);

  return { client: clientInstance, preloadQuery, cache };
}
