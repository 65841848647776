import { Box, Typography } from '@mui/material';
import { Confetti } from '@phosphor-icons/react';
import * as React from 'react';

export default function DiscountBanner() {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: '#F9E69A',
        p: 3,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Confetti size={70} color="#000" weight="duotone" />
        <Typography className={'fs-40 epilogue semi-bold'}>Discount</Typography>
        <Confetti size={70} color="#000" weight="duotone" />
      </Box>
      <Typography sx={{ maxWidth: 523, textAlign: 'center' }}>
        We’re excited to bring you offers from our partners to give you more
        healthcare options and help keep your costs down.
      </Typography>
    </Box>
  );
}
