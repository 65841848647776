import { FilterItem } from '@coverright/data-access/types/medicare';
import { getZipCountyFips } from '@coverright/data-access/medicare';
import { client } from '@coverright/data-access/apollo-clients';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export const useElevanceCompanies = (zip?: string) => {
  const [companies, setCompanies] = useState<FilterItem[]>([]);

  useEffect(() => {
    if (zip) {
      getElevanceCompanies(zip).then(setCompanies);
    }
  }, [zip]);

  return companies;
}

export const getElevanceCompanies = async (zip?: string) => {
  if (zip) {
    const {data: {plans_zip_county_fips}} = await getZipCountyFips(client, zip);
    if (plans_zip_county_fips.length) {
      const {state} = plans_zip_county_fips[0];
      return getCompanies(state)
    }
  }
  return []
}


const getCompanies = (state: string): FilterItem[] => {
  const mapping: {[key: string]: string[]} = {
    "Anthem Blue Cross and Blue Shield": ['NV', 'CO', 'WI', 'MO', 'KY', 'OH', 'IN', 'GA', 'VA', 'ME', 'CT', 'NH', 'NY'],
    "Anthem Blue Cross": ['CA', 'NY'],
    "Healthy Blue": ['LA'],
    "Optimum HealthCare": ['FL'],
    "Wellpoint": ['WA', 'AZ', 'TX', 'IA', 'TN', 'NJ'],
    "Simply": ['FL'],
    "Freedom Health": ['FL'],
    "HealthSun": ['FL'],
  }

  if (_.flatten(Object.values(mapping)).includes(state)) {
    return Object.entries(mapping)
      .filter(([key, value]) => value.includes(state))
      .map(([key, value]) => ({
        key, name: key, count: ["HealthSun", "Freedom Health", "Optimum HealthCare"].includes(key) ? 0 : -1
      }));
  }

  return []
}
