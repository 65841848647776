import {
  Collapse,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateInput, SelectInput } from '@coverright/ui/inputs';
import * as React from 'react';
import { useFormik } from 'formik';
import { getStoredUser } from '@coverright/utils';
import * as yup from 'yup';
import * as _ from 'lodash';
import { ZipSelector } from '@coverright/features';
import * as moment from 'moment';
import { Gender } from '@coverright/data-access/types/medigap';

interface CollectInfoFormProps {
  fields?: string[];
  onSubmit: (values: any) => void;
  onValidChange?: (value: boolean) => void;
  setFormik: any;
  direction?: 'row' | 'column';
  forceOpen?: boolean;
  showFilled?: boolean;
  showZipNotification?: boolean;
  notRequired?: boolean;
}

const availableFields = ['zip', 'countyName', 'birthDate', 'gender', 'tobacco'];

const schemaFields: any = {
  zip: yup.string().required('Zip is required'),
  countyName: yup.string().required('County name is required'),
  state: yup.string(),
  birthDate: yup
    .string()
    .nullable(true)
    .test('birthDate', 'Birth date is not valid', (value) => {
      if (value === null) {
        return true;
      }
      const val = (moment as any).default(value);
      return (
        val.isValid() &&
        val.isBetween(
          (moment as any).default(new Date(1900, 0, 1)),
          (moment as any).default()
        )
      );
    })
    .typeError('Birth date is required')
    .required('Birth date is required'),
  gender: yup.string().required('Gender is required'),
  tobacco: yup.string().required('Tobacco status is required'),
};

export const CollectInfoForm = (props: CollectInfoFormProps) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const fields = React.useMemo(() => {
    return props.fields || availableFields;
  }, [props.fields]);

  const renderFields = React.useMemo(() => {
    let f = props.showFilled
      ? fields
      : fields.filter((f) => !(getStoredUser() || ({} as any))[f]);
    if (f.includes('zip') && f.includes('countyName')) {
      f = _.without(f, 'countyName');
    } else if (!f.includes('zip') && f.includes('countyName')) {
      f = _.without(f, 'countyName');
      f = ['zip', ...f];
    }
    return f;
  }, [fields, props.showFilled]);

  const formik = useFormik({
    initialValues: _.fromPairs(
      fields.map((field) => [
        field,
        ((getStoredUser() || {}) as any)[field] || '',
      ])
    ),
    validationSchema: yup.object(
      _.fromPairs(
        fields.map((field) => [
          field,
          props.notRequired
            ? schemaFields[field].notRequired()
            : schemaFields[field],
        ])
      )
    ),
    onSubmit: props.onSubmit,
    validateOnChange: true,
  });

  React.useEffect(() => {
    if (formik) {
      props.setFormik(formik);
    }
  }, [formik.handleSubmit]);

  React.useEffect(() => {
    if (props.onValidChange) {
      props.onValidChange(formik.isValid);
    }
  }, [formik.isValid]);

  return (
    <form onSubmit={formik.handleSubmit} className={'fill-width'}>
      <Grid
        container
        spacing={2}
        direction={props.direction ? props.direction : phone ? 'column' : 'row'}
        sx={{ mr: { xs: 1, md: 0 } }}
      >
        {renderFields.map((field, i) => (
          <Field
            showZipNotification={props.showZipNotification}
            key={field}
            open={
              !props.forceOpen && i > 0 ? !!formik.values[fields[i - 1]] : true
            }
            name={field}
            formik={formik}
            direction={props.direction}
          />
        ))}
      </Grid>
    </form>
  );
};

const Field = ({
  name,
  formik,
  direction,
  open,
  showZipNotification,
}: {
  name: string;
  open: boolean;
  showZipNotification?: boolean;
  formik: any;
  direction?: 'row' | 'column';
}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  let result: any;

  switch (name) {
    case 'gender':
      return (
        <Grid item xs={12} md={6}>
          <Collapse in={open}>
            <SelectInput
              fullWidth
              hideTick
              data-test={'gender'}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helperText={(formik.touched.gender && formik.errors.gender) || ''}
              label={'Gender'}
              value={formik.values.gender}
              placeholder={'Select gender'}
              onChange={(event) => {
                formik.setFieldValue('gender', event.target.value as string);
              }}
            >
              <MenuItem data-test={'gender-male'} value={Gender.M}>
                Male
              </MenuItem>
              <MenuItem data-test={'gender-female'} value={Gender.F}>
                Female
              </MenuItem>
            </SelectInput>
          </Collapse>
        </Grid>
      );
    case 'tobacco':
      return (
        <Grid item xs={12} md={6}>
          <Collapse in={open}>
            <SelectInput
              fullWidth
              hideTick
              data-test={'tobacco'}
              error={formik.touched.tobacco && Boolean(formik.errors.tobacco)}
              helperText={
                (formik.touched.tobacco && formik.errors.tobacco) || ''
              }
              label={'Do you use tobacco products?'}
              value={formik.values.tobacco}
              placeholder={'Select tobacco status'}
              onChange={(event) => {
                formik.setFieldValue('tobacco', event.target.value as string);
              }}
            >
              <MenuItem data-test={'tobacco-false'} value={'false'}>
                No
              </MenuItem>
              <MenuItem data-test={'tobacco-true'} value={'true'}>
                Yes
              </MenuItem>
            </SelectInput>
          </Collapse>
        </Grid>
      );
    case 'birthDate':
      return (
        <Grid data-test={'dob'} item xs={12} md={6}>
          <Collapse in={open}>
            <DateInput
              data-test={'birth-date'}
              error={
                formik.touched.birthDate && Boolean(formik.errors.birthDate)
              }
              helperText={
                formik.touched.birthDate && (formik.errors.birthDate as string)
              }
              label={'Date of birth'}
              placeholder={'MM / DD / YYYY'}
              id={'birthDate'}
              value={formik.values.birthDate}
              onChange={(e) => {
                formik.setFieldValue('birthDate', e.target.value as string);
              }}
            />
          </Collapse>
        </Grid>
      );
    case 'countyName':
    case 'zip':
      return (
        <Grid item xs={12}>
          <Collapse in={open}>
            <ZipSelector
              direction={direction ? direction : phone ? 'column' : 'row'}
              acceptAllZips
              showZipNotification={showZipNotification}
              zip={formik.values.zip}
              countyName={formik.values.countyName}
              onChange={(data) =>
                formik.setValues({ ...formik.values, ...data })
              }
              errors={{
                zip: formik.touched.zip && formik.errors.zip,
                countyName:
                  formik.touched.countyName && formik.errors.countyName,
              }}
            />
          </Collapse>
        </Grid>
      );
    default:
      return null;
  }
};
