import React, {forwardRef} from "react";
import {
  Box,
  OutlinedTextFieldProps,
  TextField,
  Typography
} from '@mui/material';
import { SxProps } from '@mui/material';
import { getEndAdornment } from './lib/get-end-adornment';
import { getStartAdornment } from './lib/get-start-adornment';
import { TypographyProps } from '@mui/material/Typography/Typography';

export interface InputProps extends Partial<OutlinedTextFieldProps> {
  labelProps?: TypographyProps,
  inputLabelClass?: string,
  inputClassName?: string,
  wrapperSx?: SxProps,
  tickPosition?: 'start' | 'end',
  startAdornmentSx?: SxProps,
  endAdornmentSx?: SxProps,
  valid?: boolean,
  validate?: boolean,
  hideTick?: boolean,
  loading?: boolean,
}

const Input = (props: InputProps, ref: any) => {
  const {
    loading,
    valid,
    validate = true,
    wrapperSx,
    hideTick,
    inputLabelClass,
    inputClassName,
    endAdornmentSx,
    tickPosition,
    labelProps,
    ...textFieldProps
  } = props;

  let isValid = false;
  if (validate) {
    isValid = (props.valid !== undefined ? props.valid : !!props.value) && !props.error;
  }

  const filledClass = isValid ? 'Mui-success' : '';

  const endAdornment = getEndAdornment(props, isValid);
  const startAdornment = getStartAdornment(props, isValid);

  const labelClasses = [inputLabelClass, 'text-input-label'];
  if (textFieldProps.disabled) {
    labelClasses.push('MuiOutlinedInput-disabled');
  }

  return (
    <Box sx={{width: 1, ...wrapperSx}} className={props.className}>
      {
        props.label && (
          <Typography className={labelClasses.filter(Boolean).join(' ')}
                      {...labelProps}
                      sx={{
                        lineHeight: '19px',
                        fontWeight: 600,
                        ...labelProps?.sx
                      }}
          >
            {props.label}
          </Typography>
        )
      }
      <TextField
        {...textFieldProps}
        fullWidth={typeof props.fullWidth === 'undefined' ? true : props.fullWidth}
        ref={ref}
        variant="outlined"
        label={undefined}
        inputProps={{
          ...props.inputProps,
          className: 'data-hj-allow'
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment,
          startAdornment,
          classes: {
            ...props?.InputProps?.classes
          },
          className: filledClass
        }}
        FormHelperTextProps={{
          sx: {
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            marginTop: '6px',
            whiteSpace: 'nowrap',
          },
          ...props.FormHelperTextProps,
        }}
      />
    </Box>
  );
}

export const TextInput = forwardRef(Input);







