import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { MutationEmailMeMedigapPageArgs } from '@coverright/data-access/types/enrollment';

export function useEmailMeMedigapPage(options?: MutationHookOptions<{emailMeMedigapPage: boolean}, MutationEmailMeMedigapPageArgs>) {
  return useMutation<{emailMeMedigapPage: boolean}, MutationEmailMeMedigapPageArgs>(
    gql(request),
    {
      ...options,
      client: enrollmentClient
    }
  );
}


const request = `
mutation ($email: String!, $id: UUID!) {
  emailMeMedigapPage(email: $email, id: $id)
}
`;
