import React, { FunctionComponent } from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import {
  AppBar,
  Box,
  Theme,
  Toolbar,
  Container,
  Typography
} from '@mui/material';
import { SxProps } from '@mui/material';
import { ElevationScroll } from '@coverright/utils';
import { ComplienceMedicareDisclaimersModal } from './ComplienceMedicareDisclaimersModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      '& *:not(input)': {
        boxSizing: 'border-box',
      },
      marginTop: '18px',
      [theme.breakpoints.up('md')]: {
        marginTop: '100px!important',
      },
      backgroundColor: '#F8F6F2',
      flexDirection: 'column',
      padding: '43px 20px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100vw',
      },
    },
    drawerPaper: {
      width: 320,
      backgroundColor: 'white',
    },
    mobileTitle: {
      fontWeight: 600,
      marginTop: 5,
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }),
);

interface LayoutProps {
  sx?: SxProps
}

export function SignInLayout(props: React.PropsWithChildren<LayoutProps>) {
  const classes = useStyles();
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);

  return (
    <>
      <ComplienceMedicareDisclaimersModal open={openDisclaimer} onClose={() => setOpenDisclaimer(false)}/>
      <ElevationScroll>
        <AppBar sx={{top: 0}} className={classes.appBar}>
          <Toolbar>
            <Container maxWidth={'lg'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Box component={'img'} sx={{mr: 2}} src={'/assets/img/Logo.svg'} width={120} height={31} className={'pointer'} onClick={() => document.location.href = 'https://coverright.com'} />
              </Box>
              <Box component={'a'} href="tel:8889697667" sx={{
                color: '#000',
                display: 'flex',
                borderRadius: 4,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 500,
                margin: '0 0 0 8px',
                textDecoration: 'none',
                '& img': {
                  marginRight: 1
                }
              }}>
                <img src={'/assets/img/phone-dark.svg'} width={18} />
                +1 (888) 969-7667 | TTY: 711
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box sx={props.sx} className={classes.container}>
        {props.children}
      </Box>
      <Box sx={{margin: 'auto'}}>
        <Typography variant={'body2'} color={'textPrimary'} className={'medium'} align={'center'}
                    style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setOpenDisclaimer(true)}>
          View Medicare Disclaimers
        </Typography>
      </Box>
    </>
  );
}

