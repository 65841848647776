import React from "react";
import {useNavigate, useParams} from "react-router";
import paths from '../../../../config/router-paths';
import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import { CollapseSection, SeeMore, TooltipedTitle } from '@coverright/ui/marketplaces';
import {
  DrugDeliveryTypeDto,
  DrugOutput,
  PharmacyLocationDistanceOutput,
  ProviderOutput
} from '@coverright/data-access/types/medicare';
import { QuoteContext } from '@coverright/shared/contexts';

const HealthcarePreferences = () => {
  const params = useParams<{zip: string, countyName: string}>();
  const navigate = useNavigate();
  const [doctorsExpanded, setDoctorsExpanded] = React.useState(false);
  const [drugsExpanded, setDrugsExpanded] = React.useState(false);
  const quote = React.useContext(QuoteContext);

  const doctors = React.useMemo(() => {
    return quote?.preferredDoctors || [];
  }, [quote])

  const drugs = React.useMemo(() => {
    return quote?.preferredDrugs || [];
  }, [quote])

  const pharmacies = React.useMemo(() => {
    return quote?.preferredPharmacies || [];
  }, [quote])

  return <div className={'fill-width healthcare-preferences'}>
    <Typography className={'bold fs-20 lh-25'}>Your Info</Typography>
    <Divider sx={{my: 2}} />
    <CollapseSection sx={{pl: '22px'}} buttonSx={{backgroundColor: 'white'}} title={<TooltipedTitle labelSx={{fontSize: 14, fontWeight: 700, mt: '-2px'}} infoPosition={'start'} infoAlign={'start'} title={'Your doctors'} tooltip={'Your selected doctors. In-network doctor costs will be subject to the copays or coinsurance outlined as per this plan\'s policy.'}/>}>
      {!!doctors.length && <>
        {doctors.slice(0, 2).map((doctor: ProviderOutput) => <PreferenceLine key={doctor.npi}
                                               title={doctor.name + ` (${doctor.addresses[0]?.specialty})`}
                                               subtitle={''/*doctor.address.specialty*/}/>)}
        <Collapse in={doctorsExpanded}>
          {doctors.slice(2).map((doctor: ProviderOutput) => <PreferenceLine key={doctor.npi}
                                                          title={doctor.name + ` (${doctor.addresses[0]?.specialty})`}
                                                          subtitle={''/*doctor.address.specialty*/}/>)}
        </Collapse>
        {doctors.length > 2 && <SeeMore expanded={doctorsExpanded} onToggle={value => setDoctorsExpanded(!value)} />}

      </>}

      {!doctors.length && <PreferenceLine title={'You haven\'t added any doctors'}
                                          subtitle={''}/>}

    </CollapseSection>
    <div className={'h-12'} />
    <CollapseSection sx={{pl: '22px'}} buttonSx={{backgroundColor: 'white'}} title={<TooltipedTitle labelSx={{fontSize: 14, fontWeight: 700, mt: '-2px'}} infoPosition={'start'} infoAlign={'start'} title={'Your drugs'} tooltip={'Your selected prescription drugs. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug\'s tier according to plan policy details.'}/>}>
      {!!drugs.length && <>
        {drugs.slice(0, 2).map((drug: DrugOutput) => <PreferenceLine key={drug.packRxcui}
                                           title={drug.name || ''}
                                           subtitle={''/*`${drug.packName}, ${drug.quantity} ${_.lowerCase(drug.frequency)}`*/}
        />)}
        <Collapse in={drugsExpanded}>
          {drugs.slice(2).map((drug: DrugOutput) => <PreferenceLine key={drug.packRxcui}
                                                         title={drug.name || ''}
                                                         subtitle={''/*`${drug.packName}, ${drug.quantity} ${_.lowerCase(drug.frequency)}`*/}
          />)}
        </Collapse>
        {drugs.length > 2 && <SeeMore expanded={drugsExpanded} onToggle={value => setDrugsExpanded(!value)} />}
      </>}
      {!drugs.length && <PreferenceLine title={'You haven\'t added any drugs'}
                                          subtitle={''}/>}
    </CollapseSection>
    <div className={'h-12'} />
    <CollapseSection sx={{pl: '22px'}} buttonSx={{backgroundColor: 'white'}} title={<TooltipedTitle labelSx={{fontSize: 14, fontWeight: 700, mt: '-2px'}} infoPosition={'start'} infoAlign={'start'} title={'Your pharmacy'} tooltip={'Your selected pharmacy. Preferred retail pharmacies provide more cost effective drug costs in the initial coverage phase. Refer to the Plan Details for more information.'}/>}>
      {!!pharmacies?.length && pharmacies?.map((pharmacy: PharmacyLocationDistanceOutput) => <PreferenceLine key={pharmacy.npi}
                                         title={pharmacy.name || ''}
                                         subtitle={/*pharmacy.address || */''}
      />)}
      {(!pharmacies?.length && quote.drugDeliveryType) && <PreferenceLine title={quote.drugDeliveryType === DrugDeliveryTypeDto.Pharmacy ? 'Retail pharmacy' : 'Mail order pharmacy'}
                                          subtitle={''}/>}
      {(!pharmacies?.length && !quote.drugDeliveryType) && <PreferenceLine title={'You haven\'t added a pharmacy'}
                                          subtitle={''}/>}
    </CollapseSection>
    <div className={'h-16'} />
    <Button variant={'outlined'}
            fullWidth
            TouchRippleProps={{classes: {root: 'green'}}}
            id={'edit-your-info-button'}
            onClick={() => navigate(paths.editPreferences + params.zip + '/' + params.countyName)}
            startIcon={<img src={'/assets/img/edit-green2.svg'}/>}>
      <Box mt={'3px'}>Edit your info</Box>
    </Button>
  </div>
}

export default HealthcarePreferences;

export const PreferenceLine = (props: {title: string, subtitle: string}) => <div className={'mb-16'}>
  <Typography variant={'body2'} color={'textPrimary'}>{props.title}</Typography>
  <Typography variant={'h5'} color={'textSecondary'}>{props.subtitle}</Typography>
</div>
