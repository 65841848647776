import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { FetchResult } from '@apollo/client';
import { MedicareQuoteOutput } from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function useMedicareQuote(options?: LazyQueryHookOptions<{medicareQuote: MedicareQuoteOutput}, {id: string}>) {
  return useLazyQuery<{medicareQuote: MedicareQuoteOutput}, {id: string}>(
    gql(query),
    {
      ...options,
      fetchPolicy: 'no-cache'
    }
  );
}

export const getMedicareQuote = (id: string): Promise<FetchResult<{medicareQuote: MedicareQuoteOutput}>> =>
  client.query({
    query: gql(query),
    variables: { id }
  });

const query = `query ($id: UUID!) {
  medicareQuote(id: $id) {
    abCase
    createdAt
    county
    employed
    pin
    id
    clientId
    favorites
    restrictProviderNetwork
    travelMoreThan30DaysAYear
    doctorsWithinNetwork
    work8years
    filters {
      SNPTypes
      companies
      extraBenefits
      maxCostRangeNames
      maxOutOfPocketRangeNames
      partBPremiumReduction
      planTypes
      rating
      showTopDoctors
      showTopDrugs
    }
    planYear
    enrolled
    coverageFilled
    enrolledFilled
    drugDeliveryType
    hasMore20
    receivesMedicalSupport
    receivesLIS
    receivedBenefits
    governmentAssistant
    spouseEmployed
    spouseHasMore20
    zip
    doctorVisitsNumber
    specialistVisitsNumber
    coverage
    chronicIllness
    preferredPharmacies {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    medicarePlanType
    prepayCoverage
    customAnswers
    preferredDrugs {
      frequency
      genericName
      genericRxcui
      purchaseFrequency
      isGeneric
      name
      packName
      packRxcui
      quantity
      rxcui
      notSupported
    }
    preferredDoctors {
      npi
      addresses {
        addressLine1
        addressLine2
        id
        isPcp
        specialty
      }
      name
      addressesNumber
    }
  }
}`

