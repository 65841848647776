import { MagnifyingGlass } from '@phosphor-icons/react';
import { TextInput } from '@coverright/ui/inputs';
import * as React from 'react';
import { AutocompleteRenderInputParams } from '@mui/material';

interface Props {
  params: AutocompleteRenderInputParams;
  label?: string;
}

export const DrugAutocompleteInput = ({ params, label }: Props) => {
  return (
    <TextInput
      autoFocus
      data-test={'search_drug_input'}
      placeholder={'Enter name of prescription'}
      name={'search-drug-input'}
      label={label || 'Search drug name:'}
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <MagnifyingGlass
            className={'ml-16'}
            size={24}
            color="#1C434F"
            weight="regular"
          />
        ),
      }}
    />
  );
};
