import React from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { colors } from '@coverright/ui/themes';
import { CRCheckbox, Tooltip } from '@coverright/ui/inputs';
import { TooltipedTitle } from '@coverright/ui/marketplaces';
import { Box, Button, Card, Divider, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ComparisonContext } from '../../../ComparisonContext';
import { getCompanyLogo } from '@coverright/utils';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { PlanTitle } from '../../../components/PlanTitle';
import { PlanViewProps } from '../detailed-plan';
import { createStyles, makeStyles } from '@mui/styles';
import { planStyles } from '../../plan-list/components/PlanCard';
import { AdminContext, FavoritesContext, QuoteContext } from '@coverright/shared/contexts';
import { ComparablePlanType, useGetEHealthLinkRequest } from '@coverright/data-access/medicare';


const styles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      flexDirection: 'column',
      marginTop: 0,
    },
    withoutShadow: {
      boxShadow: 'none!important',
    },
    enrollSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '12px 19px 16px 16px',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 195,
      },
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
  })
);

const HeaderCard = (props: PlanViewProps & {hideSelectButton?: boolean}) => {
  const {zip, countyName, plan, setModalOpen, setEmailOpen} = props;
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const {preferredDrugs, pin} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const adminContext = React.useContext(AdminContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapsed, setCollapsed] = React.useState(false);

  useScrollPosition(({currPos}) => {
    if( currPos.y < -450 && !collapsed) {
      setCollapsed(true);
    }
    if( currPos.y >= -50 && collapsed) {
      setCollapsed(false);
    }
  }, [collapsed])

  const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
    onCompleted: data => {
      if (data?.generateEhealthPlanLink) {
        navigator.clipboard.writeText(data?.generateEhealthPlanLink)
      }
    }
  })

  const navigate = useNavigate();
  const ownClasses = styles();
  const classes = planStyles();

  const copyEHealthLink = () => {
    generateEhealthPlanLink({variables: {bidId: plan.bidId, countyName, zip, planYear: plan.planYear}})
  }

  const onEnrollClick = () => {
    navigate(`/completed/${zip}/${countyName}/${plan.bidId}`)
  }

  const EnrollSection = () => {
    if (!phone) {
      return <div className={ownClasses.enrollSection}>
        {!!adminContext && <Box position={'absolute'} right={5} top={5}
                                onClick={copyEHealthLink}
                                sx={{color: colors.custom.green.variant2}}
                                className={`fs-12 pointer underline`}>E Health</Box>}
        <Box display={"flex"} flexDirection={"column"} flex={1}>
          <Typography className={'mr-20 bold fs-36 lh-45'}>{plan?.monthlyCost || "N/A"}</Typography>
          <Box display={"flex"} alignItems={"flex-start"}>
            <Typography className={'bold fs-12 lh-15'}>estimated average total cost per month</Typography>
            <Box mb={"3px"}>
              <img className={'w-15 h-15'} src={"/assets/img/info-green.svg"}/>
            </Box>
          </Box>
        </Box>
        {!collapsed && <>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={'bold'}>{plan?.monthlyPremium}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={'bold'}>{plan?.drugsCost || 'N/A'}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={'bold'}>{plan?.doctorsCost}</Typography>
          </div>
        </>}
        {!props.hideSelectButton && <Box mt={"14px"}>
          <Button className={classes.enrollButton}
                        onClick={onEnrollClick}
                        variant={"contained"}>
            Select this plan
          </Button>
        </Box>}
      </div>
    } else {
      return <div className={ownClasses.enrollSection}>
        <div className={'flex-column drug-calculation'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className={'bold fs-36 lh-45'}>{plan?.monthlyCost || 'N/A'}</Typography>
            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-end'} flex={1}>
              <Typography variant={'body2'} color={'textPrimary'} className={'bold'} align={'right'}>estimated average total cost per
                month</Typography>
              <Tooltip placement={'top'} arrow
                       title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
                <img className={'w-15 h-15 ml-4'} src={'/assets/img/info-green.svg'}/>
              </Tooltip>
            </Box>
          </Box>
          <div className={'mt-8 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={'bold'}>{plan?.monthlyPremium}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. drug costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'}
                        className={'bold'}>{plan?.drugsCost || 'N/A'}</Typography>
          </div>
          <div className={'mt-5 flex-space-between'}>
            <Typography variant={'h5'} color={'textPrimary'}>Est. doctor costs:</Typography>
            <Typography variant={'h5'} color={'textPrimary'} className={'bold'}>{plan?.doctorsCost}</Typography>
          </div>
        </div>
      </div>
    }
  }

  const Collapsed = () => <Box sx={{display: 'flex', p: {xs: '5px 10px 10px', sm: '0 26px'}, alignItems: 'center'}}>
      <Box className={'flex-column'}>
        <Typography sx={{fontSize: {xs: 16, md: 20}, lineHeight: {xs: '20px', md: '25px'}}} className={'bold'}>{plan.planName}</Typography>
        {!phone && <TooltipedTitle title={<Typography className={`fs-18 bold`}>{plan?.monthlyCost || 'N/A'} <span className={'fs-12 lh-13 regular'}>estimated average total cost per month</span></Typography>}
                                   tooltip={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}
                                   imgProps={{
                                     width: 15,
                                     height: 15
                                   }}/>}
      </Box>
      <Box sx={{display: 'flex', gap: {xs: 1, sm: 4}, flexDirection: {xs: 'column', sm: 'row'}, alignItems: {xs: 'flex-end', sm: 'center'}}}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
          <div className={classes.logo + ' h-30'} style={{backgroundPosition: 'right', backgroundImage: `url("${getCompanyLogo(props.plan.parentOrgName, props.plan.orgName)}")`}}  />
          {!phone && <CRCheckbox variant={"standart"} controlClassName={clsx(classes.checkbox, ownClasses.checkbox)}
                                 labelClassName={classes.checkboxLabel}
                                 label={"Add to compare"} checked={isInCompare(plan)}
                                 onChange={(e, checked) => {
                                   checked ? addToCompare(plan) : removeFromCompare(ComparablePlanType.Ma, plan.bidId)
                                 }}/>}
        </Box>
        {!props.hideSelectButton && <div>
          <Button className={classes.enrollButton}
                  onClick={onEnrollClick}
                  size={phone ? 'small' : undefined}
                  variant={"contained"}>
            Select this plan
          </Button>
        </div>}
      </Box>
  </Box>

  return <Card className={`${ownClasses.headerCard}`}>
    {collapsed && <Collapsed />}
    {!collapsed && <Box display={"flex"} flexDirection={{xs: 'column', sm: 'row'}} p={phone ? '8px 12px 9px' : '15px 15px 16px 0'}>
      <Box className={classes.infoWrapper}>
        <PlanTitle plan={plan} className={ownClasses.title}
                   inFavorites={favorites.indexOf(plan.bidId) >= 0}
                   favoritesClick={() => toggleFavorite(plan.bidId, zip)}/>
        {!phone && <>
          <Divider sx={{mt: 1, mb: 2}} />
          <Box display={'flex'} flex={1} alignItems={'start'} justifyContent={'space-between'}>
            <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>
            {(!!preferredDrugs?.length && !phone && !plan.missingFormularyInAep) && (
                <Button variant={'outlined'} size={'small'} sx={{px: '33px!important'}} onClick={() => setModalOpen(true)}>
                  Drug cost breakdown/summary
                </Button>
            )}
          </Box>
        </>}
        <Box width={"100%"}>
          {!phone && <>
            <Divider />
            <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'} mt={"20px"}>
              {/*<Box display={'flex'}>
                <div className={ownClasses.iconButton} onClick={() => setEmailOpen(true)}>
                  <img src={'/assets/img/message.svg'}/>
                  <div>Email me this plan</div>
                </div>
              </Box>*/}
              <CRCheckbox variant={"standart"} controlClassName={clsx(classes.checkbox, ownClasses.checkbox)}
                          labelClassName={classes.checkboxLabel}
                          label={"Add to compare"} checked={isInCompare(plan)}
                          onChange={(e, checked) => {
                            checked ? addToCompare(plan) : removeFromCompare(ComparablePlanType.Ma, plan.bidId)
                          }}/>
            </Box>
          </>}
        </Box>
      </Box>
      <EnrollSection/>
      {(phone && !props.hideSelectButton) && <Box mt={"14px"}>
        <Button className={classes.enrollButton}
                onClick={onEnrollClick}
                variant={"contained"}>
          Select this plan
        </Button>
      </Box>}
    </Box>}
  </Card>
}

export default React.memo(HeaderCard);
