import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { SxProps } from '@mui/material';
import { Tooltip } from './tooltip';

const useStyles = makeStyles({
  root: {
    pointerEvents: (props: Props) => props.readOnly ? 'none' : undefined,
    // backgroundColor: 'white',
    boxShadow: (props: Props) => props.variant === 'standart' ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.15)',
    padding: (props: Props) => props.variant === 'standart' ? '0' : '9px 18px 9px 6px',
    display: 'flex!important',
    flex: 1,
    borderRadius: 4,
    maxHeight: 40,
    marginRight: `0!important`,
    marginLeft: `0!important`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: '16px!important',
    lineHeight: '20px!important',
    marginLeft: '3px!important',
    width: '100%',
  },
  checkedRoot: {
    borderColor: (props: Props) => props.variant === 'outlined' ? `#5ECB9E!important` : undefined,
    backgroundColor: (props: Props) => props.variant === 'outlined' ? 'rgba(94, 203, 158, 0.1)!important' : undefined
  },
  icon: {
    borderRadius: 4,
    width: 20,
    height: 20,
    backgroundColor: 'white',
    border: '1px solid #A4B4B9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      border: '1px solid #5ECB9E'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    width: '20px!important',
    height: '20px!important',
    backgroundColor: '#1D9C80',
    borderRadius: 4,
    color: 'white',
    padding: 1,
  },
  subLabel: {
    color: '#778E95',
    fontSize: 14,
    lineHeight: '15px',
  }
});

type Props = CheckboxProps & {
  variant: 'outlined' | 'standart',
  label: React.ReactNode,
  subLabel?: React.ReactNode,
  endAdornment?: React.ReactNode,
  labelClassName?: string,
  controlClassName?: string,
  iconClassName?: string,
  tooltip?: string,
  wrapperSx?: SxProps,
}

function CRCheckboxBase(props: Props) {
  const classes = useStyles(props);
  const {labelClassName, controlClassName, iconClassName, subLabel, tooltip, endAdornment, wrapperSx, ...rest} = props;

  return (
    <FormControlLabel className={clsx({[classes.root]: true, [classes.checkedRoot]: props.checked, [controlClassName || ""]: !!controlClassName})}
                      sx={wrapperSx}
                      control={<Checkbox
                        color="default"
                        checkedIcon={props.checked ? <Check className={classes.checkedIcon} /> : <span className={clsx(classes.icon, iconClassName)} />}
                        icon={<span className={clsx(classes.icon, iconClassName)} />}
                        {...rest}
                      />}
                      classes={{label: classes.label}}
                      label={<Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
                        <Box mr={'12px'} className={labelClassName}>
                          {props.label}
                          {subLabel && <Box className={classes.subLabel}>{subLabel}</Box>}
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          {endAdornment && <>{endAdornment}</>}
                          {tooltip && <Tooltip placement={'top'} arrow title={tooltip}>
                            <img className={'w-20 h-20'} src={'/assets/img/info.svg'}></img>
                          </Tooltip>}
                        </Box>
                      </Box>}
    />

  );
}

export const CRCheckbox = React.memo(CRCheckboxBase, areEqual);

function areEqual(prevProps: Props, nextProps: Props): boolean {
  if (prevProps.checked === nextProps.checked && prevProps.disabled === nextProps.disabled) {
    return typeof nextProps.label === 'string' ? prevProps.label === nextProps.label : true;
  }
  return false;
}
