import React from "react";import * as Scroll from "react-scroll";
var ScrollElement = Scroll.ScrollElement;

function TableScrollElement(props: any) {
  const {parentBindings, ...rest} = props;
  return (
    <tbody {...rest}  ref={(el) => { parentBindings.domNode = el; }}>
      {props.children}
    </tbody>
  );
};

export default ScrollElement(TableScrollElement);
