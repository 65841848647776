import * as React from 'react';
import { Box, Button, Dialog, DialogActions, Typography } from '@mui/material';
import { CRModal } from '@coverright/ui/shared';
import { ZipSelector, ZipSelectorCompact } from '@coverright/features';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getStoredUser } from '@coverright/utils';

type UpdateZipModalProps = {
  variant?: 'full' | 'compact';
  onClose: (zip?: string, county?: string, state?: string) => void;
  open: boolean;
  state?: string | null;
  county?: string | null;
  zip?: string | null;
  title?: string;
};

const validationSchema = yup.object({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required('Zip is required'),
  county: yup.string().required('County name is required'),
  state: yup.string(),
});

export function UpdateZipModal(props: UpdateZipModalProps) {
  const formik = useFormik({
    initialValues: {
      zip: props.zip || '',
      county: props.county || '',
      state: props.state || getStoredUser()?.state || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (formik.isValid) {
        props.onClose(values.zip, values.county, values.state);
      }
    },
    validateOnChange: true,
  });

  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={() => props.onClose()}
    >
      <Box p={3}>
        <Typography className={'fs-26 bold lh-32'} align={'center'}>
          {props.title || 'Update your zip code'}
        </Typography>
        <div className={'h-16'} />
        <Typography
          color={'textSecondary'}
          className={'fs-18 lh-23 bold'}
          align={'center'}
        >
          Your plan options will update automatically.
        </Typography>

        <form onSubmit={formik.handleSubmit} className={'mt-40'}>
          {props.variant !== 'compact' && (
            <ZipSelector
              direction={'column'}
              zip={formik.values.zip}
              countyName={formik.values.county}
              onChange={(data) =>
                formik.setValues({
                  zip: data.zip,
                  county: data.countyName,
                  state: data.state,
                })
              }
              errors={{
                zip: formik.touched.zip && formik.errors.zip,
                countyName: formik.touched.county && formik.errors.county,
              }}
            />
          )}

          {props.variant === 'compact' && (
            <ZipSelectorCompact onChange={(data) =>
              formik.setValues({
                zip: data.zip,
                county: data.county,
                state: data.state,
              })}
                                zip={formik.values.zip}
                                county={formik.values.county}
                                state={formik.values.state} />
          )}


          <Box
            display={'flex'}
            mt={'24px'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Button
              className={'gtm-step-event w-200'}
              disabled={!formik.isValid}
              variant={'contained'}
              type="submit"
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}
