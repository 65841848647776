import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** A type representing local date */
  LocalDate: { input: any; output: any; }
  /** A type representing long */
  Long: { input: any; output: any; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
};

export enum CalcDrugFrequencyPeriod {
  Daily = 'DAILY',
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum CsgGender {
  F = 'F',
  M = 'M'
}

export enum Gender {
  F = 'F',
  M = 'M'
}

export type MedigapCompanyFilterItem = {
  __typename?: 'MedigapCompanyFilterItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  naics: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type MedigapCompanyOutput = {
  __typename?: 'MedigapCompanyOutput';
  fullName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  naic: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCompanyName: Scalars['String']['output'];
};

export enum MedigapDrugDeliveryType {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export type MedigapFilterItem = {
  __typename?: 'MedigapFilterItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MedigapFilterStateInput = {
  age: Scalars['Int']['input'];
  benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  gender: Gender;
  id?: InputMaybe<Scalars['UUID']['input']>;
  monthlyPlanPremiumRanges?: InputMaybe<Array<Scalars['String']['input']>>;
  planCategory?: InputMaybe<Scalars['String']['input']>;
  planNames?: InputMaybe<Array<MedigapPlanName>>;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};

export type MedigapFilterStateOutput = {
  __typename?: 'MedigapFilterStateOutput';
  age: Scalars['Int']['output'];
  benefits?: Maybe<Array<MedigapPlanBenefit>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  county?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  gender: Gender;
  id: Scalars['UUID']['output'];
  monthlyPlanPremiumRanges?: Maybe<Array<Scalars['String']['output']>>;
  planCategory?: Maybe<Scalars['String']['output']>;
  planNames?: Maybe<Array<MedigapPlanName>>;
  tobacco: Scalars['Boolean']['output'];
  zip: Scalars['String']['output'];
};

export enum MedigapFrequencyPeriodOutput {
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type MedigapMaBestRatingOutput = {
  __typename?: 'MedigapMaBestRatingOutput';
  amBestRating?: Maybe<Scalars['String']['output']>;
  naic: Scalars['String']['output'];
  orgName?: Maybe<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
};

export enum MedigapPlanBenefit {
  ForeignTravelEmergency = 'FOREIGN_TRAVEL_EMERGENCY',
  PartADeductible = 'PART_A_DEDUCTIBLE',
  PartBDeductible = 'PART_B_DEDUCTIBLE',
  PartBExcessCharges = 'PART_B_EXCESS_CHARGES',
  SkilledNursingFacilities = 'SKILLED_NURSING_FACILITIES'
}

export enum MedigapPlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MaSupp1A = 'MA_SUPP1A',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnHded2 = 'MN_HDED2',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N',
  WiHded = 'WI_HDED'
}

export type MedigapPlanOutput = {
  __typename?: 'MedigapPlanOutput';
  amBestRating?: Maybe<Scalars['String']['output']>;
  duplicates?: Maybe<Array<MedigapPlanOutput>>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  householdDiscount?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  monthlyPremium?: Maybe<Scalars['String']['output']>;
  naic: Scalars['String']['output'];
  orgName?: Maybe<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  ratingClass?: Maybe<Scalars['String']['output']>;
  showAdditionalBenefitsDescription: Scalars['Boolean']['output'];
  spRating?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MedigapPlanRateOutput = {
  __typename?: 'MedigapPlanRateOutput';
  carrier?: Maybe<Scalars['String']['output']>;
  female65PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  female65PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  female75PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  female75PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  householdPercentMax?: Maybe<Scalars['BigDecimal']['output']>;
  householdPercentMin?: Maybe<Scalars['BigDecimal']['output']>;
  male65PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  male65PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  male75PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  male75PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
};

export enum MedigapPlansField {
  MonthlyPremium = 'MONTHLY_PREMIUM',
  PlanName = 'PLAN_NAME'
}

export type MedigapPlansFilterInput = {
  age: Scalars['Int']['input'];
  benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
  companies: Array<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  gender: Gender;
  monthlyPlanPremiumRanges: Array<Scalars['String']['input']>;
  planNames: Array<MedigapPlanName>;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};

export type MedigapPlansSortInput = {
  direction: Sort;
  field: MedigapPlansField;
};

export type MedigapPreferredDrugOutput = {
  __typename?: 'MedigapPreferredDrugOutput';
  dosageRxcui: Scalars['String']['output'];
  frequency: CalcDrugFrequencyPeriod;
  productRxcui: Scalars['String']['output'];
  purchaseFrequency: MedigapFrequencyPeriodOutput;
  quantity: Scalars['BigDecimal']['output'];
};

export type MedigapPremiumRange = {
  __typename?: 'MedigapPremiumRange';
  planName: MedigapPlanName;
  rangeDescription: Scalars['String']['output'];
};

export type MedigapQuoteInput = {
  cId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  medigapFilterState: MedigapFilterStateInput;
};

export type MedigapQuoteOutput = {
  __typename?: 'MedigapQuoteOutput';
  cId?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['UUID']['output'];
  drugDeliveryType?: Maybe<MedigapDrugDeliveryType>;
  id: Scalars['UUID']['output'];
  medigapFilterState: MedigapFilterStateOutput;
  preferredDrugs: Array<MedigapPreferredDrugOutput>;
  preferredPharmacies: Array<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedigapQuoteForClient: SaveMedigapQuoteOutput;
  createMedigapQuoteFromMedicareQuote: SaveMedigapQuoteOutput;
  saveMedigapFilterState: Scalars['UUID']['output'];
  saveMedigapQuote: SaveMedigapQuoteOutput;
};


export type MutationCreateMedigapQuoteForClientArgs = {
  agentOffer?: InputMaybe<Scalars['Boolean']['input']>;
  input: MedigapQuoteInput;
};


export type MutationCreateMedigapQuoteFromMedicareQuoteArgs = {
  medicareQuoteId: Scalars['UUID']['input'];
};


export type MutationSaveMedigapFilterStateArgs = {
  input: MedigapFilterStateInput;
};


export type MutationSaveMedigapQuoteArgs = {
  input: MedigapQuoteInput;
};

export type PageInput = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PageableMedigapPlanOutput = {
  __typename?: 'PageableMedigapPlanOutput';
  data: Array<MedigapPlanOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PlanPriceByMonthOutput = {
  __typename?: 'PlanPriceByMonthOutput';
  month: Scalars['LocalDate']['output'];
  monthlyPremium?: Maybe<Scalars['BigDecimal']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  buildMedigapPlanAmBestRatingReport: Array<MedigapMaBestRatingOutput>;
  medigapBenefits: Array<MedigapFilterItem>;
  medigapCompanies: Array<MedigapCompanyFilterItem>;
  medigapCompaniesUnfiltered: Array<MedigapCompanyFilterItem>;
  medigapCompany?: Maybe<MedigapCompanyOutput>;
  medigapFilterState: MedigapFilterStateOutput;
  medigapMonthlyPlanPremium: Array<MedigapFilterItem>;
  medigapPlan: MedigapPlanOutput;
  medigapPlanNames: Array<MedigapFilterItem>;
  medigapPlanRates: Array<MedigapPlanRateOutput>;
  medigapPlans: PageableMedigapPlanOutput;
  medigapPlansUnfiltered: PageableMedigapPlanOutput;
  medigapPremiumRanges: Array<MedigapPremiumRange>;
  medigapQuote: MedigapQuoteOutput;
  planPricesByMonth: Array<PlanPriceByMonthOutput>;
};


export type QueryBuildMedigapPlanAmBestRatingReportArgs = {
  code: Scalars['String']['input'];
};


export type QueryMedigapCompaniesArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapCompaniesUnfilteredArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapCompanyArgs = {
  naic: Scalars['String']['input'];
};


export type QueryMedigapFilterStateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMedigapPlanArgs = {
  key: Scalars['String']['input'];
};


export type QueryMedigapPlanNamesArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapPlanRatesArgs = {
  planName: MedigapPlanName;
};


export type QueryMedigapPlansArgs = {
  filter: MedigapPlansFilterInput;
  page: PageInput;
  sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPlansUnfilteredArgs = {
  filter: MedigapPlansFilterInput;
  page: PageInput;
  sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPremiumRangesArgs = {
  age: Scalars['Int']['input'];
  gender: CsgGender;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};


export type QueryMedigapQuoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryPlanPricesByMonthArgs = {
  planName: MedigapPlanName;
};

export type SaveMedigapQuoteOutput = {
  __typename?: 'SaveMedigapQuoteOutput';
  clientId: Scalars['UUID']['output'];
  mgQuoteId: Scalars['UUID']['output'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type SaveMedigapFilterStateMutationVariables = Exact<{
  input: MedigapFilterStateInput;
}>;


export type SaveMedigapFilterStateMutation = { __typename?: 'Mutation', saveMedigapFilterState: any };

export type MedigapFiltersQueryVariables = Exact<{
  filter: MedigapPlansFilterInput;
}>;


export type MedigapFiltersQuery = { __typename?: 'Query', medigapBenefits: Array<{ __typename?: 'MedigapFilterItem', key: string, name: string }>, medigapMonthlyPlanPremium: Array<{ __typename?: 'MedigapFilterItem', key: string, name: string }>, medigapPlanNames: Array<{ __typename?: 'MedigapFilterItem', key: string, name: string, count: number }>, medigapCompanies: Array<{ __typename?: 'MedigapCompanyFilterItem', key: string, name: string, count: number }> };

export type CreateMedigapQuoteFromMedicareQuoteMutationVariables = Exact<{
  medicareQuoteId: Scalars['UUID']['input'];
}>;


export type CreateMedigapQuoteFromMedicareQuoteMutation = { __typename?: 'Mutation', createMedigapQuoteFromMedicareQuote: { __typename?: 'SaveMedigapQuoteOutput', clientId: any, mgQuoteId: any } };

export type MedigapPremiumRangesQueryVariables = Exact<{
  zip: Scalars['String']['input'];
  tobacco: Scalars['Boolean']['input'];
  age: Scalars['Int']['input'];
  gender: CsgGender;
}>;


export type MedigapPremiumRangesQuery = { __typename?: 'Query', medigapPremiumRanges: Array<{ __typename?: 'MedigapPremiumRange', planName: MedigapPlanName, rangeDescription: string }> };

export type MedigapPlansQueryVariables = Exact<{
  filter: MedigapPlansFilterInput;
  page: PageInput;
  sort?: InputMaybe<Array<MedigapPlansSortInput> | MedigapPlansSortInput>;
}>;


export type MedigapPlansQuery = { __typename?: 'Query', medigapPlans: { __typename?: 'PageableMedigapPlanOutput', totalElements: any, data: Array<{ __typename?: 'MedigapPlanOutput', amBestRating?: string | null, householdDiscount?: string | null, key: string, monthlyPremium?: string | null, orgName?: string | null, parentOrgName?: string | null, title?: string | null, subTitle?: string | null, naic: string, spRating?: string | null, ratingClass?: string | null, planName?: string | null, showAdditionalBenefitsDescription: boolean }> } };

export type SaveMedigapQuoteMutationVariables = Exact<{
  input: MedigapQuoteInput;
}>;


export type SaveMedigapQuoteMutation = { __typename?: 'Mutation', saveMedigapQuote: { __typename?: 'SaveMedigapQuoteOutput', clientId: any, mgQuoteId: any } };

export type MedigapCompaniesQueryVariables = Exact<{
  filters: MedigapPlansFilterInput;
}>;


export type MedigapCompaniesQuery = { __typename?: 'Query', medigapCompanies: Array<{ __typename?: 'MedigapCompanyFilterItem', key: string, name: string, count: number }> };

export type MedigapQuoteQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type MedigapQuoteQuery = { __typename?: 'Query', medigapQuote: { __typename?: 'MedigapQuoteOutput', cId?: string | null, id: any, clientId: any, drugDeliveryType?: MedigapDrugDeliveryType | null, preferredPharmacies: Array<string>, userId?: any | null, preferredDrugs: Array<{ __typename?: 'MedigapPreferredDrugOutput', dosageRxcui: string, frequency: CalcDrugFrequencyPeriod, productRxcui: string, purchaseFrequency: MedigapFrequencyPeriodOutput, quantity: any }>, medigapFilterState: { __typename?: 'MedigapFilterStateOutput', age: number, benefits?: Array<MedigapPlanBenefit> | null, companies?: Array<string> | null, county?: string | null, effectiveDate?: any | null, gender: Gender, id: any, monthlyPlanPremiumRanges?: Array<string> | null, planCategory?: string | null, planNames?: Array<MedigapPlanName> | null, tobacco: boolean, zip: string } } };


export const SaveMedigapFilterStateDocument = gql`
    mutation saveMedigapFilterState($input: MedigapFilterStateInput!) {
  saveMedigapFilterState(input: $input)
}
    `;
export type SaveMedigapFilterStateMutationFn = Apollo.MutationFunction<SaveMedigapFilterStateMutation, SaveMedigapFilterStateMutationVariables>;

/**
 * __useSaveMedigapFilterStateMutation__
 *
 * To run a mutation, you first call `useSaveMedigapFilterStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMedigapFilterStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMedigapFilterStateMutation, { data, loading, error }] = useSaveMedigapFilterStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMedigapFilterStateMutation(baseOptions?: Apollo.MutationHookOptions<SaveMedigapFilterStateMutation, SaveMedigapFilterStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMedigapFilterStateMutation, SaveMedigapFilterStateMutationVariables>(SaveMedigapFilterStateDocument, options);
      }
export type SaveMedigapFilterStateMutationHookResult = ReturnType<typeof useSaveMedigapFilterStateMutation>;
export type SaveMedigapFilterStateMutationResult = Apollo.MutationResult<SaveMedigapFilterStateMutation>;
export type SaveMedigapFilterStateMutationOptions = Apollo.BaseMutationOptions<SaveMedigapFilterStateMutation, SaveMedigapFilterStateMutationVariables>;
export const MedigapFiltersDocument = gql`
    query medigapFilters($filter: MedigapPlansFilterInput!) {
  medigapBenefits {
    key
    name
  }
  medigapMonthlyPlanPremium {
    key
    name
  }
  medigapPlanNames(filter: $filter) {
    key
    name
    count
  }
  medigapCompanies(filter: $filter) {
    key
    name
    count
  }
}
    `;

/**
 * __useMedigapFiltersQuery__
 *
 * To run a query within a React component, call `useMedigapFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedigapFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedigapFiltersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedigapFiltersQuery(baseOptions: Apollo.QueryHookOptions<MedigapFiltersQuery, MedigapFiltersQueryVariables> & ({ variables: MedigapFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedigapFiltersQuery, MedigapFiltersQueryVariables>(MedigapFiltersDocument, options);
      }
export function useMedigapFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedigapFiltersQuery, MedigapFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedigapFiltersQuery, MedigapFiltersQueryVariables>(MedigapFiltersDocument, options);
        }
export function useMedigapFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedigapFiltersQuery, MedigapFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedigapFiltersQuery, MedigapFiltersQueryVariables>(MedigapFiltersDocument, options);
        }
export type MedigapFiltersQueryHookResult = ReturnType<typeof useMedigapFiltersQuery>;
export type MedigapFiltersLazyQueryHookResult = ReturnType<typeof useMedigapFiltersLazyQuery>;
export type MedigapFiltersSuspenseQueryHookResult = ReturnType<typeof useMedigapFiltersSuspenseQuery>;
export type MedigapFiltersQueryResult = Apollo.QueryResult<MedigapFiltersQuery, MedigapFiltersQueryVariables>;
export const CreateMedigapQuoteFromMedicareQuoteDocument = gql`
    mutation createMedigapQuoteFromMedicareQuote($medicareQuoteId: UUID!) {
  createMedigapQuoteFromMedicareQuote(medicareQuoteId: $medicareQuoteId) {
    clientId
    mgQuoteId
  }
}
    `;
export type CreateMedigapQuoteFromMedicareQuoteMutationFn = Apollo.MutationFunction<CreateMedigapQuoteFromMedicareQuoteMutation, CreateMedigapQuoteFromMedicareQuoteMutationVariables>;

/**
 * __useCreateMedigapQuoteFromMedicareQuoteMutation__
 *
 * To run a mutation, you first call `useCreateMedigapQuoteFromMedicareQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedigapQuoteFromMedicareQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedigapQuoteFromMedicareQuoteMutation, { data, loading, error }] = useCreateMedigapQuoteFromMedicareQuoteMutation({
 *   variables: {
 *      medicareQuoteId: // value for 'medicareQuoteId'
 *   },
 * });
 */
export function useCreateMedigapQuoteFromMedicareQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateMedigapQuoteFromMedicareQuoteMutation, CreateMedigapQuoteFromMedicareQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMedigapQuoteFromMedicareQuoteMutation, CreateMedigapQuoteFromMedicareQuoteMutationVariables>(CreateMedigapQuoteFromMedicareQuoteDocument, options);
      }
export type CreateMedigapQuoteFromMedicareQuoteMutationHookResult = ReturnType<typeof useCreateMedigapQuoteFromMedicareQuoteMutation>;
export type CreateMedigapQuoteFromMedicareQuoteMutationResult = Apollo.MutationResult<CreateMedigapQuoteFromMedicareQuoteMutation>;
export type CreateMedigapQuoteFromMedicareQuoteMutationOptions = Apollo.BaseMutationOptions<CreateMedigapQuoteFromMedicareQuoteMutation, CreateMedigapQuoteFromMedicareQuoteMutationVariables>;
export const MedigapPremiumRangesDocument = gql`
    query medigapPremiumRanges($zip: String!, $tobacco: Boolean!, $age: Int!, $gender: CsgGender!) {
  medigapPremiumRanges(zip: $zip, tobacco: $tobacco, age: $age, gender: $gender) {
    planName
    rangeDescription
  }
}
    `;

/**
 * __useMedigapPremiumRangesQuery__
 *
 * To run a query within a React component, call `useMedigapPremiumRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedigapPremiumRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedigapPremiumRangesQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *      tobacco: // value for 'tobacco'
 *      age: // value for 'age'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useMedigapPremiumRangesQuery(baseOptions: Apollo.QueryHookOptions<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables> & ({ variables: MedigapPremiumRangesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>(MedigapPremiumRangesDocument, options);
      }
export function useMedigapPremiumRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>(MedigapPremiumRangesDocument, options);
        }
export function useMedigapPremiumRangesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>(MedigapPremiumRangesDocument, options);
        }
export type MedigapPremiumRangesQueryHookResult = ReturnType<typeof useMedigapPremiumRangesQuery>;
export type MedigapPremiumRangesLazyQueryHookResult = ReturnType<typeof useMedigapPremiumRangesLazyQuery>;
export type MedigapPremiumRangesSuspenseQueryHookResult = ReturnType<typeof useMedigapPremiumRangesSuspenseQuery>;
export type MedigapPremiumRangesQueryResult = Apollo.QueryResult<MedigapPremiumRangesQuery, MedigapPremiumRangesQueryVariables>;
export const MedigapPlansDocument = gql`
    query medigapPlans($filter: MedigapPlansFilterInput!, $page: PageInput!, $sort: [MedigapPlansSortInput!]) {
  medigapPlans(filter: $filter, page: $page, sort: $sort) {
    data {
      amBestRating
      householdDiscount
      key
      monthlyPremium
      orgName
      parentOrgName
      title
      subTitle
      naic
      spRating
      ratingClass
      planName
      showAdditionalBenefitsDescription
    }
    totalElements
  }
}
    `;

/**
 * __useMedigapPlansQuery__
 *
 * To run a query within a React component, call `useMedigapPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedigapPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedigapPlansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMedigapPlansQuery(baseOptions: Apollo.QueryHookOptions<MedigapPlansQuery, MedigapPlansQueryVariables> & ({ variables: MedigapPlansQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedigapPlansQuery, MedigapPlansQueryVariables>(MedigapPlansDocument, options);
      }
export function useMedigapPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedigapPlansQuery, MedigapPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedigapPlansQuery, MedigapPlansQueryVariables>(MedigapPlansDocument, options);
        }
export function useMedigapPlansSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedigapPlansQuery, MedigapPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedigapPlansQuery, MedigapPlansQueryVariables>(MedigapPlansDocument, options);
        }
export type MedigapPlansQueryHookResult = ReturnType<typeof useMedigapPlansQuery>;
export type MedigapPlansLazyQueryHookResult = ReturnType<typeof useMedigapPlansLazyQuery>;
export type MedigapPlansSuspenseQueryHookResult = ReturnType<typeof useMedigapPlansSuspenseQuery>;
export type MedigapPlansQueryResult = Apollo.QueryResult<MedigapPlansQuery, MedigapPlansQueryVariables>;
export const SaveMedigapQuoteDocument = gql`
    mutation saveMedigapQuote($input: MedigapQuoteInput!) {
  saveMedigapQuote(input: $input) {
    clientId
    mgQuoteId
  }
}
    `;
export type SaveMedigapQuoteMutationFn = Apollo.MutationFunction<SaveMedigapQuoteMutation, SaveMedigapQuoteMutationVariables>;

/**
 * __useSaveMedigapQuoteMutation__
 *
 * To run a mutation, you first call `useSaveMedigapQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMedigapQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMedigapQuoteMutation, { data, loading, error }] = useSaveMedigapQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMedigapQuoteMutation(baseOptions?: Apollo.MutationHookOptions<SaveMedigapQuoteMutation, SaveMedigapQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMedigapQuoteMutation, SaveMedigapQuoteMutationVariables>(SaveMedigapQuoteDocument, options);
      }
export type SaveMedigapQuoteMutationHookResult = ReturnType<typeof useSaveMedigapQuoteMutation>;
export type SaveMedigapQuoteMutationResult = Apollo.MutationResult<SaveMedigapQuoteMutation>;
export type SaveMedigapQuoteMutationOptions = Apollo.BaseMutationOptions<SaveMedigapQuoteMutation, SaveMedigapQuoteMutationVariables>;
export const MedigapCompaniesDocument = gql`
    query medigapCompanies($filters: MedigapPlansFilterInput!) {
  medigapCompanies(filter: $filters) {
    key
    name
    count
  }
}
    `;

/**
 * __useMedigapCompaniesQuery__
 *
 * To run a query within a React component, call `useMedigapCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedigapCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedigapCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMedigapCompaniesQuery(baseOptions: Apollo.QueryHookOptions<MedigapCompaniesQuery, MedigapCompaniesQueryVariables> & ({ variables: MedigapCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>(MedigapCompaniesDocument, options);
      }
export function useMedigapCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>(MedigapCompaniesDocument, options);
        }
export function useMedigapCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>(MedigapCompaniesDocument, options);
        }
export type MedigapCompaniesQueryHookResult = ReturnType<typeof useMedigapCompaniesQuery>;
export type MedigapCompaniesLazyQueryHookResult = ReturnType<typeof useMedigapCompaniesLazyQuery>;
export type MedigapCompaniesSuspenseQueryHookResult = ReturnType<typeof useMedigapCompaniesSuspenseQuery>;
export type MedigapCompaniesQueryResult = Apollo.QueryResult<MedigapCompaniesQuery, MedigapCompaniesQueryVariables>;
export const MedigapQuoteDocument = gql`
    query medigapQuote($id: UUID!) {
  medigapQuote(id: $id) {
    cId
    id
    clientId
    drugDeliveryType
    preferredDrugs {
      dosageRxcui
      frequency
      productRxcui
      purchaseFrequency
      quantity
    }
    medigapFilterState {
      age
      benefits
      companies
      county
      effectiveDate
      gender
      id
      monthlyPlanPremiumRanges
      planCategory
      planNames
      tobacco
      zip
    }
    preferredPharmacies
    userId
  }
}
    `;

/**
 * __useMedigapQuoteQuery__
 *
 * To run a query within a React component, call `useMedigapQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedigapQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedigapQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedigapQuoteQuery(baseOptions: Apollo.QueryHookOptions<MedigapQuoteQuery, MedigapQuoteQueryVariables> & ({ variables: MedigapQuoteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedigapQuoteQuery, MedigapQuoteQueryVariables>(MedigapQuoteDocument, options);
      }
export function useMedigapQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedigapQuoteQuery, MedigapQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedigapQuoteQuery, MedigapQuoteQueryVariables>(MedigapQuoteDocument, options);
        }
export function useMedigapQuoteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MedigapQuoteQuery, MedigapQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedigapQuoteQuery, MedigapQuoteQueryVariables>(MedigapQuoteDocument, options);
        }
export type MedigapQuoteQueryHookResult = ReturnType<typeof useMedigapQuoteQuery>;
export type MedigapQuoteLazyQueryHookResult = ReturnType<typeof useMedigapQuoteLazyQuery>;
export type MedigapQuoteSuspenseQueryHookResult = ReturnType<typeof useMedigapQuoteSuspenseQuery>;
export type MedigapQuoteQueryResult = Apollo.QueryResult<MedigapQuoteQuery, MedigapQuoteQueryVariables>;