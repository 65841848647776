import * as React from 'react';
import * as _ from "lodash";
import { QuoteContext } from './QuoteContext';

interface FavoritesContextState {
  toggleFavorite: (bidId: string, zip: string) => void,
  favorites: string[]
}

const defaultState: FavoritesContextState = {
  toggleFavorite: () => {},
  favorites: [],
};

export const FavoritesContext = React.createContext<FavoritesContextState>(defaultState);

export function FavoritesProvider(props: React.PropsWithChildren<FavoritesProviderProps>) {

  const [favorites, setFavorites] = React.useState<string[]>([]);
  const {id, favorites: quoteFavorites, save} = React.useContext(QuoteContext);

  React.useEffect(() => {
    if (quoteFavorites) {
      setFavorites(quoteFavorites || []);
    }
  }, [quoteFavorites])

  const toggleFavorite = (bidId: string, zip: string) => {
    setFavorites(prev => {
      const res = prev.indexOf(bidId) >= 0 ? _.without(prev, bidId) : [...prev, bidId];
      save({
        variables: {
          data: {
            zip,
            favorites: res,
            id
          }
        },
      })
      return res;
    })
  }

  return <FavoritesContext.Provider value={{favorites, toggleFavorite}}>
    {props.children}
  </FavoritesContext.Provider>

}

type FavoritesProviderProps = {}
