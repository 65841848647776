import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Box, SxProps, Typography } from '@mui/material';
import { GetPlansYear } from '@coverright/utils';
import { CoverageEntryOutput, DrugCalculationStage, PlanYear } from '@coverright/data-access/types/medicare';
import { DrugCostBreakdownVariant } from '../../lib/types';

interface DCBCoverageProps {
  plan: IDrugCostBreakdownPlan;
  variant?: DrugCostBreakdownVariant;
}

export default function Coverage({ plan, variant }: DCBCoverageProps) {
  const hitIC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.InitialCoverage
  );
  const hitCG = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.CoverageGap
  );
  const hitCC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.Catastrophic
  );

  return (
    <>
      <InitialCoverageSection plan={plan} entry={hitIC} variant={variant} />
      <CoverageGapSection plan={plan} entry={hitCG} variant={variant} />
      <CatastrophicSection plan={plan} entry={hitCC} variant={variant} />
    </>
  );
}

function InitialCoverageSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
  variant?: DrugCostBreakdownVariant
}) {
  return (
    <Section
      title={'Initial Coverage'}
      variant={props.variant}
      color={'#EFEBE1'}
      label={
        <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>Initial Coverage Phase</span> this
              year under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Initial Coverage Phase</span> this
              year under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
        </>
      }
      content={
        <>
          {props.plan?.drugDetails?.mrxAltDedAmount
            ? `This plan has a drug deductible of ${props.plan?.drugDetails?.mrxAltDedAmount}. That means you'll pay the retail drug price for your covered drugs up until that point, before your insurance starts paying.`
            : `This plan doesn't have a drug deductible. That means you'll pay a copay or co-insurance for all of your covered prescription drugs until you reach the Coverage Gap.`}
        </>
      }
    />
  );
}

function CoverageGapSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
  variant?: DrugCostBreakdownVariant
}) {
  return (
    <Section
      title={'Coverage Gap'}
      variant={props.variant}
      color={'#E0F0F5'}
      label={
        props.plan.planYear === PlanYear.Year2024 ? <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>
                Coverage Gap Phase in {intToMonth(props.entry.month)}
              </span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Coverage Gap Phase</span> this year
              under this plan based on the information you provided on your
              prescription drugs.
            </>
          )}
        </> : <></>
      }
      content={
        props.plan.planYear === PlanYear.Year2024 ? <>
          After you pay your deductible (if applicable), you’ll pay a copay or
          co-insurance on your drugs up until the sum of your portion of costs
          and what the plan has paid is{' '}
          {props.plan?.drugDetails?.initialCoverageLimit} (in{' '}
          {(props.plan.planYear || GetPlansYear())
            .toString()
            .replace('Year', '')}
          ) per year, and then you’ll enter the Coverage Gap (or “donut hole”) phase, where you will typically pay 25% of retail cost for your drugs.
        </> : <>
          After you pay your deductible (if applicable), you’ll pay a copay or co-insurance on your drugs. The plan pays
          the rest. Once you, and others on your behalf, have paid a combined total of {props.plan?.drugDetails?.initialCoverageLimit}, you move to the
          Catastrophic Coverage stage.
        </>
      }
    />
  );
}

function CatastrophicSection(props: {
  plan: IDrugCostBreakdownPlan;
  entry?: CoverageEntryOutput;
  variant?: DrugCostBreakdownVariant
}) {
  return (
    <Section
      title={'Catastrophic Coverage'}
      variant={props.variant}
      color={'#FCF2C9'}
      label={
        <>
          {!!props.entry && (
            <>
              You most likely will enter the{' '}
              <span className={'underline'}>
                Catastrophic Coverage Phase in {intToMonth(props.entry.month)}
              </span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
          {!props.entry && (
            <>
              You most likely won't enter the{' '}
              <span className={'underline'}>Catastrophic Coverage Phase</span>{' '}
              this year under this plan based on the information you provided on
              your prescription drugs.
            </>
          )}
        </>
      }
      content={
        <>
          You will enter the Catastrophic Coverage phase once your total
          out-of-pocket costs reaches{' '}
          {props.plan?.drugDetails?.catastrophicThreshold} (in{' '}
          {(props.plan.planYear || GetPlansYear())
            .toString()
            .replace('Year', '')}
          ). Under this phase, you’ll pay the{' '}
          {props.plan?.drugDetails?.catastrophicCopayGeneric} of retail price
          for generic drugs, and{' '}
          {props.plan?.drugDetails?.catastrophicCopayBranded} of retail price
          for branded drugs.
        </>
      }
    />
  );
}

interface SectionProps {
  title: string;
  label: React.JSX.Element;
  content: React.JSX.Element;
  variant?: DrugCostBreakdownVariant;
  color: string;
}

const Section = ({ title, label, content, variant, color }: SectionProps) => {
  return (
    <Box sx={{
      borderLeft: variant === 'z1' ? `8px solid ${color}` : undefined,
      pl: variant === 'z1' ? 3 : undefined
    }}>
      {['z1', 'v3'].includes(variant as string) && (
        <Typography variant={'h4'} sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      )}
      <Label variant={variant}> {label} </Label>
      <Content variant={variant}> {content} </Content>
    </Box>
  );
};

const Label = ({ variant, children }: PropsWithChildren<{ variant?: DrugCostBreakdownVariant }>) => {
  const sx: SxProps = {
    color: variant === 'v3' ? '#666666' : undefined,
    fontWeight: 600,
    fontSize: variant !== 'v3' ? 14 : undefined,
    lineHeight: !variant ? '20px' : undefined
  };

  return <Typography sx={sx}>{children}</Typography>;
};

const Content = ({ variant, children }: PropsWithChildren<{ variant?: DrugCostBreakdownVariant }>) => {
  const sx: SxProps = {
    color: variant === 'v3' ? '#666666' : variant === 'z1' ? 'text.secondary' : undefined,
    fontSize: !variant ? 13 : variant === 'z1' ? 14 : undefined,
    lineHeight: !variant ? '20px' : undefined
  };

  return <Typography sx={sx}>{children}</Typography>;
};

function intToMonth(month: number): string {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
}
