import {
  ProfileOption,
  UserProfileInput,
  useSaveUserProfileMutation,
} from '@coverright/data-access/types/enrollment';
import { trkdisPromise } from '../lib/trkdis-promise';
import { useLogEvent } from '@coverright/shared/analytics';
import {
  getCId,
  getMedigapQuoteId,
  getPdpQuoteId,
  getQuoteId,
  getStoredUser,
  setStoredUser,
} from '@coverright/utils';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useCreateProfile() {
  const [saveProfile] = useSaveUserProfileMutation({
    client: enrollmentClient,
  });
  const logEvent = useLogEvent();

  const save = async (data: Partial<UserProfileInput>) => {
    const res = await saveProfile({
      variables: {
        data: {
          options: [ProfileOption.RegisterUser],
          medigapQuoteId: getMedigapQuoteId(),
          pdpQuoteId: getPdpQuoteId(),
          quoteId: getQuoteId(),
          cId: getCId(),
          profileId: getStoredUser()?.profileId,
          gender: getStoredUser()?.gender,
          birthDate: getStoredUser()?.birthDate,
          tobacco:
            typeof getStoredUser()?.tobacco !== 'undefined'
              ? getStoredUser()?.tobacco === 'true'
              : undefined,
          zip: getStoredUser()?.zip,
          countyName: getStoredUser()?.countyName,
          state: getStoredUser()?.state,
          enrolled: getStoredUser()?.enrolled,
          ...data,
        },
      },
    });
    await trkdisPromise();

    const userProfile = res.data?.saveUserProfile;

    (window as any).gtm_profile_id = userProfile?.profileId;

    logEvent('profile-saved', { profile_id: userProfile?.profileId });

    if ((window as any).hj) {
      (window as any).hj('identify', getCId(), {
        email: getStoredUser()?.email,
        c_id: getCId(),
      });
    } else {
      logEvent('error', { message: 'No hotjar on the page' });
      console.warn('No hotjar on the page');
    }

    setStoredUser({ ...getStoredUser(), profileId: userProfile?.profileId });

    return userProfile;
  };

  return save;
}
