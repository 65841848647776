'use client';
import { Button, ButtonProps } from '@mui/material';
import * as React from 'react';
import { useCalendlyModal } from '../hooks/use-calendly-modal';
import { CalendlyProps } from '../lib/types';

interface Props extends ButtonProps {
  calendlyProps?: CalendlyProps & { width?: number | string };
  onClose?: () => void;
}

export const ScheduleACallButton = (props: Props) => {
  const openCalendly = useCalendlyModal();
  const { calendlyProps, onClose, children, ...rest } = props;

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      {...rest}
      onClick={e => {
        props.onClick && props.onClick(e);
        openCalendly(calendlyProps).finally(onClose)
      }}
    >
      {children || 'Schedule A Call'}
    </Button>
  );
};
