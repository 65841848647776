import React from "react";
import { Box, ButtonBase, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import {CaretDown} from '@phosphor-icons/react';

interface Props {
  sx?: SxProps,
  expanded: boolean,
  onToggle: (expanded: boolean, event: React.MouseEvent<HTMLButtonElement>) => void,
  getTitle?: (expanded: boolean) => string,
  iconProps?: {
    size?: number,
    color?: string
  }
}

export const SeeMore = ({expanded, onToggle, sx, getTitle, iconProps}: Props) => (
  <ButtonBase sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center',  ...sx }} onClick={e => onToggle(expanded, e)}>
    <Typography sx={{fontSize: 11, mr: .5}}>
      {getTitle ? getTitle(expanded) : `See ${!expanded ? 'more' : 'less'}`}
    </Typography>
    <Box sx={{
      transform: expanded ? 'rotate(-180deg) !important' : 'rotate(0)',
      transition: 'all 0.3s',
      display: 'flex',
      alignItems: 'center',
    }}>
      <CaretDown size={iconProps?.size ?? 10} color={iconProps?.color ?? '#1C434F'} weight={'bold'} />
    </Box>
  </ButtonBase>
)
