'use client';
import * as React from 'react';
import { CRModal } from '@coverright/ui/shared/crmodal';
import { Box, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react/dist/ssr/X';
import { CalendlyCallType, CalendlyProps } from '../lib/types';
import { Calendly } from './calendly';
import { getDisclamers } from '@coverright/utils';

export interface ModalProps extends Partial<CalendlyProps> {
  onClose: (value: boolean) => void;
  width?: number | string;
  open?: boolean;
  logEvent?: (message: string, data?: any) => void;
}

export const CalendlyModal = (props: ModalProps) => {
  const { width, logEvent, ...rest } = props;

  React.useEffect(() => {
    if (logEvent && props.open !== false) {
      logEvent('Calendly modal opened');
    }
  }, [props.open, logEvent]);

  const onClose = (val: boolean) => {
    logEvent && logEvent('Calendly modal closed', { scheduled: val });
    props.onClose(val);
  };

  return (
    <CRModal
      slotProps={{
        backdrop: {
          sx: {
            background: 'rgba(0, 0, 0, 0.25)'
          }
        }
      }}
      width={width || 725}
      id={'calendly-modal'}
      paperPadding={0}
      open={props.open === undefined ? true : props.open}
      onClose={() => onClose(false)}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1 }}
          data-test="modal-close-button"
          onClick={() => onClose(false)}
        >
          <X size={32} color="#000" />
        </IconButton>
        <Box sx={{ mx: 4, mt: 4, mb: 1 }}>
          <Typography sx={{ lineHeight: '21px', fontWeight: 600, mb: 1 }}>
            Schedule a call
          </Typography>
          <Typography sx={{ fontSize: 16, lineHeight: '24px' }}>
            We have licensed insurance agents ready to help answer your
            questions. Select date and time that works best for you.
          </Typography>
        </Box>
        <Calendly
          {...rest}
          type={rest.type || CalendlyCallType.consultation}
          maxWidth={1000}
          onEventScheduled={(data) => {
            rest.onEventScheduled && rest.onEventScheduled(data);
            onClose(true);
          }}
        />
        <Box sx={{ m: 4 }}>
          <Typography sx={{ fontSize: 14, lineHeight: '24px' }}>
            {getDisclamers('SCHEDULE_A_CALL', props.maOrgCount && props.maPlansCount ? {maOrgCount: props.maOrgCount, maPlansCount: props.maPlansCount} : undefined)}
          </Typography>
        </Box>
      </Box>
    </CRModal>
  );
};
