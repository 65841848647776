import * as React from 'react';
import { Box, Button, IconButton, Theme, Typography } from '@mui/material';
import { CRModal } from '@coverright/ui/shared';
import { DrugInfoWithPack } from '@coverright/data-access/types/medicare';
import { Pill, X } from '@phosphor-icons/react';

interface ConfirmBrandedDrugModalProps {
  open: boolean;
  drug?: DrugInfoWithPack;
  onClose: (useGeneric?: boolean) => void;
}

export default function ConfirmBrandedDrugModal(
  props: ConfirmBrandedDrugModalProps
) {
  return (
    <CRModal
      width={500}
      open={props.open}
      onClose={() => props.onClose()}
      data-test={'confirm-branded-drug-modal'}
      paperPadding={'24px'}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '-8px',
            mr: '-8px',
          }}
        >
          <IconButton onClick={() => props.onClose()}>
            <X size={24} color="#000000" weight="regular" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Pill size={32} color="#1C434F" weight="fill" />
          <Typography variant={'h4'} className={'mt-16 mb-24'} align={'center'}>
            Generic version available
          </Typography>
          <Typography
            variant={'body2'}
            className={'medium mb-8'}
            align={'center'}
          >
            Selected Branded Drug
          </Typography>
          <Typography
            variant={'body2'}
            sx={{ color: '#094451' }}
            className={'medium mb-24'}
            align={'center'}
          >
            {props.drug?.name}
          </Typography>
          <Typography
            variant={'body2'}
            className={'bold mb-8'}
            align={'center'}
          >
            Available Generic Version
          </Typography>
          <Typography
            variant={'body2'}
            sx={{ color: '#094451' }}
            className={'medium mb-24'}
            align={'center'}
          >
            {props.drug?.genericName}
          </Typography>
          <Button
            data-test={'add_generic_drug_button'}
            fullWidth
            variant={'contained'}
            color={'primary'}
            className={'mb-16'}
            onClick={() => props.onClose(true)}
          >
            Add generic drug
          </Button>
          <Button
            data-test={'add_branded_drug_button'}
            fullWidth
            variant={'outlined'}
            color={'primary'}
            className={'branded-drug-button'}
            onClick={() => props.onClose(false)}
          >
            Continue with branded
          </Button>
        </Box>
      </>
    </CRModal>
  );
}
