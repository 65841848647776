import React from 'react';
import { Box, Button, Collapse, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { colors } from '@coverright/ui/themes';
import { planStyles } from '../../plan-list/components/PlanCard';
import { ComparisonContext } from '../../../ComparisonContext';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { getCompanyLogo } from '@coverright/utils';
import PlanRating from '../../../components/PlanRating';
import { Plan } from '@coverright/data-access/types/medicare';
import ContainerDimensions from 'react-container-dimensions';
import { ComparablePlanType } from '@coverright/data-access/medicare';


const styles = makeStyles((theme: Theme) =>
  createStyles({
    removeButton: {
      background: 'rgba(164, 180, 185, 0.1)',
      width: 30,
      maxWidth: 30,
      height: 30,
    },
    planName: {
      fontWeight: 500,
      color: colors.custom.green.variant2
    },
    premium: {
      fontWeight: 500,
    },
    logo: {
      height: 33,
      width: '100%',
      minWidth: 99,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    ratingIcon: {
      width: 20,
      height: 20,
      marginRight: 4
    }
  }),
);

type CompareHeaderCardProps = {
  plan: Plan
}

export default function CompareHeaderCard({plan}: CompareHeaderCardProps) {
  const {zip, countyName} = useParams<any>();
  const navigate = useNavigate();
  const classes = styles();
  const planClasses = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const [collapsed, setCollapsed] = React.useState(phone);
  const ref = React.useRef(null);

  useScrollPosition(({currPos}) => {
    if (currPos.y < -150 && !collapsed) {
      setCollapsed(true)
    } else {
      if (currPos.y > -50 && !phone) {
        setCollapsed(false)
      }
    }
  }, [collapsed, phone])

  React.useEffect(() => {
    if (phone) {
      setCollapsed(phone)
    }
  }, [phone]);

  return <ContainerDimensions>
    {({height}) => {
      return <Box ref={ref} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}
                  height={!collapsed && (ref?.current as any)?.clientHeight && (ref?.current as any)?.clientHeight < height - 21 ? height - 21 : undefined}>
        <Box display={'flex'} flex={1} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box width={'99px'} height={'33px'}>
              <div className={classes.logo}
                   style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}/>
            </Box>
            <IconButton onClick={() => removeFromCompare(ComparablePlanType.Ma, plan.bidId)} className={classes.removeButton}><img
              src={'/assets/img/icon-close.svg'}/></IconButton>
          </Box>
          <Typography sx={{mt: collapsed ? 1 : 2}}
                      className={'fs-16 lh-20 medium comparison-header-title'}>{plan.planName}</Typography>
        </Box>
        <div className={'mt-8'}>
          <Collapse in={!collapsed}>
            <PlanRating plan={plan}/>
            <Typography className={'fs-36'}>{plan.monthlyCost}</Typography>
            <Typography className={'fs-12 lh-15 mb-10'}>Estimated average total cost per month</Typography>
          </Collapse>
          <Button className={planClasses.enrollButton}
                  onClick={() => {
                    navigate(`/completed/${zip}/${countyName}/${plan.bidId}`)
                  }}
                  variant={'contained'}>Select this plan</Button>
        </div>
      </Box>
    }}
  </ContainerDimensions>
}
