import {
  DrugInfoWithGeneric,
  DrugOutput,
} from '@coverright/data-access/types/medicare';
import { Box, Typography } from '@mui/material';
import { PlusCircle } from '@phosphor-icons/react';
import * as React from 'react';

type Props = {
  drug: DrugInfoWithGeneric | DrugOutput;
  onClick?: () => void;
};

export const DrugOption = (props: Props) => {
  return (
    <Box
      data-test={'drug_option_' + props.drug.name}
      sx={{
        display: 'flex',
        padding: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
          background: '#F5F5F5',
          '& .add-icon': {
            display: 'flex',
          },
        },
      }}
      onClick={props.onClick}
    >
      <Typography className={`bold drug-name`}>{props.drug.name}</Typography>
      <Box
        className={'add-icon'}
        sx={{ alignItems: 'center', display: 'none', gap: 1 }}
      >
        <PlusCircle size={16} color="#1C434F" weight="regular" />
        <Typography className={'medium'} sx={{ color: '#1C434F' }}>
          Add
        </Typography>
      </Box>
    </Box>
  );
};
