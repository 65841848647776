import { CRModal } from '@coverright/ui/shared';
import { Box, Typography } from '@mui/material';
import * as React from 'react';

export default function ThankYouModal(props: {open: boolean, onClose: () => void}) {
  return <CRModal BackdropProps={{
    sx: {
      background: 'rgba(0, 0, 0, 0.25)',
    }
  }} width={622} paperPadding={0} open={props.open} onClose={() => props.onClose()}>
    <div>
      <Box sx={{display: 'flex', gap: 4, alignItems: 'center', justifyContent: 'center', minHeight: '280px', flexDirection: 'column'}}>
        <Typography className={'fs-32 lh-32 medium'}>Thank you for submitting!</Typography>
        <img src={'/assets/img/CheckCircle.svg'} />
        <Typography className={'fs-21 lh-32 medium'}>A licensed insurance agent will contact you soon!</Typography>
      </Box>
    </div>
  </CRModal>
}
