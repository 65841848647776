import { createTheme } from '@mui/material';


declare module '@mui/material/Button' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ButtonPropsVariantOverrides {
    rounded: true,
  }
  interface ButtonPropsColorOverrides {
    grey: true,
    white: true,
  }
}

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
  custom: {
    green: {
      variant1: string,
      variant2: string,
      variant3: string,
      variant4: string,
      variant5: string,
    }
  }
} = {
  text: {
    primary: '#1C434F',
    secondary: '#778E95',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "#76A9FF",
    "500": "#4F91FF",
    "600": "#2979FF",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "#133774",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  },
  custom: {
    green: {
      variant1: '#5ECB9E',
      variant2: '#029094',
      variant3: '#06B9BE',
      variant4: '#00AF9E',
      variant5: '#1D9C80',
    }
  }
};

export const AppTheme = createTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.secondary["400"],
      main: colors.secondary["700"],
      dark: colors.secondary["900"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.15)',
    '0px 3px 5px rgba(0, 0, 0, 0.15)',
    '0px 4px 6px rgba(0, 0, 0, 0.15)',
    '0px 5px 7px rgba(0, 0, 0, 0.15)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  components: {
    DrugSelector: {
      styleOverrides: {
        drugCard: {
          backgroundColor: '#FFFFFF',
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
          backgroundColor: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'calc(50% - 8px)'
        },
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#CCD8DC',
          },
          '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'rgba(220, 16, 28, 0.05)',
          },
        },
        multiline: {
          padding: '11px 7px 10px',
          '& input': {
            padding: 0,
          }
        },
        input: {
          padding: '18px 20px 14px',
          fontSize: '15px',
          lineHeight: '21px',
          fontWeight: 500,
          fontFamily: 'Sailec,Roboto,Helvetica Neue,sans-serif',
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          border: `1px solid #029094!important`,
          backgroundColor: '#f2f9fa',
          borderRadius: 4,
          color: '#1C434F',
          fontSize: 16,
          textAlign: 'left',
          padding: '15px 20px 11px',
          fontWeight: 400,
          '& .MuiTooltip-arrow': {
            color: '#f2f9fa'
          },
          '& .MuiTooltip-arrow:before': {
            border: `1px solid #029094!important`
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '26px',
          '& .MuiOutlinedInput-input': {
            padding: '16px 20px 15px',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 500,
            fontFamily: 'Sailec,Roboto,Helvetica Neue,sans-serif',
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '5px',
          },
          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-input': {
            borderColor: `#029094!important`,
            borderWidth: '1px!important',
            backgroundColor: 'rgba(2, 144, 148, 0.05)'
          },
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          background: colors.custom.green.variant4,
          '&:hover': {
            background: colors.custom.green.variant3
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: colors.custom.green.variant2 + '!important',
          },
          '&.Mui-checked+.MuiSwitch-track': {
            backgroundColor: colors.custom.green.variant2 + '!important',
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-filled .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            // border: '1px solid #1C434F!important'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(123, 143, 149, 0.2)',
          borderRadius: '8px',
          height: '30px',
        },
        label: {
          fontSize: 12,
          lineHeight: '15px',
          paddingLeft: '8px',
        },
        deleteIcon: {
          marginRight: '8px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          fontSize: 14,
          lineHeight: '17px',
          fontWeight: 700,
          textTransform: 'none',
          boxShadow: 'none',
          padding: '12px',
          whiteSpace: 'nowrap',
        },
        containedSizeSmall: {
          fontSize: 12,
          textTransform: 'none',
          lineHeight: '15px',
          padding: '8px 24px'
        },
        outlinedSizeSmall: {
          padding: '6px 10px!important',
          fontSize: '12px!important',
          lineHeight: '15px!important',
        },
        containedSizeLarge: {
          fontSize: 20,
          textTransform: 'none',
          lineHeight: '25px',
          padding: '14px 24px'
        },
        outlinedSizeLarge: {
          fontSize: '20px !important',
          fontWeight: '500!important',
          textTransform: 'none',
          lineHeight: '25px',
          padding: '14px 24px'
        }
      },
      variants: [
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: colors.text.primary,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(94, 203, 158, 0.05)',
            },
          }
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'white',
            color: colors.custom.green.variant5,
            border: '1px solid #1D9C80',
            textTransform: 'none',
            padding: '12px 10px',
            borderRadius: '50px',
            fontSize: 14,
            lineHeight: '17px',
            '&:hover': {
              backgroundColor: 'white',
              border: '1px solid #20A286',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
            '&:active': {
              backgroundColor: 'white',
              border: '1px solid #20A286',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
          }
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            fontWeight: 400,
            backgroundColor: 'white',
            color: colors.text.primary,
            border: '1px solid #C7E2E7',
            textTransform: 'none',
            padding: '12px 10px',
            borderRadius: '50px',
            fontSize: 14,
            lineHeight: '17px',
            '&:hover': {
              backgroundColor: 'white',
              border: '1px solid #C7E2E7',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
            '&:active': {
              backgroundColor: 'white',
              border: '1px solid #C7E2E7',
              boxShadow: 'none',
            },
          }
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.custom.green.variant5,
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: colors.custom.green.variant4,
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
            '&:active': {
              backgroundColor: colors.custom.green.variant4,
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
          }
        },
        {
          props: { variant: 'contained', color: 'primary', disabled: true },
          style: {
            color: 'white!important',
            textTransform: 'none',
          }
        },
        {
          props: { variant: 'rounded', color: 'primary' },
          style: {
            backgroundColor: colors.custom.green.variant5,
            borderRadius: '4px',
            padding: '6px 16px',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: colors.custom.green.variant4,
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
            '&:active': {
              backgroundColor: colors.custom.green.variant4,
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
          }
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            backgroundColor: '#FF785A',
            color: 'white',
            textTransform: 'none',
            '&:disabled': {
              backgroundColor: '#FFC2B5',
              color: 'white',
            },
            '&:hover': {
              backgroundColor: '#FF551F',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
            '&:active': {
              backgroundColor: '#FF551F',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
            },
          }
        },
        {
          props: { variant: 'rounded', color: 'warning' },
          style: {
            backgroundColor: '#FF785A',
            borderRadius: '4px',
            padding: '6px 16px',
            color: 'white',
            textTransform: 'none',
            '&.Mui-disabled': {
              backgroundColor: '#FFC2B5',
              color: 'white',
            },
            '&:hover': {
              backgroundColor: '#FF551F',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
              color: 'white',
            },
            '&:active': {
              backgroundColor: '#FF551F',
              boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
              color: 'white',
            },
          }
        }
      ]
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E8ECED',
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(28,67,79,0.5)',
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: 4,
          '& ul': {
            padding: '10px 0',
          },
          '& li': {
            fontSize: 16,
            fontWeight: 500,
            padding: '8px 20px',
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginTop: 10,
          marginLeft: 0,
          lineHeight: '17px',
          fontSize: 14,
          left: 0,
          right: 0,
          marginRight: 0,
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          height: 15,
          width: 15,
          minWidth: 15,
          fontSize: 12
        },
        colorPrimary: {
          backgroundColor: colors.custom.green.variant2
        },
        anchorOriginBottomRightCircular: {
          right: '30%',
          bottom: '33%'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginTop: 3,
          '& p': {
            fontSize: 16
          }
        }
      }
    }
  },
  typography: {
    fontWeightBold: 500,
    fontFamily: [
      'Sailec', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
    allVariants: {
      color: colors.text.primary,
    },
    h1: {
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32.5px'
    },
    h2: {
      fontSize: '24px',
      fontWeight: 400
    },
    h3: {
      fontSize: '18px',
    } ,
    h4: {
      fontSize: '16px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '12px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '10px',
      fontWeight: 400,
    },
    body1: {
       fontSize: 13,
    },
    body2: {
       fontSize: 14,
    },
  },
});
