import {useMutation, MutationHookOptions} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  MutationSaveCompareYearToYearArgs,
} from '@coverright/data-access/types/medicare';

export function useSaveCompareYearToYear(options?: MutationHookOptions<{saveCompareYearToYear: boolean}, MutationSaveCompareYearToYearArgs>) {
  return useMutation<{saveCompareYearToYear: boolean}, MutationSaveCompareYearToYearArgs>(
    gql(saveRequestMutation),
    options
  );
}

const saveRequestMutation = `
mutation ($input: CompareYearToYearInput!) {
  saveCompareYearToYear(input: $input)
}`;
