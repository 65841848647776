import { Box } from '@mui/material';
import { SxProps } from '@mui/material';
import './v3-preloader.scss'

export function V3Preloader({sx}: {sx?: SxProps}) {
  return <Box data-test={'preloader'}
              sx={{position: 'relative', minHeight: 100, maxWidth: 100, m: 'auto', ...sx}}>
    <div className="preloader">
      <hr/>
      <hr/>
      <hr/>
    </div>
  </Box>
}
