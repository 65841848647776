import React from "react";
import paths from "../config/router-paths";
import { useNavigate } from "react-router-dom";
import { getStoredUser } from '@coverright/utils';
import { CRPreloader } from '@coverright/ui/shared';
import { Box } from '@mui/material';


const Hub = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (getStoredUser()?.zip && getStoredUser()?.countyName) {
      navigate(paths.plans + getStoredUser()?.zip + '/' + getStoredUser()?.countyName + document.location.search);
    } else {
      navigate(paths.zip + document.location.search);
    }
  }, [navigate])

  return <Box
    sx={{
      minHeight: '100vh',
      maxHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    }}
  >
    <CRPreloader />
  </Box>;
}


export default Hub;
