import {  useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import {gql} from "@apollo/client";
import {
  QueryCompareYearToYearByClientIdArgs,
  CompareYearToYearOutput, Maybe
} from '@coverright/data-access/types/medicare';

export function useCompareYearToYearByClientId(options?: LazyQueryHookOptions<{compareYearToYearByClientId: Maybe<CompareYearToYearOutput>}, QueryCompareYearToYearByClientIdArgs>) {
  return useLazyQuery<{compareYearToYearByClientId: Maybe<CompareYearToYearOutput>}, QueryCompareYearToYearByClientIdArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options,
  })
}


const query = `
query($clientId: UUID!) {
  compareYearToYearByClientId(clientId: $clientId) {
    clientId
    county
    createdAt
    currentMgPlanKey
    currentMaPlanId
    currentMgNaic
    currentMgPlanName
    currentPdpPlanId
    currentYear
    isLocked
    id
    maIsHidden
    mgIsHidden
    pdpIsHidden
    suggestedMaPlanId
    suggestedMgPlanKey
    suggestedMgNaic
    suggestedMgPlanName
    suggestedPdpPlanId
    updatedAt
    zip
  }
}`;
