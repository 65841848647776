'use client'
import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { CalendlyModal } from '../ui/calendly-modal';
import { CalendlyProps } from '../lib/types';
import { logEvent } from '@coverright/shared/analytics/logEvent';
import { useClientAgent } from '@coverright/shared/contexts/agent-context';

export function useCalendlyModal() {
  const {agent} = useClientAgent();

  return (props?: CalendlyProps & { width?: number | string }) => {
    return new Promise<boolean>((resolve) => {
      const container = getContainer();
      const root = createRoot(container);

      document.body.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('padding-right', '15px');

      root.render(
        <CalendlyModal
          agent={agent}
          {...props}
          logEvent={logEvent}
          onClose={(value: boolean) => {
            resolve(value);
            const modal = document.getElementById('calendly-modal');
            if (modal) {
              document.body.removeChild(modal);
            }
            if (container) {
              document.body.removeChild(container);
            }
            document.body.style.setProperty('overflow', null);
            document.body.style.setProperty('padding-right', null);
          }}
        />
      );
    });
  };
}

const getContainer = () => {
  let div: any;
  if (!document.getElementById('calendly-modal-container')) {
    div = document.createElement('div');
    div.id = 'calendly-modal-container';
    document.body.appendChild(div);
  }
  return document.getElementById('calendly-modal-container') as HTMLElement;
}
