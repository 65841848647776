import React from "react";
import {QueryLazyOptions} from "@apollo/client";
import { FilterItem, QueryPlanTypesArgs } from '@coverright/data-access/types/medicare';
import { useMedicareFilterData } from '@coverright/data-access/medicare';
import { getElevanceCompanies } from '@coverright/features';

interface PlanFilterDataContextState {
  getPlanFilterData: (data?: QueryLazyOptions<QueryPlanTypesArgs & {quoteId?: string | null}>) => void,
  planFilterData: PlanFilterData | undefined,
}

export const PlanFilterDataContext = React.createContext<PlanFilterDataContextState>({
  getPlanFilterData: () => {},
  planFilterData: undefined
});

export function PlanFilterDataProvider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const [planFilterData, setPlanFilterData] = React.useState<PlanFilterData>();

  const [request] = useMedicareFilterData();

  const getPlanFilterData = async (data?: QueryLazyOptions<QueryPlanTypesArgs & {quoteId?: string | null}>) => {
    const res = await request(data)

    if (res.data) {
      let elevanceCompanies = await getElevanceCompanies(data?.variables?.filter.zip)
      if (res.data.companies.find(c => c.key === 'Elevance Health, Inc.')?.count === 0) {
        elevanceCompanies = elevanceCompanies.map(c => ({...c, count: 0}))
      }

      setPlanFilterData({
        SNPTypes: transformListItem(res.data.SNPTypes),
        companies: transformListItem([...res.data.companies.filter(c => !c.key.toLowerCase().includes('elevance')), ...elevanceCompanies]),
        extraBenefits: transformListItem(res.data.extraBenefits),
        maxCostRanges: transformListItem(res.data.maxCostRanges),
        maxOutOfPocketRanges: transformListItem(res.data.maxOutOfPocketRanges),
        partBPremiumReductionCount: res.data.partBPremiumReductionCount,
        planTypes: transformListItem(res.data.planTypes),
        starRatings: transformListItem(res.data.findStarRatings),
      })
    }
  }

  return <PlanFilterDataContext.Provider value={{getPlanFilterData, planFilterData}}>
    {props.children}
  </PlanFilterDataContext.Provider>

}

export type ListItem = {
  value: any,
  label: any,
  disabled?: boolean
}

interface PlanFilterData {
  companies: ListItem[],
  planTypes: ListItem[],
  SNPTypes: ListItem[],
  extraBenefits: ListItem[],
  maxCostRanges: ListItem[],
  maxOutOfPocketRanges: ListItem[],
  starRatings: ListItem[],
  partBPremiumReductionCount: number,
}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}



function transformListItem(collection: FilterItem[]): ListItem[] {
  const replace = (name: string) => name.replace("Over-the-counter benefits", "Over-the-counter Allowance")
    .replace('Cigna', 'Cigna Healthcare')
    .replace('UnitedHealthcare', 'UnitedHealthcare®')
  return collection.map((item: any) => ({
      value: item.key,
      label: (item.count > 0) ? `${replace(item.name)} (${item.count})`: replace(item.name),
      disabled: item.count === 0
    })
  )
}
