import * as React from 'react';
import * as _ from 'lodash';
import { Box } from '@mui/material';
import AddressSelectionModal from './ui/address-selection-modal';
import {
  ProviderAddressOutput,
  ProviderOutput,
} from '@coverright/data-access/types/medicare';
import { DoctorAutocomplete } from './ui/doctor-autocomplete';
import { DoctorCard } from './ui/doctor-card';
import { useState } from 'react';

type DoctorSelectionProps = {
  inputPosition?: 'top' | 'bottom';
  zip: string;
  value: ProviderOutput[];
  onChange: (data: ProviderOutput[]) => void;
};

export const DoctorSelection = (props: DoctorSelectionProps) => {
  const [doctor, setDoctor] = React.useState<ProviderOutput>();
  const [addresses, setAddresses] = useState<ProviderAddressOutput[]>([]);

  const handleDoctorSelect = (value: ProviderOutput) => {
    if (value.addressesNumber <= 1) {
      props.onChange([...props.value, value]);
      setDoctor(undefined);
    } else {
      setDoctor({
        ...value,
        addresses: [],
      });
      setAddresses(value.addresses);
    }
  };

  const onModalClose = (addresses?: ProviderAddressOutput[]) => {
    if (addresses && doctor) {
      props.onChange([
        ..._.differenceWith(
          props.value,
          [doctor],
          (v1, v2) => v1.npi === v2.npi
        ),
        {
          ...doctor,
          addresses,
        },
      ]);
    }
    setDoctor(undefined);
    setAddresses([]);
  };

  return (
    <>
      {doctor && doctor.addressesNumber > 1 && (
        <AddressSelectionModal doctor={doctor} onClose={onModalClose} addresses={addresses} />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection:
            props.inputPosition === 'top' ? 'column' : 'column-reverse',
          gap: props.value.length ? 4 : 0,
        }}
      >
        <DoctorAutocomplete
          zip={props.zip}
          onSelect={handleDoctorSelect}
          filterOptionsFunc={(doctor) =>
            !props.value.some((d) => d.npi === doctor.npi)
          }
        />
        {props.value.map((doctor) => (
          <DoctorCard
            doctor={doctor}
            showChangeLocation={doctor.addressesNumber > 1}
            onDeleteClick={() =>
              props.onChange(props.value.filter((d) => d !== doctor))
            }
            key={doctor.npi}
            onChangeLocationClick={() => setDoctor(doctor)}
          />
        ))}
      </Box>
    </>
  );
};
