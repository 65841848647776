import * as React from 'react';
import { useCompareOffer, useSaveCompareOffer } from '@coverright/data-access/medicare';
import { AdminContext } from '@coverright/shared/contexts';
import { ComparablePlan, ComparablePlanInput, ComparablePlanType } from '@coverright/data-access/types/medicare';
import { KeycloakContext } from '@coverright/shared/keycloak';

interface CompareOfferContextState {
  refetch: () => void,
  togglePlan: (plan: ComparablePlan) => void,
  changePdpPrimary: (bidId: string) => void,
  plans: ComparablePlan[],
  loading: boolean,
  clientId?: string
}

const defaultState: CompareOfferContextState = {
  refetch: () => {},
  changePdpPrimary: () => {},
  togglePlan: () => {},
  plans: [],
  loading: false
};

export const CompareOfferContext = React.createContext<CompareOfferContextState>(defaultState);

export function CompareOfferContextProvider(props: React.PropsWithChildren<CompareOfferProviderProps>) {

  const [plans, setPlans] = React.useState<ComparablePlan[]>([]);
  const [clientId, setClientId] = React.useState<string>();
  const {initialized, keycloak, isAdmin} = React.useContext(KeycloakContext);
  const adminContext = React.useContext(AdminContext);
  const [saveCompareOffer, saveCompareOfferData] = useSaveCompareOffer();
  const [getCompareOffer, compareOfferData] = useCompareOffer();


  React.useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      if (props.adminOnly && isAdmin || !props.adminOnly)
      getCompareOffer({ variables: { clientId: adminContext?.clientId } })
    }
  }, [adminContext, props.adminOnly, initialized, keycloak?.authenticated, isAdmin])

  React.useEffect(() => {
    if (!compareOfferData.loading) {
      if (compareOfferData?.data?.compareOffer?.plans) {
        setPlans(compareOfferData?.data?.compareOffer?.plans)
      }
      if (compareOfferData?.data?.compareOffer?.clientId) {
        setClientId(compareOfferData?.data?.compareOffer?.clientId)
      }
    }
  }, [compareOfferData])

  const loading = React.useMemo(() => {
    return compareOfferData.loading || saveCompareOfferData.loading
  }, [compareOfferData.loading, saveCompareOfferData.loading])

  const togglePlan = React.useCallback((plan: ComparablePlan) => {
    if (adminContext && !loading) {
      setPlans(prev => {
        let temp = [...prev];
        if (temp.map(o => o.bidId).includes(plan.bidId)) {
          temp.splice(plans.findIndex(p => p.bidId === plan.bidId), 1);
        } else {
          temp.push(plan)
        }

        saveCompareOffer({
          variables: {
            compareOffer: {
              clientId: adminContext.clientId,
              id: compareOfferData?.data?.compareOffer?.id,
              plans: temp
            }
          }
        }).then(() => getCompareOffer({variables: {clientId: adminContext.clientId}}))
        return temp;
      })
    }
  }, [adminContext, loading])

  const changePdpPrimary = React.useCallback((bidId: string) => {
    if (adminContext && !loading) {
      setPlans(prev => {
        const temp = prev.reduce((prev, current) => {
          if (current.type === ComparablePlanType.Pdp) {
            return [...prev, {...current, pdpPrimary: current.bidId === bidId}];
          }
          return [...prev, current]
        }, [] as ComparablePlanInput[]);

        saveCompareOffer({
          variables: {
            compareOffer: {
              clientId: adminContext.clientId,
              id: compareOfferData?.data?.compareOffer?.id,
              plans: temp
            }
          }
        }).then(() => getCompareOffer({variables: {clientId: adminContext.clientId}}))
        return temp;
      })
    }
  }, [adminContext, loading])

  return <CompareOfferContext.Provider value={{plans, clientId, togglePlan, loading, changePdpPrimary, refetch: compareOfferData.refetch}}>
    {props.children}
  </CompareOfferContext.Provider>

}

type CompareOfferProviderProps = {
  adminOnly?: boolean
}

export const withCompareOfferContext = (WrappedComponent: any, adminOnly?: boolean) => (props: any) => {
  return (
    <CompareOfferContextProvider adminOnly={adminOnly}>
      <WrappedComponent {...props} />
    </CompareOfferContextProvider>
  )
}

