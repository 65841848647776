import * as React from 'react';
import * as _ from 'lodash';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  SelectChangeEvent, Stack,
  Typography
} from '@mui/material';
import { useGetPackagesDescription } from '@coverright/data-access/medicare';
import { CRModal } from '@coverright/ui/shared';
import {
  CalcDrugFrequencyPeriod,
  FrequencyPeriod,
  PackInfoOuput,
} from '@coverright/data-access/types/medicare';
import { X, ArrowLeft, Pill } from '@phosphor-icons/react';
import { LoadingButton } from '@mui/lab';
import { SelectInput, TextInput } from '@coverright/ui/inputs';
import { DrugDosage, DrugName, PreferredDrug } from '../lib/types';
import { ModalCloseButton } from '@coverright/shared/ui';

const frequencies = [
  CalcDrugFrequencyPeriod.Daily,
  CalcDrugFrequencyPeriod.Weekly,
  CalcDrugFrequencyPeriod.Monthly,
  CalcDrugFrequencyPeriod.Quarterly,
  CalcDrugFrequencyPeriod.HalfYearly,
  CalcDrugFrequencyPeriod.Yearly
];

interface DrugDosageModalModalProps {
  open: boolean;
  showBackButton: boolean;
  drug?: DrugName;
  onBackButtonClick: () => void;
  onPackageSelected: (rxcui: string) => void;
  dosage?: PreferredDrug;
  packages?: PackInfoOuput[];
  onClose: (result?: DrugDosage) => void;
}

const initialDosage: DrugDosage = {
  dosage: '',
  quantity: undefined,
  frequency: 'MONTHLY',
  purchaseFrequency: 'MONTHLY' as FrequencyPeriod,
};

export function DrugDosageModal(props: DrugDosageModalModalProps) {
  const [dosage, setDosage] = React.useState<DrugDosage>(initialDosage);
  const [getDescription, { data, loading }] = useGetPackagesDescription();
  const [description, setDescription] = React.useState<string>();
  const [unitLabel, setUnitLabel] = React.useState<string>('-');

  React.useEffect(() => {
    if (props.open) {
      setDosage(
        props.dosage
          ? {
              frequency:
                props.dosage?.frequency || CalcDrugFrequencyPeriod.Daily,
              quantity: props.dosage?.quantity,
              dosage: props.dosage?.dosageRxcui || '',
              purchaseFrequency:
                props.dosage?.purchaseFrequency || FrequencyPeriod.Monthly,
            }
          : initialDosage
      );
      if (props.dosage?.dosageRxcui) {
        getDescription({ variables: { rxcui: props.dosage.dosageRxcui } });
      }
    } else {
      setDosage(initialDosage);
      setDescription(undefined);
      setUnitLabel('-');
    }
  }, [props.open]);

  React.useEffect(() => {
    setDescription(data?.packageDescriptionByRxcui?.packageDescription || '');
    setUnitLabel(data?.packageDescriptionByRxcui?.unitType || '-');
  }, [data]);

  const handlePackageSelected = (event: SelectChangeEvent<any>) => {
    setDosage({
      ...dosage,
      dosage: event.target.value,
    });
    getDescription({ variables: { rxcui: event.target.value } });
    props.onPackageSelected(event.target.value);
  };

  const handleFrequencyChanged = (event: SelectChangeEvent<unknown>) => {
    const frequency = event.target.value as string;
    let purchaseFrequency: FrequencyPeriod;

    switch (frequency) {
      case CalcDrugFrequencyPeriod.Quarterly: purchaseFrequency = FrequencyPeriod.Quarterly; break;
      case CalcDrugFrequencyPeriod.HalfYearly: purchaseFrequency = FrequencyPeriod.HalfYearly; break;
      case CalcDrugFrequencyPeriod.Yearly: purchaseFrequency = FrequencyPeriod.Yearly; break;
      default: purchaseFrequency = dosage.purchaseFrequency;
    }

    setDosage({
      ...dosage,
      frequency,
      purchaseFrequency,
    });
  }

  return (
    <CRModal
      width={600}
      open={props.open}
      onClose={() => props.onClose()}
      paperPadding={'24px'}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: props.showBackButton ? 'space-between' : 'flex-end',
            m: -1,
          }}
        >
          {props.showBackButton && (
            <Button
              variant={'text'}
              color={'primary'}
              startIcon={
                <ArrowLeft size={24} color="#1C434F" weight="regular" />
              }
              sx={{ textTransform: 'none', color: '#1C434F' }}
              className={'medium'}
              onClick={props.onBackButtonClick}
            >
              Back
            </Button>
          )}
          <ModalCloseButton onClick={() => props.onClose()} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Pill size={32} color="#1C434F" weight="fill" />
          <Typography variant={'h4'} className={'mt-16 mb-8'} align={'center'}>
            {props.drug?.name}
          </Typography>
          <Typography
            className={'mb-32'}
            color={'textSecondary'}
            align={'center'}
          >
            Enter dosage and frequency
          </Typography>
          <SelectInput
            label={'What dosage do you take?'}
            data-test={'dosage_select'}
            placeholder={'Select dosage'}
            className={'mb-0'}
            hideTick
            value={dosage?.dosage}
            onChange={handlePackageSelected}
          >
            {props.packages?.map((p) => (
              <MenuItem
                data-test={'dosage_select_item_' + p.rxcui}
                key={p.rxcui}
                value={p.rxcui as string}
                className={'regular'}
              >
                {p.name}
              </MenuItem>
            ))}
          </SelectInput>
          <Collapse sx={{ width: 1 }} in={!!description}>
            <Stack spacing={2} mt={2}>
              <div>
                <Typography
                  variant={'body2'}
                  className={'bold'}
                  align={'center'}
                >
                  Package contains:
                </Typography>
                <Typography
                  variant={'body2'}
                  data-test={'drug-description'}
                  className={'medium mv-8'}
                  sx={{ color: '#094451' }}
                  align={'center'}
                >
                  {description}
                </Typography>
              </div>

              <TextInput
                type={'number'}
                label={unitLabel}
                className={'mb-0'}
                validate={false}
                hideTick
                data-test={'unit_quantity'}
                placeholder={'Enter quantity'}
                value={dosage?.quantity || ''}
                error={(dosage?.quantity || 0) < 0}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setDosage({
                    ...dosage,
                    quantity: parseFloat(event.target.value as string),
                  });
                }}
              />
              <SelectInput
                label={'Frequency'}
                placeholder={'Please select'}
                value={dosage?.frequency || 'month'}
                className={'mb-0'}
                hideTick
                data-test={'frequency-select'}
                onChange={handleFrequencyChanged}
              >
                {frequencies.map((f) => (
                  <MenuItem className={'regular'} key={f} value={f}>
                    {_.upperFirst(_.lowerCase(f))}
                  </MenuItem>
                ))}
              </SelectInput>
              <SelectInput
                label={'Purchase frequency'}
                placeholder={'Please select'}
                data-test={'purchase-frequency-select'}
                className={'mb-0'}
                value={dosage?.purchaseFrequency || FrequencyPeriod.Monthly}
                hideTick
                onChange={(event) => {
                  setDosage({
                    ...dosage,
                    purchaseFrequency: event.target.value as FrequencyPeriod,
                  });
                }}
              >
                <MenuItem
                  value={FrequencyPeriod.Monthly}
                  className={'regular'}
                  disabled={
                    dosage?.frequency === CalcDrugFrequencyPeriod.Quarterly ||
                    dosage?.frequency === CalcDrugFrequencyPeriod.HalfYearly ||
                    dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
                  }
                >
                  Monthly
                </MenuItem>

                <MenuItem value={FrequencyPeriod.Quarterly}
                          disabled={
                            dosage?.frequency === CalcDrugFrequencyPeriod.HalfYearly || dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
                          }
                          className={'regular'}>
                  Quarterly
                </MenuItem>

                <MenuItem value={FrequencyPeriod.HalfYearly}
                          disabled={
                            dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
                          }
                          className={'regular'}>
                  Half Yearly
                </MenuItem>

                <MenuItem value={FrequencyPeriod.Yearly} className={'regular'}>
                  Yearly
                </MenuItem>

              </SelectInput>
            </Stack>
          </Collapse>

          <LoadingButton
            data-test={'add_drug_button'}
            loading={loading}
            color={'primary'}
            fullWidth
            size={'large'}
            variant={'contained'}
            sx={{mt: 4}}
            onClick={() => props.onClose(dosage)}
            disabled={!dosage.dosage || (dosage.quantity || 0) <= 0}
          >
            Add Drug
          </LoadingButton>
        </Box>
      </>
    </CRModal>
  );
}
