import React from 'react';
import { SignInLayout } from './sign-in-layout';
import { Box, ThemeProvider } from '@mui/material';
import { UpdatedTheme } from '@coverright/ui/themes';
import { Outlet } from 'react-router-dom';

export default function SignUpFlow(props: any) {
  return <ThemeProvider theme={UpdatedTheme}>
    <SignInLayout {...props}>
      <Box
        mt={'8px'}
        minHeight={'700px'}
        id={'wrapper'}
        position={'relative'}
      >
        <Outlet />
      </Box>
    </SignInLayout>
  </ThemeProvider>
}
