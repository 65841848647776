import { ProviderOutput } from '@coverright/data-access/types/medicare';
import * as React from 'react';
import { CRAutocomplete } from '@coverright/ui/inputs';
import { DoctorAutocompleteInput } from './doctor-autocomplete-input';
import { CircularProgress } from '@mui/material';
import { useDoctors } from '../api/use-doctors';
import { DoctorAutocompleteOption } from './doctor-autocomplete-option';
import { useDebouncedMemo } from '@coverright/utils';
import { FindProvidersByFilterQuery } from '@coverright/data-access/medicare';
import * as _ from 'lodash';

interface Props {
  zip: string;
  onSelect: (doctor: ProviderOutput) => void;
  filterOptionsFunc: (
    doctor: FindProvidersByFilterQuery['findProvidersByFilter'][0]
  ) => boolean;
}

export function DoctorAutocomplete({
  zip,
  onSelect,
  filterOptionsFunc,
}: Props) {
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const deferredValue = useDebouncedMemo(autocompleteValue, 500);
  const [open, setOpen] = React.useState(true);

  const { doctors, loading } = useDoctors(zip, deferredValue);

  const autocompleteOpen =
    !!autocompleteValue && !loading && !!doctors.length && open;

  const handleOptionClick = (value: ProviderOutput) => {
    onSelect(value);
    setAutocompleteValue('');
  };

  return (
    <CRAutocomplete
      fullWidth
      open={!!autocompleteOpen}
      onClose={() => {
        setTimeout(() => setOpen(false), 300); // todo: hack - fix closing autocomplete by blur
      }}
      onFocus={() => setOpen(true)}
      options={doctors.filter(filterOptionsFunc)}
      filterOptions={(x) => x}
      inputValue={autocompleteValue || ''}
      onInputChange={(event, newInputValue) => {
        setAutocompleteValue(newInputValue);
      }}
      getOptionLabel={(d: any) => d?.name || ''}
      popupIcon={loading ? <CircularProgress size={20} sx={{ mr: 1 }} /> : null}
      renderOption={(e, doctor: unknown) => (
        <DoctorAutocompleteOption
          onClick={() => handleOptionClick(doctor as ProviderOutput)}
          key={(doctor as ProviderOutput).npi}
          doctor={doctor as ProviderOutput}
        />
      )}
      renderInput={(params) => <DoctorAutocompleteInput params={params} />}
    />
  );
}
