import * as React from 'react';
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material';
import { formatPharmacyName } from '@coverright/utils';
import { PharmaciesModal } from './PharmaciesModal';
import * as _ from 'lodash';
import {
  DrugDeliveryTypeDto,
  PharmacyLocationDistanceOutput,
} from '@coverright/data-access/types/medicare';
import { MedigapDrugDeliveryType } from '@coverright/data-access/types/medigap';
import {
  CheckCircle,
  Envelope,
  Plus,
  Prescription,
  XCircle,
} from '@phosphor-icons/react';

/* eslint-disable-next-line */
export interface PharmaciesSelectionProps {
  zip?: string;
  value: PharmacyLocationDistanceOutput[];
  onChange: (data: PharmacyLocationDistanceOutput[]) => void;
  onOrderTypeChange: (data?: DrugDeliveryTypeDto) => void;
  orderType?: DrugDeliveryTypeDto | MedigapDrugDeliveryType;
}

export function PharmaciesSelection(props: PharmaciesSelectionProps) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const onPopupSubmit = (paharmacies: PharmacyLocationDistanceOutput[]) => {
    setModalOpen(false);
    props.onChange(paharmacies);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <PharmaciesModal
        zip={props.zip}
        values={props.value}
        open={modalOpen}
        onClose={onModalClose}
        onSubmit={onPopupSubmit}
      />
      <Box>
        <Typography
          sx={{ color: '#1C434F' }}
          variant={'body2'}
          className={'medium'}
          align={'center'}
        >
          Choose your preferred way of receiving your prescription drugs
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant={
              props.orderType === DrugDeliveryTypeDto.Pharmacy
                ? 'contained'
                : 'outlined'
            }
            size={'large'}
            data-test={'tobacco-true'}
            onClick={() =>
              props.onOrderTypeChange(DrugDeliveryTypeDto.Pharmacy)
            }
            startIcon={<Prescription />}
            endIcon={props.orderType === DrugDeliveryTypeDto.Pharmacy && <CheckCircle weight={'fill'} />}
            fullWidth
          >
            Retail Pharmacy
          </Button>
          <Button
            variant={
              props.orderType === DrugDeliveryTypeDto.Mail
                ? 'contained'
                : 'outlined'
            }
            size={'large'}
            data-test={'tobacco-false'}
            startIcon={<Envelope weight="fill" />}
            endIcon={props.orderType === DrugDeliveryTypeDto.Mail && <CheckCircle weight={'fill'} />}
            onClick={() => props.onOrderTypeChange(DrugDeliveryTypeDto.Mail)}
            fullWidth
          >
            Mail Order
          </Button>
        </Box>
      </Box>
      <Collapse in={props.orderType === DrugDeliveryTypeDto.Pharmacy}>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          {!!props.value?.length && (
            <Box mb={'16px'}>
              <Typography sx={{ fontWeight: 600 }} variant={'body2'}>
                Pharmacy Added
              </Typography>
            </Box>
          )}
          {props.value?.map((pharmacy) => (
            <Box
              data-test={'pharmacy-line'}
              key={pharmacy.npi}
              sx={{
                p: 2,
                backgroundColor: '#F5F5F5',
                display: 'flex',
                gap: 3,
                alignItems: 'center',
              }}
            >
              <Box flex={1}>
                <Typography variant={'body2'} className={'bold mb-8'}>
                  {formatPharmacyName(pharmacy.name || '')}
                </Typography>
                <Typography sx={{ color: '#333333' }}>
                  {pharmacy.address}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: '#1C434F',
                    display: { xs: 'block', sm: 'none' },
                  }}
                  className={'pointer fs-14 underline mt-8'}
                  onClick={() => setModalOpen(true)}
                >
                  Change Pharmacy
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: '#1C434F',
                  display: { xs: 'none', sm: 'block' },
                }}
                className={'pointer fs-14 underline'}
                onClick={() => setModalOpen(true)}
              >
                Change Pharmacy
              </Typography>
              <IconButton
                onClick={() => props.onChange(_.without(props.value, pharmacy))}
              >
                <XCircle size={24} color="#460C1D" weight="fill" />
              </IconButton>
            </Box>
          ))}
          {props.orderType === DrugDeliveryTypeDto.Pharmacy &&
            !props.value?.length && (
              <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={<Plus  />}
                size={'large'}
                onClick={() => setModalOpen(true)}
                fullWidth
              >
                Add Pharmacy
              </Button>
            )}
        </Box>
      </Collapse>
    </>
  );
}
