import { LazyQueryHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { QueryIsTherePlanWithFullDrugCoverageArgs } from '@coverright/data-access/types/medicare';

export function useIsTherePlanWithFullDrugCoverage(options?: LazyQueryHookOptions<{isTherePlanWithFullDrugCoverage: boolean}, QueryIsTherePlanWithFullDrugCoverageArgs>) {
  return useLazyQuery<{isTherePlanWithFullDrugCoverage: boolean}, QueryIsTherePlanWithFullDrugCoverageArgs>(
    gql(getPackageRequest),
    {
      ...options
    }
  );
}

const getPackageRequest = `
query ($zip: String!, $countyName: String!, $quoteId: UUID, $planYear: PlanYear) {
  isTherePlanWithFullDrugCoverage(zip: $zip, countyName: $countyName, quoteId: $quoteId, planYear: $planYear)
}
`;

