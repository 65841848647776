import React, { useContext, useState } from 'react';
import { createLoginUrl, getHash, initKeycloakAndAuthorize, keycloak as kcGlobal, KeycloakRoles } from './keycloak';
import {Dialog, DialogTitle} from '@mui/material';
import {getClientId, resetStoredUser} from '@coverright/utils';

interface UserToken {
  accessToken: string,
  idToken: string,
  refreshToken: string,
}

interface IKeycloakContext {
  authenticated: boolean,
  initialized: boolean,
  isAdmin: boolean,
  keycloak: Keycloak.KeycloakInstance,
  init: (data: UserToken) => Promise<any>,
  getHash: () => string,
  createLoginUrl: (redirectUri?: string) => string,
}

export const KeycloakContext = React.createContext<IKeycloakContext>({
  authenticated: false,
  initialized: false,
  isAdmin: false,
  keycloak: kcGlobal,
  init: () => new Promise<any>(resolve => null),
  getHash,
  createLoginUrl: () => '',
});

export function KeycloakProvider(props: React.PropsWithChildren<KeycloakProviderProps>) {
  const [initialized, setInitialized] = useState(props.keycloak ? !!props.keycloak.authServerUrl : !!kcGlobal.authServerUrl);
  const [keycloak, setKeycloak] = useState(props.keycloak || kcGlobal);
  const [authenticated, setAuthenticated] = React.useState<boolean>(props.keycloak ? !!props.keycloak.authenticated : !!kcGlobal.authenticated);


  const init = async (data: UserToken) => {
    const newInstance = await initKeycloakAndAuthorize({
      idToken: data.idToken,
      token: data.accessToken,
      refreshToken: data.refreshToken,
    });
    setKeycloak(newInstance);
    setInitialized(true);
    setAuthenticated(Boolean(newInstance.authenticated));
  }

  const isAdmin = initialized && authenticated && keycloak.hasRealmRole(KeycloakRoles.ROLE_ADMIN);

  if (isAdmin && !props.allowAdmin) {
    return <Dialog disableEscapeKeyDown open={true} PaperProps={{sx: {backgroundColor: 'rgb(192, 0, 0)'}}}>
      <DialogTitle sx={{color: 'white'}} className={'bold fs-18'}>You are an admin. Please <span
        className={'underline pointer'} onClick={() => {
        resetStoredUser();
        kcGlobal.logout()
      }}>log out</span>.</DialogTitle>
    </Dialog>
  }

  return <KeycloakContext.Provider
    value={{
      initialized,
      authenticated,
      isAdmin,
      init,
      keycloak,
      getHash,
      createLoginUrl}}>
    {(props.lateInit || initialized) && props.children}
  </KeycloakContext.Provider>
}

export const withKeycloakContext = (WrappedComponent: any, allowAdmin = !!getClientId()) => (props: any) => {
  return (
    <KeycloakProvider allowAdmin={allowAdmin}>
      <WrappedComponent {...props} />
    </KeycloakProvider>
  )
}

export const useIsAdmin = () => {
  const {isAdmin} = useContext(KeycloakContext)
  return isAdmin;
}

type KeycloakProviderProps = {
  keycloak?: any,
  onLoad?: string,
  allowAdmin?: boolean,
  lateInit?: boolean
}
