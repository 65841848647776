import { SxProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react/dist/ssr/CheckCircle';
import React from 'react';

export const TickAdornment = ({sx}: {sx?: SxProps}) => (
  <InputAdornment sx={sx}
                  disablePointerEvents
                  position="end">
    <CheckCircle size={24} color="#0DBC83" weight="fill" />
  </InputAdornment>
);
