import React from 'react';
import {PlanParam} from '../plan-param/PlanParam';
import { Collapse, Typography } from '@mui/material';
import { Benefit, SeeMore } from '../..';

export function DrugCoverageList(props: DrugCoverageList) {
  const {coverage, hideTitle, labelClassName, titleClassName, length = 3, title} = props;
  const [expanded, setExpanded] = React.useState(false);
  if(coverage.length === 0) {
    return <Typography className={'fs-12 card-title'} color={'textPrimary'}>You haven’t added any drugs</Typography>;
  }

  return <div>
    {!hideTitle && <Typography className={`fs-11 lh-13 mv-12 ${titleClassName}`}>{title || 'Drug name:'}</Typography>}
    {coverage.slice(0, length).map((drug, index) => (
      <PlanParam key={drug.rxcui + index}
                 value={<Benefit value={drug.coverage}
                                 labelClass={labelClassName || 'mb-8'}
                                 label={drug.name || 'Drug is missing'}/>}/>
    ))}
    <Collapse in={expanded}>
      {coverage.slice(length).map((drug, index) => (
        <PlanParam key={drug.rxcui + index}
                   value={<Benefit value={drug.coverage}
                                   labelClass={labelClassName || 'mb-8'}
                                   label={drug.name || 'Drug is missing'}/>}/>
      ))}
    </Collapse>
    {coverage.length > length && <SeeMore sx={{mt: 1}} expanded={expanded} onToggle={value => setExpanded(!value)} />}
  </div>
}

interface DrugCoverageList {
  coverage: any[],
  hideTitle?: boolean,
  titleClassName?: string,
  labelClassName?: string,
  length?: number,
  title?: string
}
