import React from 'react';
import { useParams } from 'react-router';
import {
  PlanFilterContext,
  withPlanFilterProvider,
} from './components/filters/PlanFilterContext';
import PlanList from './plan-list';
import PlanListSideContent from './components/PlanListSideContent';
import ComparisonPanel from '../plan-comparison/components/ComparisonPanel';
import MAMarketplaceLayout from '../../components/MAMarketplaceLayout';
import * as QueryString from 'query-string';
import paths from '../../../config/router-paths';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { UpdateZip } from '@coverright/widgets';

const DashboardLayout = () => {
  const { initialized, keycloak, getHash } = React.useContext(KeycloakContext);
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const { values: filterValues, switchZip } =
    React.useContext(PlanFilterContext);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (initialized) {
      const params = QueryString.parse(document.location.search);
      if (params.quote && !keycloak?.authenticated) {
        //  keycloak?.login();
      }
    }
  }, [initialized, keycloak?.authenticated]);

  const onUpdateZipModalClose = React.useCallback(
    (newZip?: string, newCounty?: string) => {
      if (newZip && newCounty && (newCounty !== countyName || newZip !== zip)) {
        switchZip(newZip, newCounty).then(() => {
          document.location.href = paths.plans + newZip + '/' + newCounty;
        });
      }
    },
    [zip, countyName, filterValues]
  );

  return (
    <>
      <MAMarketplaceLayout
        title={
          <>
            We’ve found <b>{total} Medicare Advantage Plans</b> for you
          </>
        }
        backButtonComponent={<></>}
        extra={
          <UpdateZip
            zip={zip}
            county={countyName}
            onChange={onUpdateZipModalClose}
          />
        }
        side={<PlanListSideContent />}
        main={<PlanList onPlansCountChange={setTotal} />}
      />
      {zip && <ComparisonPanel countyName={countyName!} zip={zip!} />}
    </>
  );
};

export default withPlanFilterProvider(DashboardLayout);
