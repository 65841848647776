import { DrugOutput } from '@coverright/data-access/types/medicare';
import { DrugCard } from './drug-card';
import { Stack } from '@mui/material';
import * as React from 'react';

type Drug = DrugOutput & { notSupported: boolean };

interface Props {
  onEditDrugClick: (item: Drug) => void;
  onDeleteDrugClick: (item: Drug) => void;
  items: Drug[];
}

export function DrugList({ onDeleteDrugClick, onEditDrugClick, items }: Props) {
  return (
    <Stack spacing={2}>
      {items.map((item) => (
        <DrugCard
          key={item.packRxcui}
          drug={item}
          onEditDrugClick={() => onEditDrugClick(item)}
          onDeleteClick={() => onDeleteDrugClick(item)}
        />
      ))}
    </Stack>
  );
}
