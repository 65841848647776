import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import V3Breakdown from './v3';
import LegacyBreakdown from './legacy';
import Z1Breakdown from './z1';
import { DrugCostBreakdownVariant } from '../../../lib/types';

type Props = {
  plan: IDrugCostBreakdownPlan;
  loading: boolean;
  onFullYearChange: (value: boolean) => void;
  fullYear: boolean;
  regulatory?: RegulatoryVariable;
  variant?: DrugCostBreakdownVariant;
};

export default function Breakdown(props: Props) {
  if (props.variant === 'z1') {
    return <Z1Breakdown {...props} />
  }
  return props.variant === 'v3' ? <V3Breakdown {...props} /> : <LegacyBreakdown {...props} />;
}
