import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { CRModal } from '@coverright/ui/shared';
import { DrugInfoWithPackagesFlags } from '@coverright/data-access/types/medicare';
import { Pill, X } from '@phosphor-icons/react';
import { SelectInput } from '@coverright/ui/inputs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 500,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        color: colors.custom.green.variant2 + '!important',
        padding: '15px 20px 14px!important',
        fontSize: 22,
      },
    },
  })
);

interface NoPackagesModalProps {
  open: boolean;
  info?: DrugInfoWithPackagesFlags | null;
  onClose: (switchTo?: { name: string; rxcui: string }) => void;
}

export default function NoPackagesModal(props: NoPackagesModalProps) {
  const classes = useStyles();

  const [brandedRxcui, setBrandedRxcui] = React.useState<string>();

  React.useEffect(() => {
    setBrandedRxcui(
      (props?.info?.brandedDetails[0]?.rxcui as string) || undefined
    );
  }, [props.open]);

  if (!props.info) {
    return null;
  }

  const hasPackages =
    props.info.hasBrandedPackages || props.info.hasGenericPackages;

  const buttonText = hasPackages
    ? `Add ${props.info.isGeneric ? 'branded' : 'generic'} drug`
    : `Back to drug search`;

  const submit = () => {
    if (props.info?.isGeneric) {
      if (props.info.brandedDetails.length === 1) {
        props.onClose({
          rxcui: props.info?.brandedDetails[0].rxcui as string,
          name: props.info?.brandedDetails[0].name,
        });
      } else {
        const drug = props.info?.brandedDetails.find(
          (d) => d.rxcui === brandedRxcui
        );
        if (drug) {
          props.onClose({ rxcui: drug.rxcui, name: drug.name });
        } else {
          props.onClose();
        }
      }
    } else {
      props.onClose({
        rxcui: props.info?.genericRxcui as string,
        name: props.info?.genericName as string,
      });
    }
  };

  return (
    <CRModal
      width={500}
      open={props.open}
      onClose={() => props.onClose()}
      paperPadding={'24px'}
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '-8px',
            mr: '-8px',
          }}
        >
          <IconButton onClick={() => props.onClose()}>
            <X size={24} color="#000000" weight="regular" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Pill size={32} color="#1C434F" weight="fill" />
          <Typography variant={'h4'} className={'mt-16 mb-8'} align={'center'}>
            {props.info.name}
          </Typography>
          <Typography
            className={'mb-8'}
            color={'textSecondary'}
            align={'center'}
          >
            isn’t currently available
          </Typography>
        </Box>
        <div className={'h-16'} />
        {hasPackages && (
          <>
            {!props.info.isGeneric && (
              <>
                <Typography className={'mb-8'} align={'center'}>
                  It has a lower cost generic version called:
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{ color: '#094451' }}
                  className={'medium mb-24'}
                  align={'center'}
                >
                  {props.info.genericName}
                </Typography>
              </>
            )}
            {!!props.info.isGeneric && props.info.brandedDetails.length === 1 && (
              <>
                <Typography className={'mb-8'} align={'center'}>
                  It has a lower cost branded version called:
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{ color: '#094451' }}
                  className={'medium mb-24'}
                  align={'center'}
                >
                  {props.info.brandedDetails[0].name}
                </Typography>
              </>
            )}
            {!!props.info.isGeneric && props.info.brandedDetails.length > 1 && (
              <>
                <SelectInput
                  fullWidth
                  hideTick
                  label={
                    'You can instead select from the following branded drugs:'
                  }
                  value={brandedRxcui}
                  placeholder={'Select drug'}
                  className={classes.select}
                  onChange={(event) => {
                    setBrandedRxcui(event.target.value as string);
                  }}
                >
                  {props.info.brandedDetails.map((drug) => (
                    <MenuItem
                      key={drug.rxcui}
                      value={drug.rxcui}
                      className={'regular'}
                    >
                      {drug.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </>
            )}
            <div className={'h-16'} />
            <Typography variant={'h4'} className={'lh-24'} align={'center'}>
              Would you like to add the{' '}
              {props.info.isGeneric ? 'branded' : 'generic'} version to your
              list instead?
            </Typography>
          </>
        )}
        {!hasPackages && (
          <Typography variant={'h4'} align={'center'}>
            Speak to one our advisors if this
            <br />
            drug is important to you.
          </Typography>
        )}
        <Box
          sx={{ gap: 2 }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          mt={'16px'}
        >
          {hasPackages && (
            <Button
              variant={'contained'}
              fullWidth
              onClick={() => {
                if (!hasPackages) {
                  props.onClose();
                } else {
                  submit();
                }
              }}
            >
              {buttonText}
            </Button>
          )}
          <Button
            data-test={'add_branded_drug_button'}
            fullWidth
            variant={'outlined'}
            color={'primary'}
            className={'branded-drug-button'}
            onClick={() => props.onClose()}
          >
            Close and go back to drug search
          </Button>
        </Box>
      </>
    </CRModal>
  );
}
