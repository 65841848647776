import * as React from "react";
import { Box } from '@mui/material';
import { SxProps } from '@mui/material';

interface CompanyLogoProps {
  sx?: SxProps,
  url: string,
}

export function CompanyLogo({ url, sx }: CompanyLogoProps) {
  return <Box sx={{
    backgroundPosition: { xs: 'left', md: 'right' },
    height: { xs: 50, md: 33 },
    width: { xs: '50%', md: 119 },
    minWidth: 119,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url("${url}")`,
    ...sx
  }}  />
}
