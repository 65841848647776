import React from 'react';
import {
  Box,
  Button,
  Card,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import { colors } from '@coverright/ui/themes';
import * as Scroll from "react-scroll";
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { PlanWithTiers } from '@coverright/data-access/medicare';
import { PlanYear } from '@coverright/data-access/types/medicare';
import { MarketplaceLayoutContext } from '../../../components/MarketplaceLayout';
import _ from 'lodash';

const scrollSpy = Scroll.scrollSpy;
const Link = Scroll.Link;

export default function PlanDetailsContent({plan}: {plan?: PlanWithTiers, zip: string, countyName: string}) {
  const [collapsed, setCollapsed] = React.useState(false);
  const {setDrawerOpen} = React.useContext(MarketplaceLayoutContext);
  const [showMenu, setShowMenu] = React.useState(false);

  useScrollPosition(({currPos}) => {
    if( currPos.y < -250 && !collapsed) {
      setCollapsed(true);
    }
    if( currPos.y >= -150 && collapsed) {
      setCollapsed(false);
    }
  }, [collapsed])


  React.useEffect(() => {
    scrollSpy.update();
  }, []);

  const titles = React.useMemo(() => {
    const result = [
      "Key Plan Benefits Overview",
      "Drug Coverage & Costs",
      "Medical & Hospital Coverage",
      "Extra Benefits",
      "Plan Brochures"
    ];
    if (plan && (!plan.extraBenefits.drugCoverage || !plan.drugDetails)) {
      result.splice(1, 1)
    }

    return result;
  }, [plan]);

  const onBackClick = React.useCallback(() => {
    if (showMenu) {
      setShowMenu(false)
      setDrawerOpen(false)
    } else {
      setShowMenu(true)
    }
  }, [showMenu])


  return <Box sx={{position: 'sticky', top: {xs: 0, lg: 41}}}>
    <Box sx={{display: {xs: 'flex', lg: 'none'}, alignItems: 'center', pt: 2, mx: '-4px', px: '4px', justifyContent: 'space-between', position: 'sticky', top: 0, zIndex: 2, background: '#F5F7F6'}}>
      <Button
        sx={{color: colors.text.primary}}
        startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
        onClick={onBackClick}>
        Back to {showMenu ? 'plan details' : 'menu'}
      </Button>
      <IconButton onClick={() => {
        setShowMenu(false)
        setDrawerOpen(false)
      }}>
        <img src={'/assets/img/icon-close.svg'} className={'w-16 h-16 op-5'}/>
      </IconButton>
    </Box>
    <Card sx={{p: 3}}>
      <Typography className={'bold fs-16 lh-20'}>Table Of Contents</Typography>
      <Divider sx={{my: 2}} />
      {plan && titles.map((title, idx) => <PlanDetailTitle key={idx} title={title} index={idx + 1} />)}
    </Card>
  </Box>
}

const PlanDetailTitle = ({title, index}: { title: string, index: number}) => {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>
      <Box sx={{
        fontSize: '16px',
        fontWeight: 700,
        p: '12px',
        cursor: 'pointer',
        color: colors.text.secondary,
        whiteSpace: 'nowrap'
      }}>
        {index}. {title}
      </Box>
    </Link>
  </>)
}
