export const environmentStage = {
  production: false,

  medigapUrl: 'https://medigap2.staging.ascendinsure.com/',
  medicareAdvantageUrl: 'https://plans2.staging.ascendinsure.com/',
  dashboardUrl: 'https://quote2.staging.ascendinsure.com/',
  learningUrl: 'https://learning.staging.ascendinsure.com/',
  advisorUrl: 'https://advisors.staging.ascendinsure.com/',
  pdpUrl: 'https://pdp.staging.ascendinsure.com/',
  flexpaUrl: 'http://flexpa.staging.ascendinsure.com/',
  offersUrl: 'https://cost-plus.staging.ascendinsure.com/',
  profileUrl: 'https://signup.staging.ascendinsure.com/',
  planFinderUrl: 'https://v3.staging.ascendinsure.com/plan-finder/',
  planFinderZ1Url: 'https://v3.staging.ascendinsure.com/plan-finder-z1/',

  filesUrl: 'https://plan-files.ascendmedicare.com',

  clientFilesApiUrl: 'https://api.staging.ascendinsure.com',
  userFlowRestUrl: 'https://api.staging.ascendinsure.com/user-flow/api',

  cdnUrl: 'https://cdn.staging.ascendinsure.com/',

  strapiUrl: 'https://content-api.coverright.com',

  flexpaApiPublishableKey: 'pk_test_fUUKcP4fVWf1JITZ9-LGAX_kfXhkcbe6kbrtGmGo4TU'
};
