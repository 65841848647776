import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Popover, SwipeableDrawer,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material';
import { SxProps } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { ContactsSection } from '@coverright/legacy';
import { colors } from '@coverright/ui/themes';
import { ProfileSource } from '@coverright/data-access/types/enrollment';

interface MarketplaceLayoutProps {
  MenuComponent?: FunctionComponent,
  LoginComponent: JSX.Element,
  title: string | JSX.Element,
  titleSx?: SxProps,
  wrapperSx?: SxProps,
  headerSx?: SxProps,
  side: JSX.Element | null,
  main: JSX.Element,
  extra?: JSX.Element,
  backButtonTitle: string,
  backButtonComponent?: JSX.Element,
  onBackButtonClick: () => void,
  maxWidth?: any,
  source: ProfileSource,
  showSkipLogin?: boolean,
  align?: 'flex-start' | 'center',
}

interface ILayoutContext {
  setDrawerOpen: (value: boolean) => void,
}

export const MarketplaceLayoutContext = React.createContext<ILayoutContext>({setDrawerOpen: () => {}});

export const MarketplaceLayout = ({MenuComponent, ...props}: MarketplaceLayoutProps) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const Component: any = MenuComponent;
  return <MarketplaceLayoutContext.Provider value={{setDrawerOpen}}>
    <Box sx={{width: '100%', ...props.wrapperSx}}>
      <Hidden lgDown>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, ...props.headerSx}}>
          <Box sx={{display: 'flex', gap: '28px'}}>
            {!!MenuComponent && <>
              <Button
                sx={{ml: 0}}
                startIcon={<img src={'/assets/img/menu.png'} />}
                variant="text"
                color="primary"
                onClick={event => {
                  setAnchorEl(event.currentTarget);
                  if ((window as any).track) {
                    (window as any).track('Nav: Click main burger menu icon');
                  }
                }}
              >
                MENU
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                BackdropProps={{
                  sx: {
                    backgroundColor: 'transparent'
                  }
                }}
                PaperProps={{
                  sx: {
                    padding: '20px 24px'
                  }
                }}
                sx={{ml: '-15px', top: '20px', maxWidth: 1230}}
                elevation={4}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Component {...props} />
              </Popover>
            </>}
            <img src={'/assets/img/Logo.svg'} width={122} />
            {props.LoginComponent}
          </Box>
          <ContactsSection />
        </Box>
      </Hidden>
      <Hidden lgUp>
        <ElevationScroll>
          <AppBar sx={{
            zIndex: 1100,
            backgroundColor: '#F5F7F6'
          }}>
            <Toolbar style={{minHeight: '48px', padding: '3px 20px', background: '#F5F7F6'}}>
              <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
                <SwipeableDrawer
                  variant="temporary"
                  anchor={'left'}
                  open={drawerOpen}
                  onOpen={() => setDrawerOpen(true)}
                  onClose={() => setDrawerOpen(false)}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{'& .MuiDrawer-paper': {p: '0 16px 16px', background: '#F5F7F6'}}}
                >
                  {props.side}
                </SwipeableDrawer>
                <Box sx={{display: 'flex', gap: 1}} justifyContent={'space-between'} alignItems={'center'}>
                  <Button
                    sx={{ml: 0}}
                    startIcon={<img src={'/assets/img/menu.png'} />}
                    variant="text"
                    color="primary"
                    onClick={event => {
                      setDrawerOpen(true)
                      if ((window as any).track) {
                        (window as any).track('Nav: Click main burger menu icon');
                      }
                    }}
                  >
                    MENU
                  </Button>
                  <img src={'/assets/img/Logo.svg'} width={108} />
                  {props.LoginComponent}
                </Box>
                <Box sx={{display: 'flex', gap: 1}} justifyContent={'space-between'} alignItems={'center'}>
                  <IconButton
                    sx={{background: 'white', width: '49px', height: '49px'}}
                    component={'a'}
                    href={'tel:8889697667'}
                  >
                    <img src={'/assets/img/phone.svg'} />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </Hidden>
      <Box px={2} sx={{pt: {xs: '70px', lg: 'inherit'}}} maxWidth={props.maxWidth || {xs: 800, lg: 1136}} boxSizing={'border-box'} width={'100%'} margin={'auto'}>
        {!props.backButtonComponent && <Button
          sx={{color: colors.text.primary}}
          startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
          onClick={props.onBackButtonClick}>
          {props.backButtonTitle}
        </Button>}
        {!!props.backButtonComponent && <>{props.backButtonComponent}</>}
        {props.title && <Typography sx={{fontSize: {xs: 20, sm: 32}, lineHeight: {xs: '25px', sm: '40px'}, ...props.titleSx}}>{props.title}</Typography>}
        {props.extra}
        <Box display={'flex'} gap={props.side ? '32px' : undefined} justifyContent={props.align ? props.align : 'center'}>
          <Hidden lgDown implementation="css">
            {props.side}
          </Hidden>
          {props.main}
        </Box>
      </Box>
    </Box>
  </MarketplaceLayoutContext.Provider>
}

const ElevationScroll = ({children}: any)  => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
  });
}
