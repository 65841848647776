import * as React from 'react';
import { CRModal } from '@coverright/ui/shared';
import { Props } from '../lib/types';
import { Box, Typography } from '@mui/material';
import { ModalCloseButton } from '@coverright/shared/ui';
import { MADrugCostBreakdown } from './ma-drug-cost-breakdown';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect } from 'react';
import { logEvent } from '@coverright/shared/analytics';

interface ModalProps extends Props {
  quoteId?: string;
  open: boolean;
  onClose: () => void;
}

export function MaDrugCostBreakdownModal(props: ModalProps) {
  const { open, onClose: parentOnClose, ...rest } = props;

  useEffect(() => {
    if (open) {
      logEvent('ma_drug_cost_breakdown_modal_open')
    }
  }, [open]);

  const onClose = () => {
    logEvent('ma_drug_cost_breakdown_modal_close')
    parentOnClose();
  }

  return (
    <CRModal
      paperSx={{
        width: { xs: '100%', lg: '75%' },
        minWidth: { md: 800 },
        maxWidth: 1100,
      }}
      open={open}
      onClose={onClose}
      paperPadding={{ xs: '16px 0 16px 16px', md: '32px 16px 32px 32px' }}
      disableSidePadding
    >
      <OverlayScrollbarsComponent
        options={{ paddingAbsolute: true, scrollbars: {autoHide: 'move' } }}
        style={{paddingRight: 16}}
        defer
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
          <Typography variant="h3" sx={{ flexGrow: 1 }}>Estimated Drug Cost Summary</Typography>
          <ModalCloseButton
            onClick={onClose}
          />
        </Box>
        <MADrugCostBreakdown {...rest} />
      </OverlayScrollbarsComponent>
    </CRModal>
  );
}
