import React from 'react';
import {
  PlansCountByZipQuery,
  PlansCountByZipQueryVariables,
  usePlansCountByZipLazyQuery
} from '@coverright/data-access/types/medicare';
import { LazyQueryHookOptions } from '@apollo/client';

export function useMaPlansCount(zip?: string, options?: LazyQueryHookOptions<PlansCountByZipQuery, PlansCountByZipQueryVariables>) {
  const [getPlansCount, plansCountData] = usePlansCountByZipLazyQuery(options)

  React.useEffect(() => {
    if (zip) {
      getPlansCount({variables: {zip}})
    }
  }, [zip])


  const maPlansPercent = React.useMemo(() => {
    if (plansCountData.data) {
      return Math.floor(plansCountData.data!.plansCountByZip.maPlansCount * 100 / plansCountData.data!.plansCountByZip.maPlansTotalCount)
    }
    return null;
  }, [plansCountData])

  const maPlansCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.maPlansCount;
    }
    return null;
  }, [plansCountData])

  const maOrgCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.maPlansParentOrgCount;
    }
    return null;
  }, [plansCountData])

  const pdpPlansCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.pdpPlansCount;
    }
    return null;
  }, [plansCountData])

  const pdpOrgCount = React.useMemo(() => {
    if (plansCountData.data) {
      return plansCountData.data!.plansCountByZip.pdpPlansParentOrgCount;
    }
    return null;
  }, [plansCountData])

  return { maPlansPercent, maPlansCount, maOrgCount, pdpPlansCount, pdpOrgCount };
}
