import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { MedigapPlanOutput, QueryMedigapPlanArgs } from '@coverright/data-access/types/medigap';
import * as React from 'react';
import { data } from 'autoprefixer';

export function useMedigapPlan(options?: LazyQueryHookOptions<IPlanOutput, QueryMedigapPlanArgs>) {
  return useLazyQuery<IPlanOutput, QueryMedigapPlanArgs>(gql(getPlan), {
    ...options,
  });
}

interface IPlanOutput{
  medigapPlan: MedigapPlanOutput
}



export function useMedigapPlansByBid( ids?: string[], onCompleted?: (data: any) => void) {
  const {loading, called, data, refetch} = useQuery<{[name: string]: MedigapPlanOutput}>(gql(getPlansQuery(ids)), {
    onCompleted,
    skip: !ids?.length
  });

  return {plans: data ? Object.values(data).filter(p => !!p) : [], loading, called, refetch};
}

const getPlansQuery = (plans?: string[]) => {
  let query;
  if (plans?.length) {
    query = Object.keys(plans).map(parseFloat).map((i: number) => `plan${i}: medigapPlan(key: "${plans[i]}") {
      ${planFields}
    }`)
  } else {
    query = `getMyQuoteId`
  }
  return `{${query}}`;
};

const planFields = `
    amBestRating
    householdDiscount
    key
    monthlyPremium
    orgName
    parentOrgName
    title
    subTitle
    naic
    spRating
    ratingClass
    planName
    effectiveDate
    showAdditionalBenefitsDescription
`

const getPlan = `
query($key: String!) {
  medigapPlan(key: $key) {
    ${planFields}
  }
}
`;

