import { withPlanFilterProvider } from '../pages/plan-list/components/filters/PlanFilterContext';
import React from 'react';
import { MarketplaceLayout } from './MarketplaceLayout';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import ThankYouModal from './ThankYouModal'
import { Box, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router';
import { Terms } from '@coverright/ui/shared';


export default withPlanFilterProvider((props: any) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return <>
    <ThankYouModal open={open} onClose={() => {
      setOpen(false)
    }}/>
    <MarketplaceLayout
      LoginComponent={<Button
        sx={{ fontWeight: 500, fontSize: 16, color: '#1D9C80' }}
        startIcon={
          <PersonIcon sx={{ width: '20px', height: '20px' }} />
        }
        size={'small'}
        onClick={() => {
          navigate('/sign-in')
        }}
      >
        Sign in
      </Button>}
      source={ProfileSource.MaMarketplace}
      {...props} />
    <Box className={'terms'} sx={{left: 30, bottom: 30, ml: 4, pb: 4}}>
      <Terms />
    </Box>

  </>
});
