import {  useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import {gql} from "@apollo/client";
import { QueryCompareOfferArgs, CompareOffer, Maybe } from '@coverright/data-access/types/medicare';

export function useCompareOffer(options?: LazyQueryHookOptions<{compareOffer: Maybe<CompareOffer>}, QueryCompareOfferArgs>) {
  return useLazyQuery<{compareOffer: Maybe<CompareOffer>}, QueryCompareOfferArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options,
  })
}


const query = `
query($clientId: UUID) {
  compareOffer(clientId: $clientId) {
    clientId
    id
    plans {
      bidId
      county
      type
      zip
      pdpPrimary
      effectiveDate
      planYear
    }
  }
}`;
