import {Cookies} from "react-cookie";
const cookie = new Cookies();

export function subIdHandler() {
  if (!(window as any).track) {
    return;
  }
  const triggers = [
    'subid1',
    'pushnami_id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
  ];

  const paramNames = [
    ...triggers,
    'zip',
    'first_name',
    'last_name',
    'dob',
    'phone',
    'email',
  ];

  const urlParams = new URLSearchParams(window.location.search);

  const isSent = cookie.get('partner-sent');
  const cid = (window as any).gaGlobal ? (window as any).gaGlobal.vid : '';

  if (!isSent && cid && Array.from(urlParams.keys()).some(key => triggers.includes(key))) {
    const values: any = {};
    const entries = urlParams.entries();
    for(const entry of entries) {
      if (paramNames.includes(entry[0])) {
        values[entry[0]] = entry[1];
      }
    }
    const xhr = new XMLHttpRequest();
    const url = "https://api.coverrightmedicare.com/api/partner-params";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        cookie.set('partner-sent', 'true', {maxAge: 3600 * 24 * 30})
      }
    };
    xhr.send(JSON.stringify({cid, values}));
  }
}
