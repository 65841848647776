import {
  DrugDiscount,
  DrugDiscountBrand,
  DrugPriceOutput,
} from '@coverright/data-access/types/medicare';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { ArrowRight, Confetti } from '@phosphor-icons/react';
import { Benefit } from '@coverright/ui/marketplaces';
import { toCurrency } from '@coverright/utils';
import { SxProps } from '@mui/material';
import * as _ from 'lodash';
import { ResponsiveTable, ResponsiveTableColumn } from '@coverright/features';

type DrugOffersProps = {
  discountedDrugs?: DrugDiscount[] | null;
  drugPrices: DrugPriceOutput[];
  tableSx?: SxProps,
};

export function DrugOffers(props: DrugOffersProps) {
  const isCostPlusExists = React.useMemo(() => {
    return props.discountedDrugs?.some(
      (p) => p.discountBrand === DrugDiscountBrand.CostPlus
    );
  }, [props.discountedDrugs]);

  const getDrugOffer = useMemo(() => {
    return getDrugOfferGetter(props.discountedDrugs);
  }, [props.discountedDrugs]);

  const columns = useMemo(() => {
    return getColumns(getDrugOffer);
  }, [getDrugOffer]);

  return (
    <div>
      <ResponsiveTable
        columns={columns}
        rows={props.drugPrices}
        sx={props.tableSx}
      />

      {isCostPlusExists && (
        <Typography sx={{ color: '#666' }} className={'fs-14 lh-24 mt-10 ml-4'}>
          * There is a $5 shipping fee for orders from Cost Plus Drugs
          regardless of how many prescriptions you order. Also, you may be
          charged a pharmacy fee. The discounted price is an accurate estimate
          of your drugs' cost.
        </Typography>
      )}
    </div>
  );
}

const getColumns = (
  getDrugOffer: (rxqui: string) => DrugDiscount | undefined
): ResponsiveTableColumn<DrugPriceOutput>[] => [
  {
    title: 'Drug',
    value: (v) => v.packageDescription,
  },
  {
    title: 'Detail',
    value: (v) => (
      <>
        <Typography>{v.isGeneric ? 'Generic' : 'Branded'}</Typography>
        <Typography>{v.quoteQuantity}</Typography>
      </>
    ),
  },
  {
    title: 'Covered?',
    value: (v) => (
      <Benefit
        label={v.isCoveredByPlan ? 'Yes' : 'No'}
        overrideCheckIconColor={'#0B741C'}
        overrideCrossIconColor={'#D32C1C'}
        imageSize={24}
        sx={{
          '& p.check': {
            color: '#0B741C',
          },
          '& p.cross': {
            color: '#D32C1C',
          },
        }}
        labelClass={'fs-14 lh-24 semi-bold'}
        value={v.isCoveredByPlan}
      />
    ),
  },
  {
    title: 'Yearly Plan',
    value: (v) => toCurrency(getDrugOffer(v.rxcui)?.carrierCost, 2, 2),
  },
  {
    title: 'Yearly Discount',
    value: (v) => (
      <>
        {getDrugOffer(v.rxcui)?.discount > 0 && (
          <Typography>{getDrugDiscount(getDrugOffer(v.rxcui))}</Typography>
        )}
        {getDrugOffer(v.rxcui)?.discount <= 0 && (
          <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography>
        )}
      </>
    ),
  },
  {
    title: 'CostPlus',
    value: (v) => (
      <>
        {getDrugOffer(v.rxcui)?.discount <= 0 && (
          <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography>
        )}
        {getDrugOffer(v.rxcui)?.discount > 0 && (
          <Button
            variant={'text'}
            id={'view-offer-button'}
            onClick={() => window.open(getDrugUrl(getDrugOffer(v.rxcui)))}
            size={'small'}
            endIcon={
              <ArrowRight
                className={'ml-4'}
                size={16}
                color="#1C434F"
                weight="bold"
              />
            }
          >
            View offer
          </Button>
        )}
      </>
    ),
  },
];

function getDrugUrl(discount?: DrugDiscount) {
  if (discount?.discountBrand === DrugDiscountBrand.GoodRx) {
    return discount?.goodRxDetails?.url;
  } else if (discount?.discountBrand === DrugDiscountBrand.CostPlus) {
    return (
      discount?.costPlusDetails?.url +
      '?utm_source=CoverRight&utm_medium=CoverRight'
    );
  } else {
    return '';
  }
}

const getDrugOfferGetter = (discountedDrugs?: DrugDiscount[] | null) =>
  _.memoize((rxqui: string): DrugDiscount | undefined =>
    discountedDrugs?.find((d) => d.rxcui === rxqui)
  );

function getDrugDiscount(discount?: DrugDiscount) {
  if (discount?.discountBrand === DrugDiscountBrand.GoodRx) {
    return toCurrency(discount.goodRxCost, 2, 2);
  } else if (discount?.discountBrand === DrugDiscountBrand.CostPlus) {
    return toCurrency(discount?.costPlusCost, 2, 2) + '*';
  } else {
    return '';
  }
}

