import {
  DrugCostOutput,
  MonthlyTotalCost,
} from '@coverright/data-access/types/medicare';
import {
  Box,
  Collapse,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import * as _ from 'lodash';
import { CaretDown } from '@phosphor-icons/react';
import moment from 'moment/moment';
import { ResponsiveTableColumn } from '@coverright/features';

export const DrugSummary = ({
  costs,
  total,
  monthlyCosts,
}: {
  costs: DrugCostOutput[];
  monthlyCosts?: MonthlyTotalCost[];
  total?: string;
}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedState, setExpandedState] = React.useState<{
    [key: string]: boolean;
  }>({});

  const getExpandedValue = (key: string): boolean =>
    typeof expandedState[key] === 'undefined' ? false : expandedState[key];
  const toggleExpanded = (key: string) => {
    const newValue =
      expandedState[key] === undefined ? true : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue,
    });
  };

  const groupedCosts = React.useMemo(() => {
    return _.groupBy(costs, 'month');
  }, []);

  return (
    <div>
      <Box
        component={'table'}
        cellSpacing={0}
        sx={drugSummaryTableStyles}
      >
        <thead>
          <tr>
            <td colSpan={2}>Month</td>
            {!phone && <td>Quantity</td>}
            {!phone && <td>Cost</td>}
            {!phone && <td>Coverage phase</td>}
          </tr>
        </thead>
      </Box>

      {Object.keys(groupedCosts).map((month, i) => (
        <React.Fragment key={month}>
          <Box
            component={'table'}
            cellSpacing={0}
            sx={{
              ...drugSummaryTableStyles,
              '&:hover td': { backgroundColor: 'rgba(240,247,250,0.32)' },
            }}
          >
            <tbody>
              <tr
                className={'medium pointer'}
                onClick={() => toggleExpanded(month)}
              >
                <td colSpan={2}>
                  <CaretDown
                    size={12}
                    color="#1C434F"
                    weight="bold"
                    className={'mr-15'}
                  />
                  {moment(month.toString(), 'M').format('MMMM')}
                </td>
                <td></td>
                <td>
                  {
                    monthlyCosts?.find((mc) => mc.month.toString() == month)
                      ?.amountString
                  }
                </td>
                {!phone && (
                  <td>
                    {_.upperFirst(
                      _.lowerCase(
                        monthlyCosts?.find((mc) => mc.month.toString() == month)
                          ?.stage
                      )
                    ) || ''}
                  </td>
                )}
              </tr>
            </tbody>
          </Box>
          <Collapse in={getExpandedValue(month)}>
            <Box
              component={'table'}
              cellSpacing={0}
              sx={{
                ...drugSummaryTableStyles,
                '& td': { borderBottom: '1px solid #B3B3B3' },
              }}
            >
              <tbody>
                {groupedCosts[month].map((cost, i) => (
                  <tr key={i}>
                    <Box
                      component={'td'}
                      sx={{ pl: { sm: '36px' } }}
                      colSpan={2}
                    >
                      <Typography variant={'caption'}><b>{cost.packageName}</b></Typography>
                    </Box>
                    <td>{cost.numericCost}</td>
                    <td>{cost.cost}</td>
                    {!phone && <td></td>}
                  </tr>
                ))}
              </tbody>
            </Box>
          </Collapse>
        </React.Fragment>
      ))}
    </div>
  );
};

const drugSummaryTableStyles: SxProps = {
  tableLayout: 'fixed',
  width: 1,
  fontSize: 12,
  '& thead td': {
    backgroundColor: '#F0F7FA',
    fontWeight: 600,
    px: 1.5,
    py: 1,
  },
  '& tbody td': {
    backgroundColor: '#fff',
    verticalAlign: 'top',
    borderBottom: '1px solid #E5E7EB',
    px: 1.5,
    py: 1,
  },
};
