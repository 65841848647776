import { useFindProvidersByFilterQuery } from '@coverright/data-access/medicare';
import * as _ from 'lodash';

export function useDoctors(zip: string, searchTerm: string) {
  const { data, loading } = useFindProvidersByFilterQuery({
    skip: !searchTerm,
    variables: {
      searchTerm: _.trim(searchTerm.replace('dr.', '').replace('Dr.', '')),
      zip,
    },
  });

  return { doctors: data?.findProvidersByFilter || [], loading };
}
