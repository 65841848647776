import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { FilterItem, PlansFilterInput, QueryPlanTypesArgs } from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function useMedicareFilterData() {
  return useLazyQuery<IPlanFilterResponse, QueryPlanTypesArgs & {quoteId?: string | null}>(gql(planFilterQuery))
}


export function getMedicareFilterCompanies(filter: PlansFilterInput) {
  return client.query({
    query: gql(companiesQuery),
    variables: {filter}
  })
}

export interface IPlanFilterResponse {
  planTypes: FilterItem[],
  companies: FilterItem[],
  SNPTypes: FilterItem[],
  extraBenefits: FilterItem[],
  maxOutOfPocketRanges: FilterItem[],
  maxCostRanges: FilterItem[],
  findStarRatings: FilterItem[],
  partBPremiumReductionCount: number,
}

export interface IPlanFilterShortResponse {
  planTypes: FilterItem[],
  SNPTypes: FilterItem[],
  extraBenefits: FilterItem[],
  companies: FilterItem[],
}

const companiesQuery = `
query($filter: PlansFilterInput!) {
    companies(filter: $filter) {
      key
      name
      count
    }
}
`;

const planFilterQuery = `
query($filter: PlansFilterInput!, $quoteId: UUID) {
    planTypes(filter: $filter) {
      key
      name
      count
    }
    companies(filter: $filter) {
      key
      name
      count
    }
    extraBenefits(filter: $filter) {
      key
      name
      count
    }
    SNPTypes(filter: $filter) {
      key
      name
      count
    }
    maxOutOfPocketRanges(filter: $filter) {
      key
      name
      count
    }
    maxCostRanges(filter: $filter, quoteId: $quoteId) {
      key
      name
      count
    }
    findStarRatings(filter: $filter) {
      key
      name
      count
    }
    partBPremiumReductionCount(filter: $filter)
}
`;
