import React from "react";
import { Collapse, Tooltip, Typography } from '@mui/material';
import { DoctorCoverage } from '@coverright/data-access/types/medicare';
import { Benefit, PlanParam, SeeMore } from '../..';
import { CheckCircle, XCircle } from '@phosphor-icons/react';

export const DoctorCoverageList = ({coverage, isPPO, hideTitle, labelClassName, titleClassName, length = 3}: { coverage: DoctorCoverage[], isPPO: boolean, titleClassName?: string, hideTitle?: boolean, labelClassName?: string, length?: number }) => {
  const [expanded, setExpanded] = React.useState(false);

  if(coverage.length === 0) {
    return <Typography className={'fs-12 card-title'} color={'textPrimary'}>You haven’t added any doctors</Typography>;
  }

  return <>
    <Tooltip arrow title={tooltip}>
      <div>
        {!hideTitle && <Typography className={`fs-11 lh-13 mv-12 ${titleClassName}`}>Doctor name:</Typography>}
        {coverage.slice(0, length).map((doctor, index) => <PlanParam key={doctor.npi}
                                                                value={<Benefit inactive={doctor.coverage === null}
                                                                                labelClass={labelClassName || 'mb-1'}
                                                                                overrideCrossIconColor={isPPO ? '#F5B556' : undefined}
                                                                                value={doctor.coverage || false}
                                                                                label={doctor.name} />} />)}
        <Collapse in={expanded}>
          {coverage.slice(length).map((doctor, index) => <PlanParam key={doctor.npi}
                                                               value={<Benefit inactive={doctor.coverage === null}
                                                                               labelClass={labelClassName || 'mb-1'}
                                                                               overrideCrossIconColor={isPPO ? '#F5B556' : undefined}
                                                                               value={doctor.coverage || false}
                                                                               label={doctor.name} />} />)}
        </Collapse>
      </div>
    </Tooltip>
    {coverage.length > length && <SeeMore sx={{mt: 1}} expanded={expanded} onToggle={value => setExpanded(!value)} />}
    </>
}

const tooltip = <div>
  <div className={'flex flex-align-center'}>
    <CheckCircle className={`mr-8`} size={20} color={"#029094"} weight="fill" />
    In-network
  </div>
  <div className={'flex flex-align-center'}>
    <XCircle className={`mr-8`} size={20} color={"#DD3D3D"} weight="fill" />
    Out-of-network (not covered)
  </div>
  <div className={'flex flex-align-center'}>
    <XCircle className={`mr-8`} size={20} color={"#F5B556"} weight="fill" />
    Out-of-network (covered)
  </div>
  <div className={'flex flex-align-center'}>
    <XCircle className={`mr-8`} size={20} color={"rgba(28,67,79,0.5)"} weight="fill" />
    Unable to check
  </div>
</div>
