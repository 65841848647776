import React, { forwardRef } from 'react';
import { InputMask, InputMaskProps } from '@react-input/mask';
import { InputProps, TextInput } from './text-input/text-input';
import moment from 'moment';

const ForwardedInputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => (
  <InputMask ref={forwardedRef} mask="mm/dd/yyyy" replacement={{ d: /\d/, m: /\d/, y: /\d/ }} {...props} />
));

export interface DateInputProps extends InputProps {
  selectAllOnFocus?: boolean
}


export const DateInput = forwardRef((props: DateInputProps, ref) => {
  const {selectAllOnFocus, ...rest} = props;

  const valid = typeof props.value !== 'string' || props.value.length < 10 ? false : moment(props.value, 'MM/DD/YYYY').isValid();

  return <TextInput
    ref={ref}
    valid={valid}
    {...rest}
    InputProps={{
      ...props.InputProps,
      inputComponent: ForwardedInputMask,
      onFocus: e => {
        selectAllOnFocus && e.target.select();
        props.InputProps?.onFocus && props.InputProps.onFocus(e);
      }
    }}
  />
})
