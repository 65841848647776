import { usePackagesQuery } from '@coverright/data-access/medicare';

export function usePackages(rxcui?: string | null) {
  const { data: packagesData } = usePackagesQuery({
    skip: !rxcui,
    variables: { rxcui: rxcui! },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data?.packages.length) {
        console.error('There is no drugs with this name');
      }
    },
  });

  return packagesData?.packages;
}
