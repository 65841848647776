import React from "react";
import {
  ClickAwayListener, Hidden,
  Tooltip as MuiTooltip,
  TooltipProps
} from '@mui/material';

export const Tooltip = ({ className, ...props }: TooltipProps & {className?: any}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickAway = () => {
    setOpen(false)
  }
  return <>
    <Hidden smDown>
      <MuiTooltip {...props} classes={{ popper: className }}/>
    </Hidden>
    <Hidden smUp>
      <ClickAwayListener onClickAway={handleClickAway}>
        <MuiTooltip {...props} open={open} onClick={() => {
          setOpen(true)
        }} classes={{ popper: className }}/>
      </ClickAwayListener>
    </Hidden>
  </>
};

