import React from 'react';
import paths from '../config/router-paths';
import { useNavigate } from 'react-router-dom';
import { getStoredUser, setStoredUser } from '@coverright/utils';
import { UpdateZipModal } from '@coverright/widgets';

const ZipSelection = () => {
  const navigate = useNavigate();

  return (
    <UpdateZipModal
      title={'Enter your zip code'}
      onClose={(zip?: string, county?: string, state?: string) => {
        if (zip && county && state) {
          setStoredUser({
            ...getStoredUser(),
            countyName: county,
            zip,
            state,
          });
          navigate(
            paths.plans + zip + '/' + county + document.location.search
          );
        }
      }}
      open
    />
  );
};

export default ZipSelection;
