import ErrorModal from '../ErrorModal';
import { Typography } from '@mui/material';
import React from 'react';

export const DrugErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see plans with no Prescription Drug coverage?'}
                     subtitle={'You may incur Late Enrollment Penalties'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>Penalties can apply if you do not have Medicare drug coverage (Part D) after you first become eligible for Medicare.  The only exception is if you have other qualifying (or 'creditable') coverage that provides drug coverage as good as Medicare's (such as from an employer, TRICARE or VA).
                       <br /><br />You must have drug coverage to avoid penalties even if you are not currently taking any prescription drugs.</Typography>}
                     buttonLabel={'Yes - I have other qualifying coverage '}
                     onClose={props.onClose}
                     open={props.open}/>
}

export const DsnpOnErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see D-SNP plans?'}
                     subtitle={'You might not be eligible to enroll'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>Dual-eligible Special Needs Plans (D-SNP) are special plans designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries. <br /><br /> You can not enroll in a D-SNP plan unless you are eligible for both Medicare and Medicaid.</Typography>}
                     buttonLabel={'Yes - I want to see D-SNP plans  '}
                     onClose={props.onClose}
                     open={props.open}/>
}

export const DsnpOffErrorModal = (props: any) => {
  return <ErrorModal title={'Are you sure you want to see regular Medicare Advantage plans?'}
                     subtitle={'This will unselect the Dual-Eligible Special Needs Plans (D-SNP) filter'}
                     text={<Typography align={'center'} className={'fs-18 lh-22'}>D-SNP plans are special plans designed for Medicare-Medicaid ‘dual-eligible’ beneficiaries. <br/><br />
                       These plans provide you convenience by coordinating all your Medicare and Medicaid benefits into one simple health plan (unlike regular Medicare Advantage plans).</Typography>}
                     buttonLabel={'Yes - I want to see regular MA plans'}
                     onClose={props.onClose}
                     open={props.open}/>
}
