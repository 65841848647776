import { Box, Typography } from '@mui/material';
import React from 'react';

export type JsxPlanDetailProps = { title: string | JSX.Element, content: any, className?: string, titleClassName?: string }

export function JsxPlanDetail({title, content, className, titleClassName}: JsxPlanDetailProps) {
  return (
    <>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: "flex-start"}}  my={"20px"} className={className}>
        <Box sx={{minWidth: {md: '50%'}, width: {md: '50%'}, pl: {xs: 3, md: '20px'}}}>
          <Typography variant={"h5"} className={titleClassName}>
            {title}
          </Typography>
        </Box>
        <Box display={"flex"} className={'pr-20'} mt={{xs: '5px', md: 0}} ml={{xs: 3, md: 0}}>
          {content}
        </Box>
      </Box>
    </>
  )
}
