import * as React from 'react';
import { Box } from '@mui/material';
import NoPackagesModal from './ui/no-packages-modal';
import { DrugDosageModal } from './ui/drug-dosage-modal';
import {
  DrugPackagesInfoQuery,
  useDrugPackagesInfoQuery,
  useDrugsWithPackageLazyQuery,
} from '@coverright/data-access/medicare';
import {
  CalcDrugFrequencyPeriod,
  DrugInfoWithPack,
  DrugOutput,
  FrequencyPeriod,
} from '@coverright/data-access/types/medicare';
import {
  DrugName,
  PreferredDrug,
  PreferredDrugOutput,
  DrugSelectionProps,
  DrugDosage,
} from '../';
import ConfirmBrandedDrugModal from './ui/confirm-branded-drug-modal';
import { DrugAutocomplete } from './ui/drug-autocomplete';
import { DrugList } from './ui/drug-list';
import { useState } from 'react';
import { hasNoPackages, toOutput } from './lib';
import { usePackages } from './api/use-packages';

enum Mode {
  Search,
  NoPackages,
  SelectGenericOrBranded,
  SelectDosage,
  EditDrug,
}

export function DrugSelection(props: DrugSelectionProps) {
  const [mode, setMode] = useState<Mode>(Mode.Search);

  const [drugs, setDrugs] = React.useState<
    Array<DrugOutput & { notSupported: boolean }>
  >([]);

  const [autocompleteValue, setAutocompleteValue] =
    React.useState<DrugInfoWithPack>();

  // branded or generic version of drug
  const [selectedDrug, setSelectedDrug] = React.useState<DrugName>();

  const [selectedDosage, setSelectedDosage] = React.useState<PreferredDrug>();

  const [getDrugsWithPackage] = useDrugsWithPackageLazyQuery();

  const getDrugs = async (
    pairs: { packageRxcui: string; productRxcui: string }[]
  ): Promise<DrugInfoWithPack[]> =>
    getDrugsWithPackage({ variables: { pairs } }).then(
      (res) => res.data?.drugsWithPackage || []
    );

  const handleDrugPackagesComplete = ({
    drugPackagesInfo,
  }: DrugPackagesInfoQuery) => {
    if (hasNoPackages(!!autocompleteValue?.isGeneric, drugPackagesInfo)) {
      setMode(Mode.NoPackages);
    } else {
      const hasGeneric =
        !autocompleteValue?.isGeneric &&
        autocompleteValue?.genericName &&
        drugPackagesInfo?.hasGenericPackages;
      // show generic select modal for branded drugs who has generics
      if (hasGeneric) {
        setMode(Mode.SelectGenericOrBranded);
      } else {
        if (autocompleteValue?.name && autocompleteValue?.rxcui) {
          setSelectedDrug({
            name: autocompleteValue.name,
            rxcui: autocompleteValue.rxcui,
          });
          setMode(Mode.SelectDosage);
        }
      }
    }
  };

  const { data: packagesInfoData } = useDrugPackagesInfoQuery({
    skip: !autocompleteValue?.rxcui,
    variables: { rxcui: autocompleteValue?.rxcui as string },
    onCompleted: (data) => handleDrugPackagesComplete(data),
    fetchPolicy: 'no-cache',
  });

  const packages = usePackages(selectedDrug?.rxcui);

  React.useEffect(() => {
    if (props.value.length) {
      getDrugs(
        props.value.map((d) => ({
          packageRxcui: d.dosageRxcui,
          productRxcui: d.productRxcui,
        }))
      )
        .then((res) => {
          setDrugs(
            res.map((info) => {
              const drug = props.value.find(
                (d) =>
                  d.productRxcui === info.rxcui &&
                  d.dosageRxcui === info.packRxcui
              ) as PreferredDrug;
              return {
                quantity: drug.quantity,
                frequency: drug.frequency as CalcDrugFrequencyPeriod,
                genericName: info.genericName,
                genericRxcui: info.genericRxcui,
                isGeneric: !!info.isGeneric,
                name: info.name,
                packName: info.packName,
                packRxcui: info.packRxcui || '',
                purchaseFrequency: drug.purchaseFrequency,
                rxcui: info.rxcui || '',
                notSupported: !!info.notSupported,
              };
            })
          );
        })
        .catch(() => setDrugs([]));
    } else {
      setDrugs([]);
    }
  }, [props.value]);

  const handleBrandedDrugModalClose = (useGeneric?: boolean) => {
    if (typeof useGeneric !== 'undefined') {
      if (autocompleteValue) {
        setSelectedDrug(
          useGeneric
            ? {
                name: autocompleteValue.genericName || '',
                rxcui: autocompleteValue.genericRxcui || '',
              }
            : {
                name: autocompleteValue.name || '',
                rxcui: autocompleteValue.rxcui || '',
              }
        );
      }
      setMode(Mode.SelectDosage);
    } else {
      setAutocompleteValue(undefined);
      setMode(Mode.Search);
    }
  };

  const handleDrugDosageModalClose = async (result?: DrugDosage) => {
    if (typeof result !== 'undefined' && selectedDrug?.rxcui) {
      const drugsWithPackage = await getDrugs([
        { packageRxcui: result.dosage, productRxcui: selectedDrug.rxcui },
      ]);

      if (mode === Mode.EditDrug) {
        if (props.value.some((i) => i.dosageRxcui === result.dosage)) {
          props.onChange(
            props.value.reduce(
              (prev, cur) =>
                cur.dosageRxcui === result.dosage
                  ? [...prev, toOutput(result, drugsWithPackage[0])]
                  : [...prev, cur],
              [] as PreferredDrugOutput[]
            )
          );
        } else if (selectedDosage) {
          // dosage changed
          props.onChange(
            props.value.reduce(
              (prev, cur) =>
                cur.productRxcui === selectedDosage.productRxcui
                  ? [...prev, toOutput(result, drugsWithPackage[0])]
                  : [...prev, cur],
              [] as PreferredDrugOutput[]
            )
          );
        } else {
          throw new Error('Fail to find drug dosage in list');
        }
      } else {
        props.onChange([...props.value, toOutput(result, drugsWithPackage[0])]);
      }
    }

    setAutocompleteValue(undefined);
    setSelectedDosage(undefined);
    setSelectedDrug(undefined);
    setMode(Mode.Search);
  };

  const handleNoPackagesModalClose = (switchTo?: DrugName) => {
    if (switchTo) {
      setSelectedDrug(switchTo);
      setMode(Mode.SelectDosage);
    } else {
      setSelectedDosage(undefined);
      setAutocompleteValue(undefined);
      setMode(Mode.Search);
    }
  };

  const onEditDrugClick = (item: DrugOutput) => {
    if (!item.rxcui || !item.name) {
      console.error('Has no data for drug');
      item.rxcui = item.rxcui || '';
      item.name = item.name || '';
    }

    setSelectedDrug({ rxcui: item.rxcui, name: item.name });
    setSelectedDosage({
      dosage: item.packName || '',
      dosageRxcui: item.packRxcui || '',
      frequency: item.frequency || '',
      ndc: item.frequency || '',
      productId: item.name || '',
      productRxcui: item.rxcui || '',
      quantity: item.quantity || 0,
      purchaseFrequency: item.purchaseFrequency || FrequencyPeriod.Monthly,
    });
    setMode(Mode.EditDrug);
  };

  const onDeleteDrugClick = (item: DrugOutput) => {
    const result = props.value.filter(
      (v) => v.productRxcui !== item.rxcui || v.dosageRxcui !== item.packRxcui
    );
    props.onChange(result);
  };

  const handleBackButtonClick = () => {
    if (
      hasNoPackages(
        !!autocompleteValue?.isGeneric,
        packagesInfoData?.drugPackagesInfo
      )
    ) {
      setMode(Mode.NoPackages);
    } else {
      setMode(
        !autocompleteValue?.isGeneric
          ? Mode.SelectGenericOrBranded
          : Mode.Search
      );
    }
  };

  //TODO back button doesn't work
  return (
    <>
      <NoPackagesModal
        info={packagesInfoData?.drugPackagesInfo}
        open={mode === Mode.NoPackages}
        onClose={handleNoPackagesModalClose}
      />

      <ConfirmBrandedDrugModal
        drug={autocompleteValue}
        open={mode === Mode.SelectGenericOrBranded}
        onClose={handleBrandedDrugModalClose}
      />

      <DrugDosageModal
        packages={packages}
        drug={selectedDrug}
        dosage={selectedDosage}
        open={mode === Mode.SelectDosage || mode === Mode.EditDrug}
        showBackButton={mode === Mode.SelectDosage}
        onBackButtonClick={handleBackButtonClick}
        onPackageSelected={(packageRxcui) => {
          if (selectedDrug?.rxcui) {
            getDrugs([{ packageRxcui, productRxcui: selectedDrug.rxcui }]);
          }
        }}
        onClose={handleDrugDosageModalClose}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection:
            props.inputPosition === 'top' ? 'column' : 'column-reverse',
          gap: drugs.length ? 4 : 0,
        }}
      >
        <DrugAutocomplete label={props.label} onSelect={setAutocompleteValue} />

        <DrugList
          onDeleteDrugClick={onDeleteDrugClick}
          onEditDrugClick={onEditDrugClick}
          items={drugs}
        />
      </Box>
    </>
  );
}
