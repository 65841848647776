import { ComparablePlan, useMedicarePlansByBid, usePdpPlansByBid } from '@coverright/data-access/medicare';
import { GetPlansYear } from '@coverright/utils';
import { addDetailsToMGPlanList, useMedigapPlansByBid } from '@coverright/data-access/medigap';
import { ComparablePlanType } from '@coverright/data-access/types/medicare';
import { useCallback, useMemo } from 'react';

type AppContext  = [
  data: {mgSavedPlans?: ComparablePlan[], maSavedPlans?: ComparablePlan[], pdpSavedPlans?: ComparablePlan[]},
  saveState: (data: any) => void
]

export function usePlansForCompare(appContext: AppContext, medicareQuoteId?: string, clientId?: string) {
  const [data, saveState ] = appContext;

  const maConverted = useMemo(
    () => {
      const plans = Array.isArray(data.maSavedPlans) ? data.maSavedPlans : []
      return plans.map((p) => ({
        bidId: p.bidId,
        planYear: p.planYear || undefined,
        zip: p.zip,
        countyName: p.county,
        quoteId: medicareQuoteId,
      }))
    },
    [data.maSavedPlans]
  );

  const maPlans = useMedicarePlansByBid(maConverted);

  const pdpConverted = useMemo(
    () => {
      const plans = Array.isArray(data.pdpSavedPlans) ? data.pdpSavedPlans : []
      return plans.map((p) => ({
        bidId: p.bidId,
        planYear: p.planYear || GetPlansYear(),
        zip: p.zip,
        countyName: p.county,
      }))
    },
    [data.pdpSavedPlans]
  );

  const pdpPlans = usePdpPlansByBid(pdpConverted, clientId);

  const mgConverted = useMemo(
    () => {
      const plans = Array.isArray(data.mgSavedPlans) ? data.mgSavedPlans : []
      return plans.map((p) => p.bidId)
    },
    [data.mgSavedPlans]
  );

  const mgPlans = useMedigapPlansByBid(mgConverted);

  const removePlan = (type: ComparablePlanType, id: string) => {
    const key = `${type.toLowerCase()}SavedPlans` as keyof AppContext[0];
    const savedPlans = Array.isArray(data[key]) ? data[key] : [];
    if (savedPlans) {
      saveState({
        [`${type.toLowerCase()}SavedPlans`]: savedPlans.filter(
          (p) => p.bidId !== id
        ),
      });
    }
  };

  const savePlan = (plan: ComparablePlan) => {
    const key = `${plan.type.toLowerCase()}SavedPlans` as keyof AppContext[0];
    const savedPlans = Array.isArray(data[key]) ? data[key] : [];
    saveState({
      [`${plan.type.toLowerCase()}SavedPlans`]: [
        ...savedPlans!.filter((p) => p.bidId !== plan.bidId),
        plan,
      ],
    });
  };

  const isPlanSaved = (plan: ComparablePlan) => {
    const key = `${plan.type.toLowerCase()}SavedPlans` as keyof AppContext[0];
    const savedPlans = Array.isArray(data[key]) ? data[key] : [];
    return savedPlans!.some((p) => p.bidId === plan.bidId);
  };

  return {
    maPlans: {
      ...maPlans,
      count: data.maSavedPlans?.length || 0,
    },
    pdpPlans: {
      ...pdpPlans,
      count: data.pdpSavedPlans?.length || 0,
    },
    mgPlans: {
      ...mgPlans,
      plans: addDetailsToMGPlanList(mgPlans.plans),
      count: data.mgSavedPlans?.length || 0,
    },
    removePlan,
    savePlan,
    isPlanSaved,
  };
}
