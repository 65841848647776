import React from 'react';
import './app.module.scss';
import { ApolloProvider } from '@apollo/client';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  getQuoteId,
  resetStoredUser
} from '@coverright/utils';
import { ComparisonProvider } from './ComparisonContext';
import paths from '../config/router-paths';
import ZipSelection from './ZipSelection';
import DashboardLayout from './pages/plan-list/DashboardLayout';
import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import EditPreferences from './pages/edit-preferences/EditPreferences';
import DetailedPlan from './pages/detailed-plan/detailed-plan';
import PlanComparison from './pages/plan-comparison/plan-comparison';
import Completed from './pages/completed/completed';
import Favorites from './pages/favorites/favorites';
import {
  keycloak
} from '@coverright/shared/keycloak';
import {
  CompareOfferContextProvider,
  FavoritesProvider,
  QuoteProvider, withAppContextProvider
} from '@coverright/shared/contexts';
import { client, setErrorHandler } from '@coverright/data-access/apollo-clients';
import * as _ from 'lodash';
import Hub from './Hub';
import SignIn from './pages/sign-in/sign-in';
import SignUpFlow from './pages/sign-in/sign-up-flow';
import SignUpBirthdate from './pages/sign-in/sign-up-birthdate';
import SignUpPersonalInfo from './pages/sign-in/sign-up-personal-info';

function App() {
  const { enqueueSnackbar } = useSnackbar();

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        switch (extensions?.status) {
          case '403':
            {
              if (keycloak?.authenticated) {
                resetStoredUser();
                document.location.href = document.location.origin;
              } else {
                keycloak?.login();
              }
            }
            break;
          default: {
            console.error(
              `Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
            enqueueSnackbar('Network error, please try again later', {
              variant: 'error',
            });
          }
        }
      });
  });

  return (
    <ThemeProvider theme={AppTheme}>
      <ApolloProvider client={client}>
        <QuoteProvider quoteId={getQuoteId()}>
          <ComparisonProvider>
            <FavoritesProvider>
              <CompareOfferContextProvider adminOnly>
                <Routes>
                  <Route
                    path={paths.plans}
                    element={<Hub />}
                  />
                  <Route
                    path={paths.zip}
                    element={<ZipSelection />}
                  />
                  <Route
                    path={paths.plans + 'undefined/undefined'}
                    element={<Hub />}
                  />
                  <Route
                    path={paths.plans + ':zip/:countyName'}
                    element={<DashboardLayout />}
                  />
                  <Route
                    path={paths.editPreferences + ':zip/:countyName/:tab?'}
                    element={<EditPreferences />}
                  />
                  <Route
                    path={paths.planDetails + ':zip/:countyName/:planId'}
                    element={<DetailedPlan />}
                  />
                  <Route
                    path={paths.comparison + ':zip/:countyName'}
                    element={<PlanComparison />}
                  />
                  <Route
                    path={paths.completed + ':zip/:countyName/:planId'}
                    element={<Completed />}
                  />
                  <Route
                    path={paths.favorites + ':zip/:countyName'}
                    element={<Favorites />}
                  />
                  <Route path={'/sign-in'} element={<SignIn />} />
                  <Route path={'/sign-up'} element={<SignUpFlow />}>
                    <Route
                      path={'/sign-up/birthdate'}
                      element={<SignUpBirthdate />}
                    />
                    <Route
                      path={'/sign-up/personal-info'}
                      element={<SignUpPersonalInfo />}
                    />
                  </Route>

                  <Route path={paths.home} element={<Navigate to={paths.plans + document.location.search} />} />
                </Routes>
              </CompareOfferContextProvider>
            </FavoritesProvider>
          </ComparisonProvider>
        </QuoteProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default _.flowRight([withAppContextProvider])(App)
