import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  DrugCostMonthlyBreakdownOutput,
  DrugDiscount, DrugPriceOutput,
  DrugTiersOutput,
  Maybe,
  Plan, PlanYear,
  QueryPlanArgs, QuoteDrugTiersOutput
} from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';
import { useMemo } from 'react';
import { GetNextPlansYear } from '@coverright/utils';

export function useMedicarePlan(options?: LazyQueryHookOptions<IPlanOutput, QueryPlanArgs>) {
  return useLazyQuery<IPlanOutput, QueryPlanArgs>(gql(getPlan), {
    ...options,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  });
}

export function useMedicarePlansByBid(inputPlans: IPlanInput[] = [], onCompleted?: (data: any) => void, fullYear?: boolean) {
  const request = useMemo(() => {
    return getPlansQuery(inputPlans, fullYear)
  }, [inputPlans, fullYear]);

  const { loading, called, data, previousData, refetch } = useQuery<{
    [name: string]: PlanWithTiers
  }>(gql(request), {
    onCompleted,
    // fetchPolicy: 'no-cache',
    skip: !inputPlans?.length
  });

  const plans = Object.values(data || previousData || {}).filter(p => !!p && inputPlans.some(ip => ip.bidId === p.bidId)) || [];

  return { plans: plans, loading, called, refetch };
}

const getPlansQuery = (plans?: IPlanInput[], fullYear?: boolean) => {
  let query;
  if (plans?.length) {
    query = plans.map((plan, i: number) => {
      const planFullYear = fullYear ? fullYear : plan.planYear === GetNextPlansYear();
      return `plan${i}: plan(fullYear: ${planFullYear ? 'true' : 'false'}, bidId: "${plan.bidId}", countyName: "${plan.countyName}", zip: "${plan.zip}"${plan.planYear ? ', planYear: ' + plan.planYear : ''}${plan.quoteId ? ', quoteId: "' + plan.quoteId + '"' : ''}) {
      ${planFields}
    }`
    });
  } else {
    query = `getMyQuoteId`;
  }
  return `query getMedicarePlansByBid{${query}}`;
};

interface IPlanInput {
  zip: string,
  bidId: string,
  quoteId?: string | null,
  countyName: string,
  planYear?: PlanYear
}

interface IPlanOutput {
  plan: PlanWithTiers;
}

export const planFields = `
    prescriptionDrugDeductible @client
    bidId
    monthlyCost
    primaryCarePhysician
    physicianSpecialist
    firstWeekOfStay
    outOfPocketMaximum
    inNetworkDeductableAmount
    maxEnrollmentAmount
    monthlyPremium
    orgName
    parentOrgName
    outOfPocketAmount
    planName
    planType
    planYear
    planTypeCode
    alternativeMedicine
    annualPhysicalExam
    chiropractic
    dental {
      benefits
      maxPlanCoverageAmount
    }
    ratingNote
    partBGiveBack
    missingFormularyInAep
    partBAmount
    SNPType
    optionalSupplementalBenefits
    optionalSupplementalBenefitsItems
    primaryCarePhysicianInNetwork
    primaryCarePhysicianOutOfNetwork
    doctorsCoverage {
      npi
      coverage
      name
    }
    drugsCoverage{
      rxcui
      coverage
      name
    }
    drugPrices {
      catastrophicPrice
      coverageGapPrice
      initialCoveragePrice
      name
      price
      unitPrice
      rxcui
      tier
      quoteQuantity
      isCoveredByPlan
      packageDescription
      quantityLimit
      isAggregatedPrice
      isGeneric
      isInsulinDrug
    }
    extraBenefits {
      drugCoverage
      dental
      vision
      hearing
      transportation
      fitness
      telehealth
      worldwideEmergency
      insulinSavings
    }
    drugDetails {
      catastrophicCoverage {
        items {
          title
          values
        }
        title
      }
      catastrophicCoverageDescription
      coverageGap {
        items {
          title
          values
        }
        title
      }
      coverageGapDescription
      initialCoverage {
         items {
          title
          values
        }
        title
      }
      initialCoverageDescription
      initialCoverageLimit
      catastrophicThreshold
      catastrophicCopayGeneric
      catastrophicCopayBranded
      mrxAltDedAmount
      mrxAltNoDedTier
    }
    hearing {
      benefits
      coverageAmountForEars
    }
    homeHealth
    hospitalBenefits
    hospitalityDetails {
      ambulanceServices
      emergencyRoom
      urgentServices
    }
    meal
    mentalHealth
    oTC
    otherDefinedSupplemental {
      counselingServices
      enhancedDiseaseManagement
      fitnessBenefit
      healthEducation
      homeAndBathroomSafety
      inHomeSafetyAssessment
      medicalNutritionTherapy
      nutritionalDietaryBenefit
      personalEmergencyResponseSystem
      postDischargeInHome
      remoteAccessTechnologies
      smokingAndTobaccoCessationCounseling
      telemonitoringServices
    }
    preventiveCare
    outpatientHospitality
    outpatientRehabilitation
    physicianSpecialistShort
    primaryCarePhysicianShort
    outpatientSurgery
    skilledNursing
    transportation
    telehealth
    vision {
      benefits
      allEyewearMaxMount
      eyeExamsMaxAmount
    }
    drugsCost
    doctorsCost
    rating
    drugCostMonthlyBreakdown {
      totalClientCost
      costs {
        name
        month
        cost
        rxcui
        stage
      }
      coverageEntry {
        stage
        month
      }
      monthlyCosts {
        amount
        amountString
        month
        stage
      }
    }
    quoteDrugsTiers(supplyDuration: ONE_MONTH) {
      deliveryType
      pharmacyName
      initialCoverageDrugTiers {
        coverage
        tierName
        tier
      }
    }
    drugsTiers30Preferred: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: PREFERRED_RETAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers30Standard: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: STANDARD_RETAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers90Preferred: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: PREFERRED_MAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      drugsTiers90Standard: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: STANDARD_MAIL) {
        initialCoverageDrugTiers {
          coverage
          tierName
          tier
        }
        coverageGapDrugTiers {
          coverage
          tierName
          tier
        }
      }
      planFiles {
        evidenceOfCoverageDocUrl
        nonDiscriminationNoticeDocUrl
        preEnrollmentChecklistDocUrl
        starRatingDescDocUrl
        summaryOfBenefitsUrl
      }
`;

const getPlan = `
query($bidId: String!, $zip: String!, $countyName: String!, $quoteId: UUID, $fullYear: Boolean, $planYear: PlanYear) {
  plan(bidId: $bidId, zip: $zip, quoteId: $quoteId, countyName: $countyName, fullYear: $fullYear, planYear: $planYear) {
    ${planFields}
  }
}
`;

export const doesMedicarePlanHasDiscounts = async (variables: QueryPlanArgs): Promise<boolean> => {
  const res = await client.query<{ plan: { discountedDrugs: { discount: string }[] } }>({
    query: gql(`
      query($bidId: String!, $zip: String!, $countyName: String!, $fullYear: Boolean, $planYear: PlanYear, $quoteId: UUID) {
        plan(bidId: $bidId, zip: $zip, countyName: $countyName, fullYear: $fullYear, planYear: $planYear, quoteId: $quoteId) {
          discountedDrugs {
            discount
          }
        }
      }
    `),
    variables
  });
  const discounts = res.data?.plan.discountedDrugs?.map(d => +d.discount) || [];

  return discounts.some(v => v > 0);
};

export function useMedicarePlanDiscounts(options?: QueryHookOptions<{ plan: IDrugCostBreakdownPlan }, QueryPlanArgs>) {
  return useLazyQuery<{ plan: IDrugCostBreakdownPlan }, QueryPlanArgs>(
    gql(`
      query($bidId: String!, $zip: String!, $countyName: String!, $quoteId: UUID, $fullYear: Boolean, $planYear: PlanYear) {
        plan(bidId: $bidId, zip: $zip, quoteId: $quoteId, countyName: $countyName, fullYear: $fullYear, planYear: $planYear) {
          orgName
          parentOrgName
          planName
          planYear
          ratingNote
          rating
          quoteDrugsTiers(supplyDuration: ONE_MONTH) {
            deliveryType
            pharmacyName
            initialCoverageDrugTiers {
              coverage
              tierName
              tier
            }
          }
          drugDetails {
            catastrophicCoverage {
              items {
                title
                values
              }
              title
            }
            catastrophicCoverageDescription
            coverageGap {
              items {
                title
                values
              }
              title
            }
            coverageGapDescription
            initialCoverage {
               items {
                title
                values
              }
              title
            }
            initialCoverageDescription
            initialCoverageLimit
            catastrophicThreshold
            catastrophicCopayGeneric
            catastrophicCopayBranded
            mrxAltDedAmount
            mrxAltNoDedTier
          }
          drugsCost
          drugCostMonthlyBreakdown {
            totalClientCost
            costs {
              name
              month
              cost
              rxcui
              stage
            }
            coverageEntry {
              stage
              month
            }
            monthlyCosts {
              amount
              amountString
              month
              stage
            }
          }
          drugPrices {
            catastrophicPrice
            coverageGapPrice
            initialCoveragePrice
            name
            price
            unitPrice
            rxcui
            tier
            quoteQuantity
            isCoveredByPlan
            packageDescription
            quantityLimit
            isAggregatedPrice
            isGeneric
            isInsulinDrug
          }

          discountedDrugs {
            carrierCost
            discount
            discountBrand
            costPlusCost
            costPlusDetails {
              quantity
              rxcui
              unitPrice
              url
            }
            goodRxCost
            goodRxDetails {
              dosage
              form
              mobileUrl
              price
              quantity
              rxcui
              url
            }
            normalizedQuantity
            rxcui
          }
        }
      }
    `),
    {
      ...options,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    });
}

export interface IDrugCostBreakdownPlan {
  drugCostMonthlyBreakdown?: DrugCostMonthlyBreakdownOutput | null,
  drugPrices: DrugPriceOutput[],
  discountedDrugs?: DrugDiscount[] | null,
  drugsCost?: string | null,
  parentOrgName?: string | null,
  orgName?: string | null,
  planName?: string | null,
  planYear?: PlanYear | null,
  rating?: number | null,
  drugDetails?: {
    mrxAltDedAmount?: string | null,
    initialCoverageLimit?: string | null,
    catastrophicCopayGeneric?: string | null,
    catastrophicThreshold?: string | null,
    catastrophicCopayBranded?: string | null,
  } | null,
  quoteDrugsTiers?: QuoteDrugTiersOutput | null
}

export interface PlanWithTiers extends Plan {
  drugsTiers30Preferred: Maybe<DrugTiersOutput>,
  drugsTiers30Standard: Maybe<DrugTiersOutput>,
  drugsTiers90Preferred: Maybe<DrugTiersOutput>,
  drugsTiers90Standard: Maybe<DrugTiersOutput>,
}

