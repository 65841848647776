import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationEmailMeMaPageArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useEmailMeMAPage(options?: MutationHookOptions<{emailMeMAPage: boolean}, MutationEmailMeMaPageArgs>) {
  return useMutation<{emailMeMAPage: boolean}, MutationEmailMeMaPageArgs>(gql(mutation), {
    ...options,
    client: enrollmentClient
  })
}

const mutation = `
mutation ($email: String!, $id: UUID!, $yearEmailMeMaPage: EmailMeMaPagePlanYear) {
  emailMeMAPage(email: $email, id: $id, yearEmailMeMaPage: $yearEmailMeMaPage)
}
`;

