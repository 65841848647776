const paths = {
  home: '/',
  zip: '/zip',
  plans: '/plans/',
  drugs: '/drugs/',
  doctors: '/doctors/',
  pharmacies: '/pharmacies/',
  benefits: '/benefits/',
  planDetails: '/plan/',
  completed: '/completed/',
  enroll: '/enroll/',
  planSummary: '/summary/',
  favorites: '/favorites/',
  personal: '/personal/',
  received: '/received',
  comparison: '/compare/',
  editPreferences: '/preferences/',
  scheduleACall: '/schedule-a-call/',
};

export default paths;
