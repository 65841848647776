'use client';
import { createTheme } from '@mui/material';


export const V3Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 765,
      md: 992,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: '#F0F7FA',
      main: '#1C434F',
    },
    text: {
      primary: '#000',
      secondary: '#4D4D4D',
    },
  },
  components: {
    ResponsiveTable: {
      styleOverrides: {
        root: {
          '& thead td': {
            backgroundColor: '#F7FAFE',
          },
        }
      }
    },
    DrugSelector: {
      styleOverrides: {
        drugCard: {
          backgroundColor: '#F5F5F5',
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAFA',
          padding: '24px',
          borderRadius: '16px',
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 24px',
        },
        content: {
          margin: '26px 0',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0,
          }
        },
        paper: {
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
          borderRadius: '0px 0px 16px 16px',
          marginTop: '4px',
          '& ul': {
            padding: '0',
          },
          '& li': {
            minHeight: 56,
            fontSize: 16,
            fontWeight: 600,
            padding: '16px',
            fontFamily: 'Inter,Roboto,Helvetica Neue,sans-serif',
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: 100,
          boxShadow: 'none',
          backgroundColor: '#F8F6F2',
        }
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          '& .MuiLoadingButton-loadingIndicator': {
            color: 'white'
          }
        }
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 600,
            backgroundColor: '#1C434F',
            borderRadius: '8px',
            color: 'white',
            textTransform: 'none',
            padding: '16px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#33798F',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#33798F',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: '#B3B3B3',
              boxShadow: 'none',
              color: 'white',
            }
          },
        },
        {
          props: { variant: 'contained', color: 'primary', size: 'small' },
          style: {
            padding: '8px 24px',
            fontSize: 14,
            fontWeight: 600,
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            fontSize: 18,
            lineHeight: '21px',
            fontWeight: 700,
            width: 146,
            height: 52,
            backgroundColor: '#03422F',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#03422F',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#03422F',
              boxShadow: 'none',
            },
          }
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'white',
            color: '#1C434F',
            border: '1px solid #1C434F',
            textTransform: 'none',
            padding: '16px',
            borderRadius: '8px',
            fontSize: 18,
            lineHeight: '21px',
            fontWeight: 500,
            '&:hover': {
              color: 'white',
              backgroundColor: '#1C434F',
              border: '1px solid #1C434F',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#33798F',
              border: '1px solid #33798F',
              boxShadow: 'none',
            },
          }
        },
        {
          props: { variant: 'outlined', color: 'primary', size: 'small' },
          style: {
            padding: '8px 24px',
            fontSize: 14,
            fontWeight: 600,
          }
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 18,
            lineHeight: '21px',
            fontWeight: 500,
          }
        },
        {
          props: { variant: 'text', size: 'large'},
          style: {
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 24,
            lineHeight: 1.5,
            fontWeight: 500,
          }
        },
        {
          props: { variant: 'text',  },
          style: {
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 18,
            lineHeight: '21px',
            fontWeight: 500,
          }
        },
        {
          props: { variant: 'text', size: 'small'  },
          style: {
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: 500,
          }
        },
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '32px',
          borderRadius: '16px',
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: '#1C434F',
      }
    },
    MuiContainer: {
      styleOverrides: {
        /*root: ({theme}) => theme.unstable_sx({
          paddingX: {xs: '24px', sm: '40px', md: '72px', xl: 3 }
        })*/
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          borderRadius: '16px',
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#999999'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        /*paper: ({theme}) => theme.unstable_sx({
          backgroundColor: '#FFF',
          minWidth: {xs: '100%', sm: '70%' }
        }),*/
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginTop: 8,
          marginLeft: 0,
          lineHeight: '19px',
          fontSize: 16,
          left: 0,
          right: 0,
          marginRight: 0,
          color: '#D32C1C!important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: 8,
          '&.MuiOutlinedInput-filled:not(.Mui-error)  fieldset.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0B741C!important',
          },
          '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #666666!important',
            borderRadius: '8px',
          },
          '&:not(.Mui-error) .MuiOutlinedInput-input': {
            backgroundColor: 'white',
            borderRadius: '8px',
            '&::placeholder': {
              color: '#808080',
              opacity: 1,
              fontSize: '16px'
            },
          },
          borderRadius: '8px',
          backgroundColor: 'white',
          '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent',
            fontWeight: 400,
          },
        },
        multiline: {
          padding: '11px 7px 10px',
          '& input': {
            padding: 0,
          }
        },
        input: {
          '&:not(.MuiSelect-select)': {
            padding: '16px 16px 15px!important',
          },
          fontSize: '16px!important',
          lineHeight: '20px',
          fontWeight: 400,
          fontFamily: 'Inter,Roboto,Helvetica Neue,sans-serif',
        },
      }
    },
    /*MuiListItemText: {
      styleOverrides: {
        primary: {
          color: 'white',
          fontWeight: 700,
          fontSize: 16
        }
      }
    },*/
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
          borderRadius: '0px 0px 16px 16px',
          marginTop: '4px',
          '& ul': {
            padding: '0',
          },
          '& li': {
            minHeight: 56,
            fontSize: 16,
            fontWeight: 600,
            padding: '16px',
            fontFamily: 'Inter,Roboto,Helvetica Neue,sans-serif',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          width: 24,
          height: 24,
          top: 'calc(50% - 12px)',
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '28px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-input': {
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '5px',
          },
          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-input': {
            borderColor: `#1C434F!important`,
            borderWidth: '1px!important',
            boxShadow: '0px 0px 4px 4px rgba(28, 67, 79, 0.25)',
          },
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: 1536,
          margin: 'auto',
          backgroundColor: '#F8F6F2',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          boxShadow: 'none',
          borderRadius: '0px 0px 16px 16px',
          padding: '48px 80px',
        },
        tooltipArrow: {
          '& :before': {
            color: 'white',
            backgroundColor: 'white',
            border: '1px solid rgba(164,180,185,0.51)',

          }
        }
      }
    }
  },
  typography: {
    fontWeightBold: 700,
    fontFamily: [
      'Inter', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
    body1: {
      fontSize: 16,
      lineHeight: '24px'
    },
    body2: {
      fontSize: 18,
      lineHeight: '24px'
    },
    allVariants: {
      color: '#000',
    }
  }
});

(V3Theme as any).name = 'V3Theme'


V3Theme.typography.h1 = {
  fontSize: 48,
  lineHeight: '64px',
  fontWeight: 400,
  color: '#000',
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [V3Theme.breakpoints.up('sm')]: {
    fontSize: 56,
    lineHeight: '72px',
  },
}

V3Theme.typography.h2 = {
  fontSize: 40,
  lineHeight: '64px',
  fontWeight: 400,
  color: '#000',
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [V3Theme.breakpoints.up('sm')]: {
    fontSize: 48,
    lineHeight: '72px',
  },
}

V3Theme.typography.h3 = {
  fontSize: 24,
  lineHeight: '40px',
  fontWeight: 600,
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
  [V3Theme.breakpoints.up('sm')]: {
    fontSize: 32,
    lineHeight: '48px',
  },
}

V3Theme.typography.h4 = {
  fontSize: 21,
  lineHeight: '22px',
  fontWeight: 600,
  fontFamily: [
    'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'
  ].join(','),
}


