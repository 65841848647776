import clsx from "clsx";
import { createStyles, makeStyles } from '@mui/styles';
import { styled, SxProps } from '@mui/material';
import { Box, IconButton, Theme } from '@mui/material';
import { CaretUp } from '@phosphor-icons/react';
import * as React from 'react';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      background: 'rgba(2, 144, 148, 0.05)',
      marginBottom: 6,
      boxSizing: 'border-box',
    },
    title: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#029094',
      display: "flex",
      alignItems: "center",
    },
    icon: {
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(0) !important',
    },
    button: {
      background: (props: IAscendSubtitleProps) => props.toggleColor ? props.toggleColor + '!important' : 'rgba(2, 144, 148, 0.5)!important',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 10,
      marginTop: 5,
      marginLeft: 5,
      '&:hover': {
        background: (props: IAscendSubtitleProps) => props.toggleColor ? props.toggleColor + '!important' : 'rgba(2, 144, 148, 0.5)!important',
      }
    },
    img: {
      minWidth: 32,
      height: 32,
    }
  })
);

export const CRSubtitle = (props: IAscendSubtitleProps) => {
  const classes = styles(props);
  const {title, icon, expanded, onToggleClick, wrapperClassName, titleClassName, sx} = props;
  return (

    <Box p={'5px 30px'}
         className={clsx(classes.wrapper, wrapperClassName)}
         display={"flex"}
         flexDirection={"row"}
         alignItems={"center"}
         justifyContent={"space-between"}
         width={1}
         sx={{cursor: typeof expanded !== 'undefined' ? 'pointer' : 'inherit', ...sx}}
         onClick={() => (typeof expanded !== 'undefined' && onToggleClick) && onToggleClick(!expanded)}
    >

      <Box display={'flex'}
           alignItems={"center"}
           justifyContent={"start"}>
        {icon &&
          <Box mr={'16px'}><img className={classes.img} src={icon}/></Box>
        }
        <Box className={clsx(classes.title, titleClassName)}>{title}</Box>
      </Box>
      {typeof expanded !== 'undefined' && <ToggleButton>
        {!props.arrowIcon && <CaretUp size={12} color="#FFFFFF" weight="bold" className={clsx(classes.icon, expanded && classes.iconOpen)} />}
        {props.arrowIcon && <img className={clsx(classes.icon, expanded && classes.iconOpen)} src={props.arrowIcon} />}
      </ToggleButton>}
    </Box>

  )
}

const ToggleButton = styled(IconButton)({
  background: 'rgba(2, 144, 148, 0.5)!important',
  width: 30,
  maxWidth: 30,
  height: 30,
  padding: 10,
  marginTop: 5,
  marginLeft: 5,
  '&:hover': {
    background: 'rgba(2, 144, 148, 0.5)!important',
  }
})


interface IAscendSubtitleProps {
  wrapperClassName?: string,
  titleClassName?: string,
  title: string | JSX.Element;
  icon?: string;
  sx?: SxProps;
  toggleColor?: string;
  arrowIcon?: string;
  expanded?: boolean;
  onToggleClick?: (value: boolean) => void;
}

