import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {ApolloError, gql} from "@apollo/client";
import { PageablePlanOutput, QueryPlansArgs } from '@coverright/data-access/types/medicare';

export function useMedicarePlans(options?: LazyQueryHookOptions<IPlansOutput, QueryPlansArgs>) {

  return useLazyQuery<IPlansOutput, QueryPlansArgs>(gql(getPlans), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

export function useMedicarePlansTotal(options?: LazyQueryHookOptions<IPlansTotalOutput, QueryPlansArgs>) {
  return useLazyQuery<IPlansTotalOutput, QueryPlansArgs>(gql(getPlansTotal), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

interface IPlansOutput {
  plans: PageablePlanOutput
}

interface IPlansTotalOutput {
  plans: {totalElements: number}
}

const getPlans = `
query($filter: PlansFilterInput!, $page: PageInput!, $fullYear: Boolean, $sort: [PlansSortInput!]!, $quoteId: UUID) {
    plans(filter: $filter, page: $page, quoteId: $quoteId, fullYear: $fullYear, sort: $sort) {
      data  {
        nonCommissionable
        SNPType
        monthlyPremium
        planName
        planType
        bidId
        ratingNote
        orgName
        parentOrgName
        outOfPocketMaximum
        primaryCarePhysicianShort
        physicianSpecialistShort
        optionalSupplementalBenefits
        rating
        partBAmount
        partBGiveBack
        missingFormularyInAep
        outOfPocketAmount
        maxEnrollmentAmount
        inNetworkDeductableAmount
        firstWeekOfStay
        planYear
        drugCostMonthlyBreakdown {
          totalClientCost
          costs {
            name
            month
            cost
            rxcui
            stage
          }
          coverageEntry {
            stage
            month
          }
          monthlyCosts {
            amount
            amountString
            month
            stage
          }
        }
        drugPrices {
          catastrophicPrice
          coverageGapPrice
          initialCoveragePrice
          name
          price
          unitPrice
          rxcui
          tier
          quoteQuantity
          isCoveredByPlan
          packageDescription
          quantityLimit
          isAggregatedPrice
          isGeneric
          isInsulinDrug
        }
        drugDetails {
          mrxAltDedAmount
          mrxAltDedAmountShort
        }
        drugsCoverage{
          rxcui
          coverage
          name
          tier
        }
        doctorsCost
        drugsCost
        doctorsCoverage {
          npi
          coverage
          name
        }
        monthlyCost
        extraBenefits {
          drugCoverage
          dental
          vision
          hearing
          transportation
          fitness
          telehealth
          worldwideEmergency
          insulinSavings
          overTheCounter
        }
      }
      doctorsCheckBoxMessage
      drugsCheckBoxMessage
      totalElements
      number
      totalPages
      hasPrevious
      hasNext
    }
}
`;

const getPlansTotal = `
query($filter: PlansFilterInput!, $page: PageInput!, $fullYear: Boolean, $sort: [PlansSortInput!]!, $quoteId: UUID) {
    plans(filter: $filter, page: $page, quoteId: $quoteId, fullYear: $fullYear, sort: $sort) {
      totalElements
    }
}
`;
