import React from "react";
import { useNavigate } from "react-router";
import {
  Badge,
  Box,
  MenuItem,
  Typography
} from '@mui/material';
import { SelectInput } from '@coverright/ui/inputs';
import paths from '../../../../config/router-paths';
import { Field } from '@coverright/data-access/types/medicare';
import { areComponentPropsEqual } from '@coverright/utils';


interface PlanListSortProps {
  onChange: (value: Field) => void,
  value: Field,
  zip?: string,
  county?: string,
  favoritesCount: number
}

const PlanListSort = (props: PlanListSortProps) => {
  const navigate = useNavigate();

  return <>
    <Box display={'flex'} alignItems={{xs: 'flex-start', sm: 'center'}} sx={{flexDirection: {xs: 'column', sm: 'row'}}} justifyContent={'space-between'} mb={'16px'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16'}>Sort by:</Typography>
        <Box ml={'8px'}>
          <SelectInput hideTick sx={{minWidth: 220}} className={'mb-0'} value={props.value} onChange={e => props.onChange(e.target.value as Field)} >
            <MenuItem value={Field.MonthlyCost}>Estimated Lowest Cost</MenuItem>
            <MenuItem value={Field.DoctorsCoverage}>Doctors Coverage</MenuItem>
            <MenuItem value={Field.Rating}>CMS star rating</MenuItem>
          </SelectInput>
        </Box>
      </Box>
      <Box mt={{xs: 2, sm: 0}} className={'flex flex-align-center pointer'} onClick={() => navigate(paths.favorites + props.zip + '/' + props.county)}>
        <Typography className={'fs-12 medium'} color={'textPrimary'}>See your favorites</Typography>
        <div className={'w-5'} />
        <Badge color="primary" overlap={'circular'} badgeContent={props.favoritesCount} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
          <img width={30} height={30} src={'/assets/img/heart.svg'}/>
        </Badge>
      </Box>
    </Box>
  </>;
}

export default React.memo(PlanListSort, areComponentPropsEqual);
