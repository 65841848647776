import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { QueryGenerateEhealthPlanLinkArgs } from '@coverright/data-access/types/medicare';

export function useGetEHealthLinkRequest(options?: LazyQueryHookOptions<IEHealthResponse, QueryGenerateEhealthPlanLinkArgs>) {
  return useLazyQuery<IEHealthResponse, QueryGenerateEhealthPlanLinkArgs>(
    gql(eHealthQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  )
}

export interface IEHealthResponse {
  generateEhealthPlanLink: string
}


const eHealthQuery = `
query($bidId: String!, $countyName: String!, $zip: String!, $planYear: PlanYear) {
  generateEhealthPlanLink(bidId: $bidId, countyName: $countyName, zip: $zip, planYear: $planYear)
}
`
