import React from 'react';
import { Box, Typography } from '@mui/material';
import { UpdateZipModal } from './update-zip-modal';
import { PencilSimpleLine } from '@phosphor-icons/react/dist/ssr/PencilSimpleLine';

interface Props {
  variant?: 'full' | 'compact';
  loading?: boolean;
  zip?: string | null;
  county?: string | null;
  state?: string | null;
  onChange: (zip: string, county: string, state: string) => void;
  title?: string;
  hideIcon?: boolean;
}

export function UpdateZip({
  loading,
  zip,
  title,
  state,
  onChange,
  county,
  variant,
  hideIcon,
}: Props) {
  const [updateZipModalOpen, setUpdateZipModalOpen] = React.useState(false);

  const onUpdateZipModalClose = (
    newZip?: string,
    newCounty?: string,
    newState?: string
  ) => {
    setUpdateZipModalOpen(false);
    setTimeout(() => {
      if (newZip && newCounty && newState && (newCounty !== county || newZip !== zip)) {
        onChange(newZip, newCounty, newState);
      }
    })
  };

  return (
    <>
      {updateZipModalOpen && (
        <UpdateZipModal
          variant={variant}
          open={updateZipModalOpen}
          onClose={onUpdateZipModalClose}
          zip={zip}
          county={county}
          state={state}
        />
      )}

      {loading && (
        <Typography className={'epilogue fs-24 lh-40 semi-bold'}>
          {' '}
          Loading...{' '}
        </Typography>
      )}
      {!loading && (
          <Typography>
            {title || 'Showing plans for:'}
            <Box
              component={'span'}
              sx={{ml: 1, cursor: 'pointer', textDecoration: 'underline', display: 'inline-flex', alignItems: 'center', gap: .5}}
              ml={1}
              onClick={() => setUpdateZipModalOpen(true)}
            >
              {zip} {county} County{state ? `, ${state}` : ''}
              {!hideIcon && <PencilSimpleLine size={20} color={'#1C434F'} />}
            </Box>
          </Typography>

      )}
    </>
  );
}
