
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { MedigapPlanOutput } from '@coverright/data-access/types/medigap';

const deductibles: {[key: number]: any} = {
  2022: {
    inpatientHospitalDeductible: '$1,556',
    dailyCoinsurance61_90: '$389',
    lifetimeReserveDays: '$778',
    skilledNursingFacility: '$194.50',
    annualPartBDeductible: '$233',
    deductible: '$2,490',
  },
  2023: {
    inpatientHospitalDeductible: '$1,600',
    dailyCoinsurance61_90: '$400',
    lifetimeReserveDays: '$800',
    skilledNursingFacility: '$200.00',
    annualPartBDeductible: '$226',
    deductible: '$2,700',
  },
  2024: {
    inpatientHospitalDeductible: '$1,632',
    dailyCoinsurance61_90: '$408',
    lifetimeReserveDays: '$816',
    skilledNursingFacility: '$200.00',
    annualPartBDeductible: '$240',
    deductible: '$2,800',
  },
  2025: {
    inpatientHospitalDeductible: '$1,632',
    dailyCoinsurance61_90: '$408',
    lifetimeReserveDays: '$816',
    skilledNursingFacility: '$200.00',
    annualPartBDeductible: '$240',
    deductible: '$2,800',
  },
}

const getDeductible = (key: any) => {
  const year = new Date().getFullYear();
  const dataSet = deductibles[year + 1] ? deductibles[year + 1] : deductibles[year];
  return dataSet[key];
}

export function addDetailsToMGPlanList(plans: MedigapPlanOutput[]): MedigapPlanOutputWithDetails[] {
  return plans.filter(plan => !!plan?.planName).map(addDetailsToMGPlan) as MedigapPlanOutputWithDetails[];
}

export function addDetailsToMGPlan(plan?: MedigapPlanOutput): MedigapPlanOutputWithDetails | undefined {
  return (plan && plan.planName) ? {...plan, ...MedigapPlanData[plan.planName as string]} : undefined;
}

export type MedigapPlanOutputWithDetails = MedigapPlanOutput & IMedigapPlanDetails;

const OMValue = (props: {title: string | JSX.Element, value: string, subtitle?: string | JSX.Element, subtitleClassName?: string}) => (
  <div>
    <Typography sx={{fontSize: 14, fontWeight: 500, lineHeight: '17px', mb: .5}} className={'omvalue-title dark-green'}>{props.title}</Typography>
    <OMVal value={props.value} subtitle={props.subtitle} subtitleClassName={props.subtitleClassName} skipMt/>
  </div>
)

const OMVal = (props: {value: string | JSX.Element, subtitle?: string | JSX.Element, subtitleClassName?: string, skipMt?: boolean}) => (
  <Box mt={props.skipMt ? undefined : '22px'} className={'omval'}>
    <Typography sx={{fontSize: 16, fontWeight: 500, lineHeight: '20px'}} className={'omval-value'}>{props.value}</Typography>
    {props.subtitle && <Typography sx={{fontSize: 13, lineHeight: '16px'}} className={'omval-subtitle ' + (props.subtitleClassName || '')}>{props.subtitle}</Typography>}
  </Box>
)

export interface IMedigapPlanData {
  [name: string]: IMedigapPlanDetails
}

export interface IMedigapPlanDetails {
  name: string,
  note?: string,
  popularity: string,
  premium?: string,
  highDeductibleOption?: boolean,
  partADeductible: string,
  partACoinsurance: string,
  hospiceCareCopay: string | boolean,
  partBDeductible: string | boolean,
  partBCoinsurance: string | JSX.Element,
  partBServices: string | JSX.Element,
  blood: string | boolean,


  doctorAndOutpatientServices: string | JSX.Element,
  shortTermHospitalDay: string | JSX.Element,
  longTermHospitalDay: string | JSX.Element,

  deductible: string | JSX.Element,
  skilledNursingFacility: string | JSX.Element,
  partBExcessCharges: boolean | JSX.Element,
  foreignTravelEmergency: string | boolean | JSX.Element,

  outOfPocketLimits: string | JSX.Element,
  inpatientHospitalCoverage: string | JSX.Element,
  skilledNursingFacilities: string | JSX.Element,
  bloodTransfusionDuring: string | JSX.Element,
  coveredPartBServices: string,
  preventiveCare: string,
  bloodTransfusionOutside: string | JSX.Element,
  testsForDiagnosticServices: string,
  homeHealthCare: string,
  durableMedicalEquipment: string,
  deductibles: {
    partA: string,
    partB: string,
  },
  features: {
    partADeductible: boolean,
    partACopay: boolean,
    partBDeductible: boolean,
    partBExcessCharges: boolean,
    skilledNursingFacility: boolean,
    foreignTravelEmergency: boolean,
  }
}

export const MedigapPlanData: IMedigapPlanData = {
  'C': {
    name: 'C',
    note: 'Not available if you\'re\neligible for Medicare\nafter Jan 1st, 2020',
    popularity: '5%',
    highDeductibleOption: true,

    doctorAndOutpatientServices: <OMVal value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMVal value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={'$0'} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: true,
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for hospital and medical services</>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0',
    deductibles: {
      partA: '$0',
      partB: '$0'
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: true,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'D': {
    name: 'D',
    popularity: '1%',
    highDeductibleOption: false,


    doctorAndOutpatientServices: <OMVal value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMVal value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'F': {
    name: 'F',
    note: 'Not available if you\'re\neligible for Medicare\nafter Jan 1st, 2020',
    popularity: '49%',
    highDeductibleOption: true,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0'} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: '$0',
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services</>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0',
    deductibles: {
      partA: '$0',
      partB: '$0'
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: true,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'G': {
    name: 'G',
    popularity: '22%',
    highDeductibleOption: true,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'A': {
    name: 'A',
    popularity: '1%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('inpatientHospitalDeductible') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={getDeductible('skilledNursingFacility') + ' per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('inpatientHospitalDeductible'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for medical services
      <br /><span className={'text-v3 fs-12 lh-11'}>after deductible</span>
      <br /><span className={'text-v3 fs-12 lh-11'}>You pay 100% of standard (Part A) deductible for short term hospital stays</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to {getDeductible('skilledNursingFacility')} a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: getDeductible('inpatientHospitalDeductible'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: false,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false
    }
  },
  'B': {
    name: 'B',
    popularity: '2%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={getDeductible('skilledNursingFacility') + ' per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to {getDeductible('skilledNursingFacility')} a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false
    }
  },
  'K': {
    name: 'K',
    popularity: '1%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMVal value={'10% coinsurance'}
                                        subtitle={'Your plan pays 50% of Original Medicare cost'}/>,
    shortTermHospitalDay: <OMVal value={getDeductible('lifetimeReserveDays') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$92.75 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('lifetimeReserveDays'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: 'Your plan pays\n50% of costs',
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: 'Your plan pays\n50% of costs',
    partBServices: <><strong>10% generally</strong> for medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
      <div className={'h-4'}/>
      <span className={'fs-12 lh-114 mb-10 text-v3'}>You pay 50% of standard (Part A) deductible for short term hospital stays.  Once you reach $6,220 in out-of-pocket costs, you pay $0.</span>
    </>,
    blood: 'Your plan pays\n50% of costs',
    outOfPocketLimits: '$6,220',
    inpatientHospitalCoverage: <div>
      <div><span>{getDeductible('lifetimeReserveDays')} for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to $92.75 a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints of blood: 50%</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: 50%</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then generally 10%</span></div>
    </div>,
    testsForDiagnosticServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: getDeductible('lifetimeReserveDays'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false
    }
  },
  'L': {
    name: 'L',
    popularity: '< 0.5%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMVal value={'5% coinsurance'}
                                        subtitle={'Your plan pays 75% of Original Medicare cost'}/>,
    shortTermHospitalDay: <OMVal value={getDeductible('dailyCoinsurance61_90') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$46.38 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('dailyCoinsurance61_90'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: 'Your plan pays\n75% of costs',
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: 'Your plan pays\n75% of costs',
    partBServices: <><strong>5% generally</strong> for medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
      <div className={'h-4'}/>
      <span className={'text-v3 fs-12 lh-12'}>You pay 25% of standard (Part A) deductible for short term hospital stays.  Once you reach $3,310 in out-of-pocket costs, you pay $0.</span>
    </>,
    blood: 'Your plan pays\n75% of costs',
    outOfPocketLimits: '$3,110',
    inpatientHospitalCoverage: <div>
      <div><span>{getDeductible('dailyCoinsurance61_90')} for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to $46.38 a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: 25%</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: 25%</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then generally 5%</span></div>
    </div>,
    testsForDiagnosticServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: getDeductible('dailyCoinsurance61_90'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false
    }
  },
  'M': {
    name: 'M',
    popularity: '< 0.5%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMVal value={'$0 coinsurance'} subtitle={'Your plan pays 100% of Original Medicare cost'}/>,
    shortTermHospitalDay: <OMVal value={getDeductible('dailyCoinsurance61_90') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('dailyCoinsurance61_90'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '0%',
    partBServices: <><strong>$0 generally</strong> for medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
      <br /><span className={'text-v3 fs-12 lh-12'}>You pay 25% of standard (Part A) deductible for short term hospital stays.</span>
    </>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>50% after Part A deductible for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: getDeductible('dailyCoinsurance61_90'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'N': {
    name: 'N',
    popularity: '10%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/yellow-check.svg'} />} value={'$20 / $50 copay'}
                                        subtitle={'for doctor / emergency room visits. $0 for everything else'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: <div className={'text-align-centered fs-14 text-primary'}>
      Your plan <br/> typically pays 100% <br/><span
      className="fs-12">Some services<br/>have a $20 or<br/>$50 copay</span>
    </div>,
    partBServices: <><strong>$20 / $50 copay</strong> for doctor / emergency room visits. <strong>$0 generally</strong> for all other hospital/medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
    </>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: 'Up to $20 for doctor visits and up to $50 for emergency care visits. You don\'t have to pay the $50 emergency care copay if you are admitted with the first 24 hrs. $0 otherwise',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'HDF': {
    name: 'HDF',
    popularity: 'Not available',

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance after deductible'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day after deductible'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('deductible')} subtitle={'You pay 100% of costs up to this amount'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered after deductible'}/>,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost after deductible'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day after deductible'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('deductible') + ' for both Part A and B',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('deductible') + ' for both Part A and B',
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after annual deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 after Plan deductible for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 after Plan deductible for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Plan deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Plan deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Plan deductible',
    deductibles: {
      partA: getDeductible('deductible'),
      partB: ''
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: true,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'HDG': {
    name: 'HDG',
    popularity: 'Not available',

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance after deductible'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day after deductible'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('deductible')} subtitle={'You pay 100% of costs up to this amount'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered after deductible'}/>,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost after deductible'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day after deductible'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('deductible') + ' for both Part A and B',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('deductible') + ' for both Part A and B',
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after annual deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 after Plan deductible for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 after Plan deductible for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Plan deductible if Part B deductible has been met',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0 after Part B deductible</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Plan deductible if Part B deductible has been met',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Plan deductible if Part B deductible has been met',
    deductibles: {
      partA: getDeductible('deductible'),
      partB: ''
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'MA_CORE': {
    name: 'Core',
    popularity: '2%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={getDeductible('skilledNursingFacility') + ' per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to {getDeductible('skilledNursingFacility')} a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false
    }
  },
  'MA_SUPP1': {
    name: 'Supplement 1',
    note: 'Not available if you\'re\neligible for Medicare\nafter Jan 1st, 2020',
    popularity: '49%',
    highDeductibleOption: true,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0'} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: '$0',
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services</>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0',
    deductibles: {
      partA: '$0',
      partB: '$0'
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: true,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'MA_SUPP1A': {
    name: 'Supplement 1A',
    popularity: '22%',
    highDeductibleOption: true,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: '$0',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: '$0',
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
  'WI_75%': {
    name: 'WI_75%',
    popularity: '< 0.5%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMVal value={'5% coinsurance'}
                                        subtitle={'Your plan pays 75% of Original Medicare cost'}/>,
    shortTermHospitalDay: <OMVal value={getDeductible('dailyCoinsurance61_90') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$46.38 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('dailyCoinsurance61_90'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: 'Your plan pays\n75% of costs',
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: 'Your plan pays\n75% of costs',
    partBServices: <><strong>5% generally</strong> for medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
      <span className={'h-4'}/>
      <span className={'text-v3 fs-12 lh-12'}>You pay 25% of standard (Part A) deductible for short term hospital stays.  Once you reach $3,310 in out-of-pocket costs, you pay $0.</span>
    </>,
    blood: 'Your plan pays\n75% of costs',
    outOfPocketLimits: '$3,110',
    inpatientHospitalCoverage: <div>
      <div><span>{getDeductible('dailyCoinsurance61_90')} for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to $46.38 a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: 25%</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: 25%</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then generally 5%</span></div>
    </div>,
    testsForDiagnosticServices: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 5% (25% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: getDeductible('dailyCoinsurance61_90'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false
    }
  },
  'WI_50%': {
    name: 'WI_50%',
    popularity: '1%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMVal value={'10% coinsurance'}
                                        subtitle={'Your plan pays 50% of Original Medicare cost'}/>,
    shortTermHospitalDay: <OMVal value={getDeductible('lifetimeReserveDays') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMVal value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMVal value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: false,
    foreignTravelEmergency: <OMVal value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMVal value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <div className={'h-25'}/>
      <OMVal value={'$92.75 per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('lifetimeReserveDays'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: 'Your plan pays\n50% of costs',
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: 'Your plan pays\n50% of costs',
    partBServices: <><strong>10% generally</strong> for medical services
      <br /><span className={'text-v3 fs-12'}>after deductible</span>
      <span className={'h-4'}/>
      <span className={'fs-12 lh-12 mb-10 text-v3'}>You pay 50% of standard (Part A) deductible for short term hospital stays.  Once you reach $6,220 in out-of-pocket costs, you pay $0.</span>
    </>,
    blood: 'Your plan pays\n50% of costs',
    outOfPocketLimits: '$6,220',
    inpatientHospitalCoverage: <div>
      <div><span>{getDeductible('lifetimeReserveDays')} for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to $92.75 a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints of blood: 50%</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: 50%</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then generally 10%</span></div>
    </div>,
    testsForDiagnosticServices: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: 'Generally 10% (50% of the 20% coinsurance) after Part B deductible',
    deductibles: {
      partA: getDeductible('lifetimeReserveDays'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: true,
      foreignTravelEmergency: false
    }
  },
  'WI_BASE': {
    name: 'WI_BASE',
    popularity: '1%',
    highDeductibleOption: false,

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('inpatientHospitalDeductible') + ' copay'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={'Not covered'} />,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay'} subtitle={'Days 1 - 20'}/>
      <OMVal value={getDeductible('skilledNursingFacility') + ' per day'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('inpatientHospitalDeductible'),
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('annualPartBDeductible'),
    partBCoinsurance: '$0',
    partBServices: <><strong>$0 generally</strong> for medical services
      <br /><span className={'text-v3 fs-12 lh-11'}>after deductible</span>
      <br /><span className={'text-v3 fs-12 lh-11'}>You pay 100% of standard (Part A) deductible for short term hospital stays</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 for days 1-100;</span></div>
      <div><span>Up to {getDeductible('skilledNursingFacility')} a day for days 21-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Part B deductible',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: {getDeductible('annualPartBDeductible')} (Part B deductible), then $0</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Part B deductible',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Part B deductible',
    deductibles: {
      partA: getDeductible('inpatientHospitalDeductible'),
      partB: getDeductible('annualPartBDeductible')
    },
    features: {
      partADeductible: false,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: false,
      skilledNursingFacility: false,
      foreignTravelEmergency: false
    }
  },
  'WI_HDED': {
    name: 'WI_HDED',
    popularity: 'Not available',

    doctorAndOutpatientServices: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 coinsurance after deductible'}/>,
    shortTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'covers first 60 days'}/>,
    longTermHospitalDay: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 per day after deductible'} subtitle={'For days 61-90'}/>,

    deductible: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/red-close-circle.svg'} />} value={getDeductible('deductible')} subtitle={'You pay 100% of costs up to this amount'}/>,
    partBExcessCharges: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covered after deductible'}/>,
    foreignTravelEmergency: <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'Covers 80% of cost after deductible'} subtitle={'Up to plan limit'}/>,
    skilledNursingFacility: <div>
      <OMValue title={<img className={'w-15 h-15'} src={'/assets/img/green-circle-check.svg'} />} value={'$0 copay after deductible'} subtitle={'Days 1 - 20'}/>
      <OMVal value={'$0 per day after deductible'} subtitle={'Days 21 - 100'}/>
    </div>,

    partADeductible: getDeductible('deductible') + ' for both Part A and B',
    partACoinsurance: 'Up to an additional 365\ndays after Medicare\nbenefits are used up',
    hospiceCareCopay: true,
    partBDeductible: getDeductible('deductible') + ' for both Part A and B',
    partBCoinsurance: '$0',
    partBServices: <><strong>$0</strong> for hospital and medical services <br /><span className={'text-v3 fs-12'}>after annual deductible</span></>,
    blood: true,
    outOfPocketLimits: 'Not applicable',
    inpatientHospitalCoverage: <div>
      <div><span>$0 after Plan deductible for Days 1-60;</span></div>
      <div><span>$0 for Days 61-90;</span></div>
      <div><span>$0 for Days 91-150 (Lifetime Reserve);</span></div>
      <div><span>$0 for Additional 365 days after reserve;</span></div>
      <div><span>You pay all costs beyond the additional 365</span></div>
    </div>,
    skilledNursingFacilities: <div>
      <div><span>$0 after Plan deductible for days 1-100;</span></div>
      <div><span>all costs for the 101st day and after.</span></div>
    </div>,
    bloodTransfusionDuring: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints of blood: $0</span></div>
      <div><span>After 3 pints of blood: $0</span></div>
    </div>,
    coveredPartBServices: '$0 after Plan deductible if Part B deductible has been met',
    preventiveCare: '$0 Generally for most preventive services Medicare covers',
    bloodTransfusionOutside: <div>
      After you pay the Plan deductible, you pay:
      <div><span>First 3 pints: $0</span></div>
      <div><span>After 3 pints: $0 after Part B deductible</span></div>
    </div>,
    testsForDiagnosticServices: '$0 after Plan deductible if Part B deductible has been met',
    homeHealthCare: '$0',
    durableMedicalEquipment: '$0 after Plan deductible if Part B deductible has been met',
    deductibles: {
      partA: getDeductible('deductible'),
      partB: ''
    },
    features: {
      partADeductible: true,
      partACopay: true,
      partBDeductible: false,
      partBExcessCharges: true,
      skilledNursingFacility: true,
      foreignTravelEmergency: true
    }
  },
};
