import { Box } from '@mui/material';
import * as React from 'react';
import { ZipSelectorProps } from './lib/types';
import { ZipInput } from './ui/zip-input';
import { CountySelect } from './ui/county-select';
import { CountyLabel } from './ui/county-label';
import { useEffect, useState } from 'react';
import {
  ICityResponse,
  useZipCountyFipsLazy,
} from '@coverright/data-access/medicare';
import { getState } from './lib';

export function ZipSelector(props: ZipSelectorProps) {
  const [zip, setZip] = useState(props.zip || '');

  const onCountiesRequestCompleted = (data: ICityResponse) => {
    const counties = data.plans_zip_county_fips || [];
    if (counties.length === 1) {
      props.onChange({
        zip,
        countyName: counties[0].countyname,
        state: counties[0].state,
      });
    }
  };

  const [getCounties, { loading, called, data }] = useZipCountyFipsLazy({
    onCompleted: onCountiesRequestCompleted,
  });

  const counties = zip.length === 5 ? data?.plans_zip_county_fips || [] : [];

  const error =
    zip.length > 0 && !loading && called && !counties.length
      ? "We can't find your county"
      : undefined;

  useEffect(() => {
    if (props.zip?.length === 5) {
      setZip(props.zip);
      getCounties({ variables: { zip: props.zip } });
    }
  }, [props.zip]);

  //----- input handlers -----------

  const handleZipChange = (value: string) => {
    if (zip.length === 5 && value.length !== 5) {
      props.onChange({ zip: '', countyName: '', state: '' });
    }
    setZip(value);
    if (value.length === 5) {
      getCounties({ variables: { zip: value } });
    }
  };

  const handleCountySelect = (countyName: string) => {
    props.onChange({ zip, countyName, state: getState(countyName, counties) });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: props.direction || 'column',
          gap: 2,
        }}
      >
        <ZipInput
          onChange={handleZipChange}
          value={zip}
          autoFocus={props.autoFocus}
          error={error || props.errors?.zip}
          label={props.label}
          loading={loading}
          onFocus={props.onFocus}
          onClick={props.onClick}
        />
        <>
          {counties.length === 1 && (
            <CountyLabel
              showLabel={props.direction === 'row' && counties.length <= 1}
              countyname={counties[0].countyname}
              state={counties[0].state}
            />
          )}
          {counties.length > 1 && (
            <CountySelect
              defaultValue={props.countyName || ''}
              onChange={handleCountySelect}
              loading={loading}
              error={props.errors?.countyName}
              disabled={counties.length < 2 || loading}
              options={counties}
            />
          )}
        </>
      </Box>
    </Box>
  );
}
