import React from "react";
import { Collapse, Container } from '@mui/material';

interface OptionalCollapseProps {
  inCollapse: boolean;
  in?: boolean;
}

const OptionalCollapse = React.memo((props: React.PropsWithChildren<OptionalCollapseProps>) => {
  if (props.inCollapse) {
    return <Collapse in={props.in}>
      {props.children}
    </Collapse>
  }
  return <Container maxWidth={'lg'}>{props.children}</Container>
})

export default OptionalCollapse;
