import { useFindProviderAddressesByNpiQuery } from '@coverright/data-access/medicare';
import { ProviderAddressOutput } from '@coverright/data-access/types/medicare';

export function useAddresses(npi: string, addressesOverride: ProviderAddressOutput[]) {
  const { data } = useFindProviderAddressesByNpiQuery({
    skip: !npi || !!addressesOverride.length,
    variables: { npi },
  });

  return addressesOverride.length ? addressesOverride : data?.findProviderAddressesByNpi || [];
}
