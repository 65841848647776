import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  Button,
  Slide,
  Theme,
  Typography,
  useMediaQuery,
  useTheme, IconButton, styled
} from '@mui/material';
import { useNavigate } from "react-router";
import * as _ from 'lodash';
import { createStyles, makeStyles } from '@mui/styles';
import { ComparisonContext } from '../../../ComparisonContext';
import { colors } from '@coverright/ui/themes';
import { CRModal } from '@coverright/ui/shared';
import { GetPlansYear, getQuoteId, useWindowSize } from '@coverright/utils';
import paths from '../../../../config/router-paths';
import ComparisonCard from './ComparisonCard';
import { PlanWithTiers, useMedicarePlansByBid } from '@coverright/data-access/medicare';
import { QuoteContext } from '@coverright/shared/contexts';
import { PlanFilterContext } from '../../plan-list/components/filters/PlanFilterContext';

type ComparisonPanelProps = {
  zip: string,
  countyName: string,
  forceHide?: boolean
}
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '7px 30px 13px',
      background: '#F5F7F6',
      boxShadow: '0px -10px 30px rgba(0, 0, 0, 0.1)'
    },
    buttonWrapper: {
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 7,
      width: '100%',
      background: colors.custom.green.variant2,
      zIndex: 2000,
    },
    button: {
      width: 378
    },
    icon: {
      position: 'absolute!important' as any,
      top: 0,
      right: 0,
      padding: 8,
    },
  }),
);

export default function ComparisonPanel(props: ComparisonPanelProps) {
  const classes = styles();
  const navigate = useNavigate();
  const size = useWindowSize();
  const {maPlans: {count, plans} } = React.useContext(ComparisonContext);

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [showPanel, setShowPanel] = React.useState(true);
  const ref = React.useRef(null);

  const [plansOpen, setPlansOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [left, setLeft] = React.useState<number>(0);

  const panelToggle = () => setShowPanel(!showPanel);

  const open = React.useMemo(() => {
    return !!count && !props.forceHide;
  }, [count])



  const toLeft = () => {
    setLeft(0);
  };

  const offset = React.useMemo(() => {
    if (ref.current) {
      const width = (ref.current as any).clientWidth;
      const cardsWidth = plans.length * 202 + ((plans.length - 1) * 8);
      return width - cardsWidth - 30;
    } else {
      return 0
    }
  }, [size, plans, ref]);

  const toRight = React.useCallback(() => {
      setLeft(offset);
  }, [ref, offset]);

  const goToCompare = React.useCallback(() => {
    if (plans?.length > 1) {
      navigate(paths.comparison + props.zip + '/' + props.countyName)
    } else {
      setModalOpen(true)
    }
  }, [plans?.length])

  return <>
    <CRModal width={330} paperPadding={'40px 32px'} open={modalOpen} onClose={() => setModalOpen(false)}>
      <>
        <IconButton onClick={() => setModalOpen(false)} className={classes.icon}><img src={'/assets/img/close.svg'} /></IconButton>
        <Typography className={'fs-26 lh-32 mb-24 medium'} align={'center'}>Select at least one more plan in order to compare.</Typography>
        <Button variant={'contained'}
                fullWidth
                onClick={() => setModalOpen(false)}>Ok</Button>
        <Button variant={'outlined'}
                sx={{mt: 1}}
                onClick={() => navigate(paths.planDetails + props.zip + '/' + props.countyName + '/' + plans[0].bidId)}>See plan details</Button>
      </>
    </CRModal>
    {(!showPanel && open) && <div className={classes.buttonWrapper} onClick={panelToggle}>
      <Typography className={'fs-18 underline bold white mr-10'}>Show plans selected for comparison</Typography>
      <img src={'/assets/img/arrow-white.svg'} />
    </div>}
    <Slide in={showPanel && open} direction={'up'} style={{position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1200, boxSizing: 'border-box'}}>
        <Box className={classes.container}>
          {!phone && <>
            <Box sx={{
              position: 'relative',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <div>
                <Typography className={'bold fs-16 mr-24 lh-20'} color={'textPrimary'}>Select up to 6 plans to<br />compare at one time</Typography>
              </div>

              <StyledButton sx={{
                transform: 'rotate(-90deg)',
                pointerEvents: left !== 0 ? undefined : 'none',
                opacity: left !== 0 ? 1 : 0
              }} onClick={toLeft}>
                <img className={'w-10 h-10'} src={'/assets/img/arrow-white.svg'} />
              </StyledButton>

              <Box ref={ref} sx={{
                mx: '10px',
                height: 89,
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
                maxWidth: 832,
              }}>

                <Box sx={{
                  boxShadow: '0 0 30px 30px #f5f7f6',
                  right: 0,
                  position: 'absolute',
                  height: '89px',
                  width: '0px',
                  background: '#f5f7f6',
                  zIndex: 1,
                  transition: "all 0.3s",
                  opacity: left !== 0 ? 0 : 1
                }}/>

                <Box sx={{
                  boxShadow: '0 0 30px 30px #f5f7f6',
                  left: 0,
                  position: 'absolute',
                  height: '89px',
                  width: '0px',
                  background: '#f5f7f6',
                  zIndex: 1,
                  transition: "all 0.3s",
                  opacity: left === 0 ? 0 : 1
                }}/>

                <Box sx={{
                  pt: '6px',
                  left,
                  display: 'flex',
                  gap: 1,
                  position: 'absolute',
                  transition: "all 0.3s",
                }}>
                  {plans.map((plan, index) => <ComparisonCard key={index} plan={plan} />)}
                </Box>
              </Box>

              <StyledButton sx={{
                transform: 'rotate(90deg)',
                pointerEvents: left === 0 && offset < 0 ? undefined : 'none',
                opacity: left === 0 && offset < 0 ? 1 : 0
              }} onClick={toRight}>
                <img className={'w-10 h-10'} src={'/assets/img/arrow-white.svg'} />
              </StyledButton>

              <Box ml={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Button className={'ph-30'}
                        variant={'contained'}
                        onClick={goToCompare}>Compare {count} Plan{count > 1 ? 's' : ''}</Button>
                <Typography sx={{color: colors.custom.green.variant2}} className={'fs-14 pointer underline mt-5'} onClick={panelToggle}>Minimize</Typography>
              </Box>
            </Box>
          </>}
          {(phone && !plansOpen) && <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box display={'flex'} gap={'12px'} width={'100%'}>
              <Button variant={'outlined'}
                      sx={{flex: 1}}
                      onClick={() => setPlansOpen(true)}>View</Button>
              <Button sx={{flex: 1}}
                      variant={'contained'}
                      onClick={goToCompare}>Compare</Button>
            </Box>
            <Typography sx={{color: colors.custom.green.variant2}} className={'fs-14 pointer underline mt-15'} onClick={panelToggle}>Minimize</Typography>
          </Box>}
          {(phone && plansOpen) && <Collapse in={plansOpen}>
            {plans.map((plan, index) => <ComparisonCard key={index} plan={plan} />)}
            <Button className={'ph-30'}
                    variant={'contained'}
                    fullWidth
                    onClick={goToCompare}>Compare {plans.length} Plan{count > 1 ? 's' : ''}</Button>
            <Typography align={'center'} sx={{color: colors.custom.green.variant2}} className={'fs-14 fill-width pointer underline mt-15'} onClick={() => setPlansOpen(false)}>Minimize</Typography>
          </Collapse>}
        </Box>
    </Slide>
  </>
}

const StyledButton = styled(IconButton)({
  background: '#002576',
  width: 30,
  height: 30,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    background: '#002576',
  },
  '&:active': {
    background: '#002576',
  },
})
