import { split, HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import {getMainDefinition} from "@apollo/client/utilities";
import { errorTracker, graphQLErrorHandler } from './GraphQLErrorHandler';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import {getToken} from "@coverright/shared/keycloak";

const httpLink = new HttpLink({
  uri: process.env.NX_ENROLLMENT_GRAPHQL,
  headers: {
    "content-type": "application/json",
    // version: config.buildVersion,
  }
});

const authLink = setContext(async (_, { headers }: any) => {

  const token = await getToken().catch(() => {});
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.NX_ENROLLMENT_SUBSCRIPTIONS as string,
  connectionParams: async () => {
    const token = await getToken();
    return {
      Authorization: token ? token : "",
    }
  },
  lazy: true,
}));

const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const requestLink =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  requestLink
);

export const enrollmentClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
