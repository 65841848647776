import { Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { TextInput } from '@coverright/ui/inputs';
import { V3Theme } from '@coverright/ui/themes';
import { Terms } from '@coverright/ui/shared';
import paths from '../../../config/router-paths';
import { getStoredUser } from '@coverright/utils';
import { useNavigate } from 'react-router';

export default function SignIn() {
  const navigate = useNavigate();

  return <ThemeProvider theme={V3Theme}>
    <Box sx={{background: '#F8F6F2', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100vw', height: '100vh'}}>
      <Box component={'img'} src={'/assets/img/Logo.svg'} width={150} height={28} />
      <Box sx={{background: 'white', p: 5, borderRadius: '16px', width: 1, maxWidth: 420, my: 8}}>
        <Typography sx={{color: '#1C434F'}} variant={'h3'} className={'fs-32 bold mb-28'}>Log in to CoverRight</Typography>
        <TextInput fullWidth
                     autoFocus={true}
                     label={''}
                     placeholder={'Enter your email address'} />
        <TextInput fullWidth
                     label={''}
                     placeholder={'Enter your password'} />
        <Typography sx={{color: '#1C434F'}} className={'fs-16 medium mb-15 pointer'} onClick={() => navigate('/sign-up/birthdate')}>Forgot/Create password</Typography>
        <Button fullWidth variant={'contained'}  onClick={() => {
          navigate(paths.plans + getStoredUser()?.zip + '/' + getStoredUser()?.countyName + document.location.search);
        }}>Sign in</Button>
        <Typography sx={{color: '#1C434F'}} className={'fs-14 medium mt-15'}>Don't have an account? <span className={'pointer bold'} onClick={() => navigate('/sign-up/birthdate')}>Sign up</span></Typography>
      </Box>
      <Box sx={{position: 'absolute', left: 30, bottom: 30}}>
        <Terms />
      </Box>
    </Box>
  </ThemeProvider>
}
