import React from 'react';
import * as yup from 'yup';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, IconButton, Theme, Typography } from '@mui/material';
import { GetPlansYear, getStoredUser } from '@coverright/utils';
import { TextInput } from '@coverright/ui/inputs';
import { CRModal } from '@coverright/ui/shared';
import { useFormik } from 'formik';
import { useEmailMeMAPage, useEmailMeMedigapPage } from '@coverright/data-access/enrollment';
import { EmailMeMaPagePlanYear } from '@coverright/data-access/types/enrollment';
import { PlanYear } from '@coverright/data-access/types/medicare';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    pointer: {
      cursor: 'pointer'
    }
  })
);

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Enter a valid email address'),
});

interface FormState {
  email: string;
}

type PlanListEmailModalProps = {
  onClose: () => void,
  open: boolean,
  id: string,
  medigap?: boolean
}
export default function PlanListEmailModal(props: PlanListEmailModalProps) {
  const classes = useStyles();

  const [sendEmail] = useEmailMeMedigapPage();
  const [sendMAEmail] = useEmailMeMAPage();

  const sendMG = (email: string) => sendEmail({
    variables: {
      email,
      id: props.id
    }
  })

  const sendMA = (email: string) => sendMAEmail({
    variables: {
      email,
      id: props.id,
      yearEmailMeMaPage: GetPlansYear().toUpperCase() as EmailMeMaPagePlanYear
    }
  })

  const formik = useFormik({
    initialValues: {
      email: getStoredUser()?.email || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (formik.isValid) {
        onSubmit(values)
      }
    },
    validateOnChange: true
  });


  const onSubmit = React.useCallback((values: FormState) => {
    if (props.medigap) {
      sendMG(values.email).finally(props.onClose);
    } else {
      sendMA(values.email).finally(props.onClose);
    }
  }, [props.medigap, sendMA, sendMG])

  return <CRModal
    width={700}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'45px 55px 40px'}
  >
    <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
      <IconButton onClick={props.onClose} className={classes.closeIcon}><img src={'/assets/img/close.svg'} /></IconButton>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography className={'fs-26 bold text-align-centered'} color={'textPrimary'}>Enter your email below to send your<br />plan options to yourself. </Typography>
        <div className={'h-16'} />
        <img src={'/assets/img/mail.svg'} />
      </Box>
      <Box mt={'16px'}>
        <TextInput fullWidth
                       name={'email'}
                       autoFocus={true}
                       inputProps={{
                         autoCapitalize: 'none'
                       }}
                       placeholder={'Enter your email address'}
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       error={formik.touched.email && Boolean(formik.errors.email)}
                       helperText={formik.touched.email && formik.errors.email}
                       />
        <Box display={'flex'}>
          <img src={'/assets/img/padlock.svg'} className={'mr-5'} />
          <Typography className={'fs-14 bold'} color={'textPrimary'}>100% secure. We’ll never sell your details.</Typography>
        </Box>
      </Box>

      <Box display={'flex'} mt={'20px'} justifyContent={'center'}>
        <Button variant={'contained'} disabled={!formik.isValid} type={'submit'}>Send plans now</Button>
      </Box>
      <div className={'h-30'} />
      <Typography className={'fs-13'} color={'textSecondary'}>
        By submitting my email and clicking ‘Send plans now’ above, I confirm that the email provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content,   through the use of email. I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
        This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />

        I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.

      </Typography>
    </form>
  </CRModal>
}
