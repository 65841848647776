import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Box, Typography } from '@mui/material';
import { getCompanyLogo } from '@coverright/utils';
import { CompanyLogo, StarRating } from '@coverright/ui/shared';

interface DCBPlanHeaderProps {
  plan: IDrugCostBreakdownPlan;
}

export default function PlanHeader({ plan }: DCBPlanHeaderProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(28, 67, 79, 0.05)',
        borderRadius: '8px',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <StarRating plan={plan} />
      <CompanyLogo
        sx={{ backgroundPosition: 'center' }}
        url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
      />
      <Typography className={'semi-bold fs-18'}>{plan.planName}</Typography>
    </Box>
  );
}
