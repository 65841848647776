import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import { colors } from '@coverright/ui/themes';

export function PlanSectionTitle({title}: { title: string }) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      mb={'-2px'}
      zIndex={1}
      position={'sticky'}
      pt={'10px'}
      bgcolor={'#f5f7f6'}
      top={phone ? 80 : 70}>
      <Box justifyContent={"center"} width={1}
           textAlign={"center"}
           p={'13px 0'}
           sx={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
           boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
           bgcolor={colors.custom.green.variant2}>
        <Typography className={'bold fs-18 lh-22'} sx={{color: 'white'}}>{title}</Typography>
      </Box>
    </Box>
  )
}
