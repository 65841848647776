import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: { input: any; output: any; }
  /** A type representing local date */
  LocalDate: { input: any; output: any; }
  /** A type representing long */
  Long: { input: any; output: any; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
  /** A type representing uploaded file */
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  aptNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  county: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressDto = {
  __typename?: 'AddressDto';
  aptNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressDtoInput = {
  aptNumber: Scalars['String']['input'];
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetName: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type AffiliationPartnershipOutput = {
  __typename?: 'AffiliationPartnershipOutput';
  campaign: Scalars['String']['output'];
  source: Scalars['String']['output'];
  tag?: Maybe<Scalars['String']['output']>;
};

export type AgentAvailabilityStateCallDetails = {
  __typename?: 'AgentAvailabilityStateCallDetails';
  clientEmail: Scalars['String']['output'];
  clientId: Scalars['UUID']['output'];
  clientName: Scalars['String']['output'];
};

export type AgentAvailabilityStateOutput = {
  __typename?: 'AgentAvailabilityStateOutput';
  agentEmail: Scalars['String']['output'];
  agentId: Scalars['UUID']['output'];
  agentName: Scalars['String']['output'];
  availabilityIsUpdatedAt?: Maybe<Scalars['Instant']['output']>;
  csr: Scalars['Boolean']['output'];
  dontDisturb: Scalars['Boolean']['output'];
  excludedFromRoundRobin: Scalars['Boolean']['output'];
  isAvailableForNewLeads: Scalars['Boolean']['output'];
  onCall: Scalars['Boolean']['output'];
  onCallWithClient?: Maybe<AgentAvailabilityStateCallDetails>;
  online: Scalars['Boolean']['output'];
};

export type AgentCallAgendaOutput = {
  __typename?: 'AgentCallAgendaOutput';
  agentsOnline: Array<AgentsOnline>;
  assignedClientsCallTasks: Array<Scalars['UUID']['output']>;
  dontDisturb: Scalars['Boolean']['output'];
  scheduledReassignAt?: Maybe<Scalars['Instant']['output']>;
};

export enum AgentCallAgendaTaskAssignmentTypeOutput {
  Agent = 'AGENT',
  Csr = 'CSR'
}

export type AgentCallAgendaTaskCurrentAssignmentOutput = {
  __typename?: 'AgentCallAgendaTaskCurrentAssignmentOutput';
  assignedAgentEmail?: Maybe<Scalars['String']['output']>;
  assignedAgentId?: Maybe<Scalars['UUID']['output']>;
  assignedAgentName?: Maybe<Scalars['String']['output']>;
  assignedUntil?: Maybe<Scalars['Instant']['output']>;
  scheduledReassignAt?: Maybe<Scalars['Instant']['output']>;
  type: AgentCallAgendaTaskAssignmentTypeOutput;
};

export type AgentCallAgendaTaskHistoryItemOutput = {
  __typename?: 'AgentCallAgendaTaskHistoryItemOutput';
  agentEmail?: Maybe<Scalars['String']['output']>;
  agentId?: Maybe<Scalars['UUID']['output']>;
  agentName?: Maybe<Scalars['String']['output']>;
  type: AgentCallAgendaTaskAssignmentTypeOutput;
};

export type AgentCallAgendaTaskOutput = {
  __typename?: 'AgentCallAgendaTaskOutput';
  assignmentHistory: Array<AgentCallAgendaTaskHistoryItemOutput>;
  clientEmail: Scalars['String']['output'];
  clientId: Scalars['UUID']['output'];
  clientName: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['Instant']['output'];
  currentAssignment: AgentCallAgendaTaskCurrentAssignmentOutput;
  isNew: Scalars['Boolean']['output'];
};

export type AgentEmailOutput = {
  __typename?: 'AgentEmailOutput';
  agentId: Scalars['UUID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  snippet?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type AgentEmailPageOutput = {
  __typename?: 'AgentEmailPageOutput';
  data: Array<AgentEmailOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum AgentField {
  Enabled = 'ENABLED'
}

export type AgentFilterInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgentInput = {
  b2bMeetingUrl?: InputMaybe<Scalars['String']['input']>;
  consultationUrl?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentMeetingUrl?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isCSR?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  picUrl?: InputMaybe<Scalars['String']['input']>;
  planReviewUrl?: InputMaybe<Scalars['String']['input']>;
  receiveEmails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgentOutput = {
  __typename?: 'AgentOutput';
  b2bMeetingUrl?: Maybe<Scalars['String']['output']>;
  consultationUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enrollmentMeetingUrl?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isCSR: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picUrl?: Maybe<Scalars['String']['output']>;
  planReviewUrl?: Maybe<Scalars['String']['output']>;
  receiveEmails: Scalars['Boolean']['output'];
};

export type AgentPerformanceMetricOutput = {
  __typename?: 'AgentPerformanceMetricOutput';
  primaryKey: Scalars['String']['output'];
  secondaryKey?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['BigDecimal']['output']>;
};

export enum AgentSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AgentSortInput = {
  direction: AgentSortDirection;
  field: AgentField;
};

export type AgentViewOutput = {
  __typename?: 'AgentViewOutput';
  b2bMeetingUrl?: Maybe<Scalars['String']['output']>;
  consultationUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  enrollmentMeetingUrl?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picUrl?: Maybe<Scalars['String']['output']>;
  planReviewUrl?: Maybe<Scalars['String']['output']>;
};

export type AgentsOnline = {
  __typename?: 'AgentsOnline';
  agentId: Scalars['UUID']['output'];
  firstName: Scalars['String']['output'];
  isCsr: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
};

export type AllEnrollmentsFilterInput = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  createdByAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<EnrollmentType>;
};

export type AppointmentInviteeOutput = {
  __typename?: 'AppointmentInviteeOutput';
  clientId?: Maybe<Scalars['UUID']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type AppointmentOutput = {
  __typename?: 'AppointmentOutput';
  agent: AgentOutput;
  createdAt: Scalars['Instant']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Instant']['output']>;
  invitee: AppointmentInviteeOutput;
  name: Scalars['String']['output'];
  noShow?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['Instant']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  url: Scalars['String']['output'];
};

export type AssignedTagViewOutput = {
  __typename?: 'AssignedTagViewOutput';
  expiresAt?: Maybe<Scalars['Instant']['output']>;
  tag: TagViewOutput;
};

export type AssignmentHistoryOutput = {
  __typename?: 'AssignmentHistoryOutput';
  agentName: Scalars['String']['output'];
  assignmentDate: Scalars['Instant']['output'];
  assignmentType: AssignmentHistoryType;
};

export enum AssignmentHistoryType {
  Assign = 'ASSIGN',
  ClearAssign = 'CLEAR_ASSIGN',
  Unwatch = 'UNWATCH',
  Watch = 'WATCH'
}

export type AttemptedLeadFilterInput = {
  badNumbersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  excludeBadNumbers?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCalledToday?: InputMaybe<Scalars['Boolean']['input']>;
  excludeDoNotCall?: InputMaybe<Scalars['Boolean']['input']>;
  iep?: InputMaybe<Scalars['Boolean']['input']>;
  leadGroup?: InputMaybe<AttemptedLeadGroupDto>;
  range?: InputMaybe<DateRangeInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<ClientViewTimeZone>;
};

export enum AttemptedLeadGroupDto {
  CalledToday = 'CALLED_TODAY',
  Group_1 = 'GROUP_1',
  Group_2 = 'GROUP_2',
  Group_3 = 'GROUP_3',
  Group_4 = 'GROUP_4',
  Group_5 = 'GROUP_5',
  Group_6 = 'GROUP_6',
  Group_7 = 'GROUP_7'
}

export type AttemptedLeadOutput = {
  __typename?: 'AttemptedLeadOutput';
  birthDate?: Maybe<Scalars['Instant']['output']>;
  callsCount: Scalars['Int']['output'];
  clientId: Scalars['UUID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  iepFlag?: Maybe<Scalars['Boolean']['output']>;
  lastCallAgentFullName?: Maybe<Scalars['String']['output']>;
  lastCallDate?: Maybe<Scalars['Instant']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  leadDate: Scalars['Instant']['output'];
  leadGroup?: Maybe<AttemptedLeadGroupDto>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Status;
  tags: Array<AssignedTagViewOutput>;
  urgent: Scalars['Boolean']['output'];
};

export enum AttemptedLeadSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum AttemptedLeadSortField {
  Birthdate = 'BIRTHDATE',
  LeadDate = 'LEAD_DATE'
}

export type AttemptedLeadSortInput = {
  direction: AttemptedLeadSortDirection;
  field: AttemptedLeadSortField;
};

export enum CalcDrugFrequencyPeriodInput {
  Daily = 'DAILY',
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum CalcDrugFrequencyPeriodViewOutput {
  Daily = 'DAILY',
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum CallHistoryCallType {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type CallHistoryInput = {
  callType?: InputMaybe<CallHistoryCallType>;
  clientId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  phoneNumber: Scalars['String']['input'];
  result?: InputMaybe<CallHistoryResultInput>;
};

export type CallHistoryOutput = {
  __typename?: 'CallHistoryOutput';
  agent?: Maybe<AgentViewOutput>;
  audioRecordId?: Maybe<Scalars['Long']['output']>;
  callType?: Maybe<CallHistoryCallType>;
  clientId: Scalars['UUID']['output'];
  createdAt?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  phoneNumber: Scalars['String']['output'];
  result?: Maybe<CallHistoryResultOutput>;
  transcription?: Maybe<Scalars['String']['output']>;
};

export enum CallHistoryResultInput {
  SuccessfulBookedCall = 'SUCCESSFUL_BOOKED_CALL',
  SuccessfulSeeNotesForCallDetails = 'SUCCESSFUL_SEE_NOTES_FOR_CALL_DETAILS',
  SuccessfulWarmTransfer = 'SUCCESSFUL_WARM_TRANSFER',
  UnsuccessfulInvalidNumber = 'UNSUCCESSFUL_INVALID_NUMBER',
  UnsuccessfulLeftEmail = 'UNSUCCESSFUL_LEFT_EMAIL',
  UnsuccessfulLeftText = 'UNSUCCESSFUL_LEFT_TEXT',
  UnsuccessfulLeftVm = 'UNSUCCESSFUL_LEFT_VM'
}

export enum CallHistoryResultOutput {
  SuccessfulBookedCall = 'SUCCESSFUL_BOOKED_CALL',
  SuccessfulSeeNotesForCallDetails = 'SUCCESSFUL_SEE_NOTES_FOR_CALL_DETAILS',
  SuccessfulWarmTransfer = 'SUCCESSFUL_WARM_TRANSFER',
  UnsuccessfulInvalidNumber = 'UNSUCCESSFUL_INVALID_NUMBER',
  UnsuccessfulLeftEmail = 'UNSUCCESSFUL_LEFT_EMAIL',
  UnsuccessfulLeftText = 'UNSUCCESSFUL_LEFT_TEXT',
  UnsuccessfulLeftVm = 'UNSUCCESSFUL_LEFT_VM',
  UnsuccessfulLeftVmAndText = 'UNSUCCESSFUL_LEFT_VM_AND_TEXT',
  UnsuccessfulNoVmOption = 'UNSUCCESSFUL_NO_VM_OPTION'
}

export type ClientDetailsSubscriptionInput = {
  clientId: Scalars['UUID']['input'];
};

export enum ClientDrugDeliveryType {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export type ClientFileInput = {
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ClientFileOutput = {
  __typename?: 'ClientFileOutput';
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ClientGroupItemOutput = {
  __typename?: 'ClientGroupItemOutput';
  clientId: Scalars['UUID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type ClientGroupOutput = {
  __typename?: 'ClientGroupOutput';
  clients: Array<ClientGroupItemOutput>;
  id: Scalars['UUID']['output'];
};

export type ClientNoteOutput = {
  __typename?: 'ClientNoteOutput';
  clientId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdByAgent?: Maybe<AgentViewOutput>;
  id: Scalars['UUID']['output'];
  note: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
};

export type ClientTagInput = {
  clientId: Scalars['UUID']['input'];
  tags: Array<TagAssignedToClientInput>;
};

export type ClientTrackingIdDtoInput = {
  trackingId: Scalars['String']['input'];
  type: TrackingIdTypeDto;
};

export type ClientViewFilterInput = {
  adviserEmail?: InputMaybe<Scalars['String']['input']>;
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  birthDate?: InputMaybe<DateRangeInput>;
  marketingCampaign?: InputMaybe<Scalars['String']['input']>;
  marketingSource?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<DateRangeInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusDto>;
  tags?: InputMaybe<Array<Scalars['UUID']['input']>>;
  timeZone?: InputMaybe<ClientViewTimeZone>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type ClientViewOutput = {
  __typename?: 'ClientViewOutput';
  adviserEmail?: Maybe<Scalars['String']['output']>;
  adviserName?: Maybe<Scalars['String']['output']>;
  affiliationPartnershipSource?: Maybe<Scalars['String']['output']>;
  agent?: Maybe<AgentViewOutput>;
  agentMedicareQuote?: Maybe<MedicareQuoteOutput>;
  agentMedigapQuote?: Maybe<MedigapQuoteViewOutput>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  clientFiles: Array<ClientFileOutput>;
  countyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customAnswers?: Maybe<Scalars['String']['output']>;
  disqualifiedReason?: Maybe<DisqualifiedReasonDto>;
  doctors: Array<DoctorViewOutput>;
  drugDeliveryType?: Maybe<DrugDeliveryTypeViewOutput>;
  drugs: Array<DrugViewOutput>;
  email?: Maybe<Scalars['String']['output']>;
  enrolled?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<EnrollmentViewOutput>;
  expectedCloseDate?: Maybe<Scalars['LocalDate']['output']>;
  filters?: Maybe<FiltersOutput>;
  firstName?: Maybe<Scalars['String']['output']>;
  followUpDate?: Maybe<Scalars['LocalDate']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  leadSources?: Maybe<Array<LeadSourceOutput>>;
  marketingCampaign?: Maybe<Scalars['String']['output']>;
  marketingSource?: Maybe<Scalars['String']['output']>;
  medigapEnrollment?: Maybe<MedigapEnrollmentViewOutput>;
  medigapQuote?: Maybe<MedigapQuoteViewOutput>;
  noShow?: Maybe<Scalars['Boolean']['output']>;
  notes: Array<ClientNoteOutput>;
  originalClientId?: Maybe<Scalars['UUID']['output']>;
  pdpEnrollment?: Maybe<PdpEnrollmentViewOutput>;
  pdpQuote?: Maybe<PdpQuoteOutput>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
  preferredDrugs: Array<PreferredDrugViewOutput>;
  preferredPharmacies: Array<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['UUID']['output']>;
  profileSource?: Maybe<ProfileSource>;
  purchasedProducts: Array<PurchasedProductOutput>;
  quoteId?: Maybe<Scalars['UUID']['output']>;
  scheduledTagPlacement?: Maybe<Scalars['Boolean']['output']>;
  scopeOfAppointmentStatus?: Maybe<ScopeOfAppointmentStatusDto>;
  state?: Maybe<Scalars['String']['output']>;
  status: StatusDto;
  tags: Array<AssignedTagViewOutput>;
  tobacco?: Maybe<Scalars['Boolean']['output']>;
  urgentCallRequested?: Maybe<Scalars['Boolean']['output']>;
  urgentCallType?: Maybe<UrgentCallTypeDto>;
  userId?: Maybe<Scalars['UUID']['output']>;
  watchers: Array<AgentViewOutput>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ClientViewSortInput = {
  direction: SortDirection;
  field: Field;
};

export enum ClientViewTimeZone {
  Akst = 'AKST',
  Cst = 'CST',
  Est = 'EST',
  Hst = 'HST',
  Mst = 'MST',
  Pst = 'PST'
}

export type ClientWatcherInput = {
  agentIds: Array<Scalars['UUID']['input']>;
  clientId: Scalars['UUID']['input'];
};

export type CompareOfferHistoryInput = {
  clientId: Scalars['UUID']['input'];
  screenshotLink: Scalars['String']['input'];
};

export type CompareOfferHistoryOutput = {
  __typename?: 'CompareOfferHistoryOutput';
  clientId: Scalars['UUID']['output'];
  createdAt?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['UUID']['output'];
  screenshotLink: Scalars['String']['output'];
};

export enum CoverRightProductDto {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export type CsrTrackingReportOutput = {
  __typename?: 'CsrTrackingReportOutput';
  agentId: Scalars['UUID']['output'];
  bookedCallsCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  futureAppointmentsCount: Scalars['Int']['output'];
  nonWarmTransfersAttendedCount: Scalars['Int']['output'];
  nonWarmTransfersCount: Scalars['Int']['output'];
  nonWarmTransfersNotAttendedCount: Scalars['Int']['output'];
  undispositionedAppointmentsCount: Scalars['Int']['output'];
  warmTransfersCount: Scalars['Int']['output'];
};

export type CurrentAgentCallAgendaStateOutput = {
  __typename?: 'CurrentAgentCallAgendaStateOutput';
  agentsSorted: Array<AgentAvailabilityStateOutput>;
  tasks: Array<AgentCallAgendaTaskOutput>;
};

export type DateRangeInput = {
  from: Scalars['Instant']['input'];
  to: Scalars['Instant']['input'];
};

export enum DisqualifiedReasonDto {
  NoLongerInterestedInSwitching = 'NO_LONGER_INTERESTED_IN_SWITCHING',
  UnableToConnect = 'UNABLE_TO_CONNECT',
  WentToAnotherBroker = 'WENT_TO_ANOTHER_BROKER'
}

export type DoctorAddressViewOutput = {
  __typename?: 'DoctorAddressViewOutput';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPcp: Scalars['Boolean']['output'];
  specialty: Scalars['String']['output'];
};

export type DoctorViewOutput = {
  __typename?: 'DoctorViewOutput';
  addresses: Array<DoctorAddressViewOutput>;
  addressesNumber: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  npi: Scalars['String']['output'];
  pcpDetails: Array<PcpInfoViewOutput>;
};

export enum DrugDeliveryTypeViewOutput {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export type DrugViewOutput = {
  __typename?: 'DrugViewOutput';
  frequency?: Maybe<Scalars['String']['output']>;
  genericName?: Maybe<Scalars['String']['output']>;
  genericRxcui?: Maybe<Scalars['String']['output']>;
  isGeneric?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packName?: Maybe<Scalars['String']['output']>;
  packRxcui?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['BigDecimal']['output']>;
  rxcui?: Maybe<Scalars['String']['output']>;
};

export enum EmailMeMaPagePlanYear {
  Year2021 = 'YEAR2021',
  Year2022 = 'YEAR2022',
  Year2023 = 'YEAR2023'
}

export type EmailTemplateDto = {
  __typename?: 'EmailTemplateDto';
  id?: Maybe<Scalars['UUID']['output']>;
  template: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EmailTemplateDtoInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  template: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type EnrollmentAddressOutput = {
  __typename?: 'EnrollmentAddressOutput';
  aptNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type EnrollmentFilterInput = {
  isImported?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<EnrollmentStatusInput>>;
};

export type EnrollmentInput = {
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  applicationDate?: InputMaybe<Scalars['LocalDate']['input']>;
  bidId?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  communicationPreference?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enrollmentType?: InputMaybe<MedicareEnrollmentType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isElectronicallyCommunications?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<AddressDtoInput>;
  medicareNumber?: InputMaybe<Scalars['String']['input']>;
  medicarePartAStartedAt?: InputMaybe<Scalars['String']['input']>;
  medicarePartBStartedAt?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<AddressDtoInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
  planYear?: InputMaybe<PlanYear>;
  preferredLanguage?: InputMaybe<PreferredLanguageDto>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  started?: InputMaybe<Scalars['Instant']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type EnrollmentListItemOutput = {
  __typename?: 'EnrollmentListItemOutput';
  age?: Maybe<Scalars['Int']['output']>;
  agentId?: Maybe<Scalars['UUID']['output']>;
  applicationDate?: Maybe<Scalars['LocalDate']['output']>;
  approvedAt?: Maybe<Scalars['Instant']['output']>;
  bidId?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enrollmentType?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mgPlanKey?: Maybe<Scalars['String']['output']>;
  mgPlanName?: Maybe<Scalars['String']['output']>;
  naic?: Maybe<Scalars['String']['output']>;
  pdpQuoteId?: Maybe<Scalars['UUID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planYear?: Maybe<Scalars['String']['output']>;
  started?: Maybe<Scalars['Instant']['output']>;
  status: Scalars['String']['output'];
  tobacco?: Maybe<Scalars['Boolean']['output']>;
  type: EnrollmentType;
  zip?: Maybe<Scalars['String']['output']>;
};

export type EnrollmentOutput = {
  __typename?: 'EnrollmentOutput';
  agentId?: Maybe<Scalars['UUID']['output']>;
  applicationDate?: Maybe<Scalars['LocalDate']['output']>;
  approvedAt?: Maybe<Scalars['String']['output']>;
  bidId?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  communicationPreference?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByAdmin: Scalars['Boolean']['output'];
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enrollmentType?: Maybe<MedicareEnrollmentType>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<AddressDto>;
  medicareNumber?: Maybe<Scalars['String']['output']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']['output']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<AddressDto>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planYear?: Maybe<PlanYear>;
  preferredLanguage?: Maybe<PreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']['output']>;
  started?: Maybe<Scalars['Instant']['output']>;
  status: StatusOutput;
  userId?: Maybe<Scalars['UUID']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type EnrollmentPlanNameMigrationResult = {
  __typename?: 'EnrollmentPlanNameMigrationResult';
  maErrorCount: Scalars['Int']['output'];
  maSuccessfulCount: Scalars['Int']['output'];
  maTotalCount: Scalars['Int']['output'];
  medigapErrorCount: Scalars['Int']['output'];
  medigapSuccessfulCount: Scalars['Int']['output'];
  medigapTotalCount: Scalars['Int']['output'];
  pdpErrorCount: Scalars['Int']['output'];
  pdpSuccessfulCount: Scalars['Int']['output'];
  pdpTotalCount: Scalars['Int']['output'];
};

export enum EnrollmentPreferredLanguageDto {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum EnrollmentStatusDto {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum EnrollmentStatusInput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum EnrollmentType {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export type EnrollmentViewOutput = {
  __typename?: 'EnrollmentViewOutput';
  approvedAt?: Maybe<Scalars['String']['output']>;
  bidId?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  communicationPreference?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<EnrollmentAddressOutput>;
  medicareNumber?: Maybe<Scalars['String']['output']>;
  medicarePartAStartedAt: Scalars['String']['output'];
  medicarePartBStartedAt?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<EnrollmentAddressOutput>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<EnrollmentPreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']['output']>;
  status: EnrollmentStatusDto;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum Field {
  BirthDate = 'birthDate',
  CreatedAt = 'createdAt',
  Email = 'email',
  ExpectedCloseDate = 'expectedCloseDate',
  FirstName = 'firstName',
  FollowUpDate = 'followUpDate',
  Gender = 'gender',
  LastCallDate = 'lastCallDate',
  LastName = 'lastName',
  Note = 'note',
  PhoneNumber = 'phoneNumber',
  ScopeOfAppointmentStatus = 'scopeOfAppointmentStatus',
  Status = 'status',
  Zip = 'zip'
}

export type FiltersOutput = {
  __typename?: 'FiltersOutput';
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  extraBenefits: Array<ExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']['output']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']['output']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']['output']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']['output']>;
  planTypes?: Maybe<Array<Scalars['String']['output']>>;
  rating?: Maybe<Array<Rating>>;
};

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum FrequencyPeriodInput {
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export enum FrequencyPeriodViewOutput {
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export enum Gender {
  F = 'F',
  M = 'M'
}

export type InviteePersonalInfoOutput = {
  __typename?: 'InviteePersonalInfoOutput';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type KeycloakUserInput = {
  email: Scalars['String']['input'];
  enrollmentId?: InputMaybe<Scalars['UUID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['UUID']['input']>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
};

export type KeycloakUserOutput = {
  __typename?: 'KeycloakUserOutput';
  enrollmentId: Scalars['UUID']['output'];
  userId: Scalars['UUID']['output'];
};

export type LeadSourceOutput = {
  __typename?: 'LeadSourceOutput';
  campaign: Scalars['String']['output'];
  content?: Maybe<Scalars['String']['output']>;
  eventDate: Scalars['LocalDate']['output'];
  medium: Scalars['String']['output'];
  source: Scalars['String']['output'];
  term?: Maybe<Scalars['String']['output']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  idToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum MedicareEnrollmentType {
  MaNew = 'MA_NEW',
  MaReplacement = 'MA_REPLACEMENT',
  MaUnlikeChange = 'MA_UNLIKE_CHANGE'
}

export type MedicareQuoteOutput = {
  __typename?: 'MedicareQuoteOutput';
  agentOffer?: Maybe<Scalars['Boolean']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  customAnswers?: Maybe<Scalars['String']['output']>;
  doctors: Array<DoctorViewOutput>;
  drugs: Array<DrugViewOutput>;
  filters?: Maybe<FiltersOutput>;
  pin?: Maybe<Scalars['String']['output']>;
  quoteId: Scalars['UUID']['output'];
  state?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum MedigapEnrollmentAccessibleFormat {
  AccessibleScreenReader = 'ACCESSIBLE_SCREEN_READER',
  Audio = 'AUDIO',
  Braille = 'BRAILLE',
  LargePrint = 'LARGE_PRINT',
  OralOverThePhone = 'ORAL_OVER_THE_PHONE'
}

export type MedigapEnrollmentAddress = {
  __typename?: 'MedigapEnrollmentAddress';
  aptNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  county: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  toRegular: Address;
  zip: Scalars['String']['output'];
};

export type MedigapEnrollmentAddressInput = {
  aptNumber: Scalars['String']['input'];
  city: Scalars['String']['input'];
  county: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetName: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type MedigapEnrollmentInput = {
  accessibleFormatPreference?: InputMaybe<MedigapEnrollmentAccessibleFormat>;
  age: Scalars['Int']['input'];
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  applicationDate?: InputMaybe<Scalars['LocalDate']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  cId?: InputMaybe<Scalars['String']['input']>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate: Scalars['LocalDate']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  enrollmentType?: InputMaybe<MedigapEnrollmentType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender: Gender;
  hospitalDate?: InputMaybe<Scalars['LocalDate']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingAddress?: InputMaybe<MedigapEnrollmentAddressInput>;
  medicalDate?: InputMaybe<Scalars['LocalDate']['input']>;
  medicareNumber?: InputMaybe<Scalars['String']['input']>;
  medigapQuoteId?: InputMaybe<Scalars['UUID']['input']>;
  naic: Scalars['String']['input'];
  pdpBidId?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<MedigapEnrollmentAddressInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planKey: Scalars['String']['input'];
  planName: MedigapEnrollmentPlanName;
  planYear?: InputMaybe<PlanYear>;
  preferredLanguage?: InputMaybe<MedigapEnrollmentPreferredLanguage>;
  started?: InputMaybe<Scalars['Instant']['input']>;
  tobacco: Scalars['Boolean']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type MedigapEnrollmentOutput = {
  __typename?: 'MedigapEnrollmentOutput';
  accessibleFormatPreference?: Maybe<MedigapEnrollmentAccessibleFormat>;
  age: Scalars['Int']['output'];
  agentId?: Maybe<Scalars['UUID']['output']>;
  applicationDate?: Maybe<Scalars['LocalDate']['output']>;
  approvedAt?: Maybe<Scalars['Instant']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  effectiveDate: Scalars['LocalDate']['output'];
  email?: Maybe<Scalars['String']['output']>;
  enrollmentType?: Maybe<MedigapEnrollmentType>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender: Gender;
  hospitalDate?: Maybe<Scalars['LocalDate']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<MedigapEnrollmentAddress>;
  medicalDate?: Maybe<Scalars['LocalDate']['output']>;
  medicareNumber?: Maybe<Scalars['String']['output']>;
  medigapQuoteId?: Maybe<Scalars['UUID']['output']>;
  naic: Scalars['String']['output'];
  permanentAddress?: Maybe<MedigapEnrollmentAddress>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planKey?: Maybe<Scalars['String']['output']>;
  planName: MedigapEnrollmentPlanName;
  planYear?: Maybe<PlanYear>;
  preferredLanguage?: Maybe<MedigapEnrollmentPreferredLanguage>;
  started?: Maybe<Scalars['Instant']['output']>;
  status: MedigapEnrollmentStatus;
  tobacco: Scalars['Boolean']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export enum MedigapEnrollmentPlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MaSupp1A = 'MA_SUPP1A',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N'
}

export enum MedigapEnrollmentPreferredLanguage {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum MedigapEnrollmentStatus {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum MedigapEnrollmentType {
  MgGi = 'MG_GI',
  MgOep = 'MG_OEP',
  MgUnderwritten = 'MG_UNDERWRITTEN'
}

export enum MedigapEnrollmentViewAccessibleFormat {
  AccessibleScreenReader = 'ACCESSIBLE_SCREEN_READER',
  Audio = 'AUDIO',
  Braille = 'BRAILLE',
  LargePrint = 'LARGE_PRINT',
  OralOverThePhone = 'ORAL_OVER_THE_PHONE'
}

export type MedigapEnrollmentViewAddress = {
  __typename?: 'MedigapEnrollmentViewAddress';
  aptNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  county: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export enum MedigapEnrollmentViewGender {
  F = 'F',
  M = 'M'
}

export type MedigapEnrollmentViewOutput = {
  __typename?: 'MedigapEnrollmentViewOutput';
  accessibleFormatPreference?: Maybe<MedigapEnrollmentViewAccessibleFormat>;
  age: Scalars['Int']['output'];
  approvedAt?: Maybe<Scalars['Instant']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  effectiveDate: Scalars['LocalDate']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender: MedigapEnrollmentViewGender;
  hospitalDate?: Maybe<Scalars['LocalDate']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mailingAddress?: Maybe<MedigapEnrollmentViewAddress>;
  medicalDate?: Maybe<Scalars['LocalDate']['output']>;
  medicareNumber?: Maybe<Scalars['String']['output']>;
  naic: Scalars['String']['output'];
  pdpBidId?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<MedigapEnrollmentViewAddress>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planName: Scalars['String']['output'];
  preferredLanguage?: Maybe<MedigapEnrollmentViewPreferredLanguage>;
  quoteId?: Maybe<Scalars['UUID']['output']>;
  status: MedigapEnrollmentViewStatus;
  tobacco: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['UUID']['output']>;
};

export enum MedigapEnrollmentViewPreferredLanguage {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum MedigapEnrollmentViewStatus {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export type MedigapFilterInput = {
  isImported?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum MedigapFilterStateBenefit {
  ForeignTravelEmergency = 'FOREIGN_TRAVEL_EMERGENCY',
  PartADeductible = 'PART_A_DEDUCTIBLE',
  PartBDeductible = 'PART_B_DEDUCTIBLE',
  PartBExcessCharges = 'PART_B_EXCESS_CHARGES',
  SkilledNursingFacilities = 'SKILLED_NURSING_FACILITIES'
}

export enum MedigapFilterStateGender {
  F = 'F',
  M = 'M'
}

export enum MedigapFilterStatePlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MaSupp1A = 'MA_SUPP1A',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N'
}

export type MedigapFilterStateViewOutput = {
  __typename?: 'MedigapFilterStateViewOutput';
  age: Scalars['Int']['output'];
  benefits?: Maybe<Array<MedigapFilterStateBenefit>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  county?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  gender: MedigapFilterStateGender;
  id?: Maybe<Scalars['UUID']['output']>;
  monthlyPlanPremiumRanges?: Maybe<Array<Scalars['String']['output']>>;
  planCategory?: Maybe<Scalars['String']['output']>;
  planNames?: Maybe<Array<MedigapFilterStatePlanName>>;
  tobacco: Scalars['Boolean']['output'];
  zip: Scalars['String']['output'];
};

export type MedigapQuoteViewOutput = {
  __typename?: 'MedigapQuoteViewOutput';
  cId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['UUID']['output'];
  medigapFilterState?: Maybe<MedigapFilterStateViewOutput>;
  updatedAt?: Maybe<Scalars['Instant']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addClientFiles: Scalars['Boolean']['output'];
  changeEnrollmentStatus?: Maybe<Scalars['UUID']['output']>;
  changeMedigapEnrollmentStatus: Scalars['Boolean']['output'];
  changePdpEnrollmentStatus: Scalars['Boolean']['output'];
  clearFlexpaCache: Scalars['Boolean']['output'];
  clientCommunicationNoShow: Scalars['Boolean']['output'];
  confirmScopeOfAppointment: Scalars['UUID']['output'];
  createClientNote: Scalars['Boolean']['output'];
  createScopeOfAppointment: Scalars['UUID']['output'];
  /** @deprecated Need to use saveUserProfile API */
  createUser: KeycloakUserOutput;
  deleteClientFile: Scalars['Boolean']['output'];
  deleteClientNote: Scalars['Boolean']['output'];
  deleteClientsFromAnyGroup: Scalars['Boolean']['output'];
  deleteCompareOfferHistoryItem: Scalars['Boolean']['output'];
  deleteEmailTemplate: Scalars['Boolean']['output'];
  deleteEnrollment: Scalars['Boolean']['output'];
  deleteMedigapEnrollment: Scalars['Boolean']['output'];
  deletePdpEnrollment: Scalars['Boolean']['output'];
  deletePurchasedProduct: Scalars['Boolean']['output'];
  deleteTag: Scalars['Boolean']['output'];
  deleteTestClient: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  emailMeMAPage: Scalars['Boolean']['output'];
  emailMeMedigapPage: Scalars['Boolean']['output'];
  getRefreshToken: LoginResponse;
  login: LoginResponse;
  mergeClientsInOneGroup: Scalars['Boolean']['output'];
  mergeGroups: Scalars['Boolean']['output'];
  migrateAllEnrollmentsPlanNames: EnrollmentPlanNameMigrationResult;
  migrateEnrollmentsWithBrokenAgent: Scalars['Boolean']['output'];
  migrateY2YByClientId: Scalars['Boolean']['output'];
  openedScopeOfAppointment: Scalars['Boolean']['output'];
  patchClientPersonalInfo: Scalars['Boolean']['output'];
  putTemporalData: Scalars['UUID']['output'];
  requestUrgentCall: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  saveAgent: Scalars['UUID']['output'];
  saveAgentGmailCode: Scalars['Boolean']['output'];
  saveCallHistory: Scalars['UUID']['output'];
  saveChannelAttribution: Scalars['Boolean']['output'];
  saveClientTag: Scalars['Boolean']['output'];
  saveClientTrackingId: Scalars['Boolean']['output'];
  saveClientWatchers: Scalars['Boolean']['output'];
  saveCompareOfferHistoryItem: Scalars['Boolean']['output'];
  saveDisqualifiedReason: Scalars['Boolean']['output'];
  saveEmailTemplate: Scalars['UUID']['output'];
  saveEnrollment: Scalars['UUID']['output'];
  saveExpectedCloseDate: Scalars['Boolean']['output'];
  saveFlexpaToken: Scalars['Boolean']['output'];
  saveMedigapEnrollment: Scalars['UUID']['output'];
  savePdpEnrollment: PdpEnrollmentOutput;
  savePurchasedProduct: Scalars['UUID']['output'];
  saveStatus: Scalars['Boolean']['output'];
  saveTag: Scalars['UUID']['output'];
  saveUserProfile: UserProfileSaveOutput;
  sendComparisonEmail: Scalars['Boolean']['output'];
  sendGreeting: Scalars['String']['output'];
  sendY2YEmail: Scalars['Boolean']['output'];
  setAdminFlagForAllEnrollmentsByClientId: Scalars['Boolean']['output'];
  setCalendlyAppointmentNoShow: Scalars['Boolean']['output'];
  setClientUserId: Scalars['Boolean']['output'];
  setDefaultAgent: Scalars['Boolean']['output'];
  setDontDisturb: Scalars['Boolean']['output'];
  setNewPassword: Scalars['Boolean']['output'];
  setRecordedLineAgreementCheck: Scalars['Boolean']['output'];
  setSOAAgreementCheck: Scalars['Boolean']['output'];
  setTPMOAgreementCheck: Scalars['Boolean']['output'];
  setUrgentCallRequested: Scalars['Boolean']['output'];
  startTestCallFor30Seconds: Scalars['Boolean']['output'];
  syncGmailMessages: Scalars['Boolean']['output'];
  syncHawksoft: Scalars['Boolean']['output'];
  updateClientNote: Scalars['Boolean']['output'];
  updateSendInBlueByEmail: Scalars['String']['output'];
  updateSources: Array<LeadSourceOutput>;
  uploadAgentAvatar: Scalars['String']['output'];
};


export type MutationAddClientFilesArgs = {
  clientFiles: Array<ClientFileInput>;
  clientId: Scalars['UUID']['input'];
};


export type MutationChangeEnrollmentStatusArgs = {
  enrollmentId: Scalars['UUID']['input'];
  status: EnrollmentStatusInput;
};


export type MutationChangeMedigapEnrollmentStatusArgs = {
  enrollmentId: Scalars['UUID']['input'];
  status: MedigapEnrollmentStatus;
};


export type MutationChangePdpEnrollmentStatusArgs = {
  enrollmentId: Scalars['UUID']['input'];
  status: PdpEnrollmentStatus;
};


export type MutationClientCommunicationNoShowArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationConfirmScopeOfAppointmentArgs = {
  userInput: ScopeOfAppointmentUserInput;
};


export type MutationCreateClientNoteArgs = {
  clientId: Scalars['UUID']['input'];
  note: Scalars['String']['input'];
};


export type MutationCreateScopeOfAppointmentArgs = {
  adminInput: ScopeOfAppointmentAdminInput;
};


export type MutationCreateUserArgs = {
  input: KeycloakUserInput;
};


export type MutationDeleteClientFileArgs = {
  clientId: Scalars['UUID']['input'];
  link: Scalars['String']['input'];
};


export type MutationDeleteClientNoteArgs = {
  noteId: Scalars['UUID']['input'];
};


export type MutationDeleteClientsFromAnyGroupArgs = {
  clientIds: Array<Scalars['UUID']['input']>;
};


export type MutationDeleteCompareOfferHistoryItemArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteEnrollmentArgs = {
  enrollmentId: Scalars['UUID']['input'];
};


export type MutationDeleteMedigapEnrollmentArgs = {
  medigapEnrollmentId: Scalars['UUID']['input'];
};


export type MutationDeletePdpEnrollmentArgs = {
  enrollmentId: Scalars['UUID']['input'];
};


export type MutationDeletePurchasedProductArgs = {
  purchasedProductId: Scalars['UUID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteTestClientArgs = {
  email: Scalars['String']['input'];
};


export type MutationEmailMeMaPageArgs = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  yearEmailMeMaPage?: InputMaybe<EmailMeMaPagePlanYear>;
};


export type MutationEmailMeMedigapPageArgs = {
  email: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationMergeClientsInOneGroupArgs = {
  clientIds: Array<Scalars['UUID']['input']>;
};


export type MutationMergeGroupsArgs = {
  groupIds: Array<Scalars['UUID']['input']>;
};


export type MutationMigrateEnrollmentsWithBrokenAgentArgs = {
  key: Scalars['String']['input'];
};


export type MutationMigrateY2YByClientIdArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationOpenedScopeOfAppointmentArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPatchClientPersonalInfoArgs = {
  clientId: Scalars['UUID']['input'];
  drugDeliveryType?: InputMaybe<ClientDrugDeliveryType>;
  preferredDrugs?: InputMaybe<Array<PreferredDrugInput>>;
  preferredPharmacies?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationPutTemporalDataArgs = {
  data: Scalars['String']['input'];
};


export type MutationRequestUrgentCallArgs = {
  urgentCallType: UrgentCallTypeDto;
};


export type MutationResetPasswordArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSaveAgentArgs = {
  input: AgentInput;
};


export type MutationSaveAgentGmailCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationSaveCallHistoryArgs = {
  input: CallHistoryInput;
};


export type MutationSaveChannelAttributionArgs = {
  clientId: Scalars['UUID']['input'];
  marketingCampaign?: InputMaybe<Scalars['String']['input']>;
  marketingSource?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveClientTagArgs = {
  input: ClientTagInput;
};


export type MutationSaveClientTrackingIdArgs = {
  input: ClientTrackingIdDtoInput;
};


export type MutationSaveClientWatchersArgs = {
  input: ClientWatcherInput;
};


export type MutationSaveCompareOfferHistoryItemArgs = {
  input: CompareOfferHistoryInput;
};


export type MutationSaveDisqualifiedReasonArgs = {
  disqualifiedReason: DisqualifiedReasonDto;
  id: Scalars['UUID']['input'];
};


export type MutationSaveEmailTemplateArgs = {
  emailTemplate: EmailTemplateDtoInput;
};


export type MutationSaveEnrollmentArgs = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  input: EnrollmentInput;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSaveExpectedCloseDateArgs = {
  expectedCloseDate: Scalars['LocalDate']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationSaveFlexpaTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationSaveMedigapEnrollmentArgs = {
  input: MedigapEnrollmentInput;
};


export type MutationSavePdpEnrollmentArgs = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  input: PdpEnrollmentInput;
};


export type MutationSavePurchasedProductArgs = {
  input: PurchasedProductInput;
};


export type MutationSaveStatusArgs = {
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  followUpDate?: InputMaybe<Scalars['LocalDate']['input']>;
  id: Scalars['UUID']['input'];
  status: StatusDto;
};


export type MutationSaveTagArgs = {
  input: TagInput;
};


export type MutationSaveUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationSendComparisonEmailArgs = {
  clientId: Scalars['UUID']['input'];
  link: Scalars['String']['input'];
};


export type MutationSendGreetingArgs = {
  message: Scalars['String']['input'];
};


export type MutationSendY2YEmailArgs = {
  clientId: Scalars['UUID']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationSetAdminFlagForAllEnrollmentsByClientIdArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSetCalendlyAppointmentNoShowArgs = {
  id: Scalars['String']['input'];
  noShow: Scalars['Boolean']['input'];
};


export type MutationSetClientUserIdArgs = {
  clientId: Scalars['UUID']['input'];
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSetDefaultAgentArgs = {
  agentId: Scalars['UUID']['input'];
};


export type MutationSetDontDisturbArgs = {
  dontDisturb: Scalars['Boolean']['input'];
};


export type MutationSetNewPasswordArgs = {
  newPassword: Scalars['String']['input'];
};


export type MutationSetRecordedLineAgreementCheckArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSetSoaAgreementCheckArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSetTpmoAgreementCheckArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationSetUrgentCallRequestedArgs = {
  clientId: Scalars['UUID']['input'];
  urgentCallRequested: Scalars['Boolean']['input'];
};


export type MutationStartTestCallFor30SecondsArgs = {
  agentId: Scalars['UUID']['input'];
  clientId: Scalars['UUID']['input'];
};


export type MutationSyncGmailMessagesArgs = {
  code: Scalars['String']['input'];
};


export type MutationSyncHawksoftArgs = {
  secret: Scalars['String']['input'];
};


export type MutationUpdateClientNoteArgs = {
  note: Scalars['String']['input'];
  noteId: Scalars['UUID']['input'];
};


export type MutationUpdateSendInBlueByEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdateSourcesArgs = {
  clientId: Scalars['UUID']['input'];
};


export type MutationUploadAgentAvatarArgs = {
  img: Scalars['Upload']['input'];
};

export type NetworkInfoViewOutput = {
  __typename?: 'NetworkInfoViewOutput';
  carrierName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NewLeadsStatisticsOutput = {
  __typename?: 'NewLeadsStatisticsOutput';
  count: Scalars['Int']['output'];
  oldestCreatedAt?: Maybe<Scalars['Instant']['output']>;
};

export type PageInput = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PageableAgentOutput = {
  __typename?: 'PageableAgentOutput';
  data: Array<AgentOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableAttemptedLeadOutput = {
  __typename?: 'PageableAttemptedLeadOutput';
  data: Array<AttemptedLeadOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableCallHistoryOutput = {
  __typename?: 'PageableCallHistoryOutput';
  data: Array<CallHistoryOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableClientViewOutput = {
  __typename?: 'PageableClientViewOutput';
  data: Array<ClientViewOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableEmailTemplateOutput = {
  __typename?: 'PageableEmailTemplateOutput';
  data: Array<EmailTemplateDto>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableEnrollmentListItemOutput = {
  __typename?: 'PageableEnrollmentListItemOutput';
  data: Array<EnrollmentListItemOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableEnrollmentOutput = {
  __typename?: 'PageableEnrollmentOutput';
  data: Array<EnrollmentOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableMedigapEnrollmentOutput = {
  __typename?: 'PageableMedigapEnrollmentOutput';
  data: Array<MedigapEnrollmentOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageablePdpEnrollmentOutput = {
  __typename?: 'PageablePdpEnrollmentOutput';
  data: Array<PdpEnrollmentOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableSlaOutput = {
  __typename?: 'PageableSLAOutput';
  data: Array<SlaOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageableUserProfileOutput = {
  __typename?: 'PageableUserProfileOutput';
  data: Array<UserProfileOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PcpInfoViewOutput = {
  __typename?: 'PcpInfoViewOutput';
  id?: Maybe<Scalars['UUID']['output']>;
  networkDetails: Array<NetworkInfoViewOutput>;
  pcpId: Scalars['String']['output'];
};

export type PdpEnrollmentInput = {
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  applicationDate?: InputMaybe<Scalars['LocalDate']['input']>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  cId?: InputMaybe<Scalars['String']['input']>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enrollmentType?: InputMaybe<PdpEnrollmentType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insulinSavings?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  planName?: InputMaybe<Scalars['String']['input']>;
  planYear?: InputMaybe<PlanYear>;
  started?: InputMaybe<Scalars['Instant']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PdpEnrollmentOutput = {
  __typename?: 'PdpEnrollmentOutput';
  agentId?: Maybe<Scalars['UUID']['output']>;
  applicationDate?: Maybe<Scalars['LocalDate']['output']>;
  approvedAt?: Maybe<Scalars['Instant']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['UUID']['output'];
  county?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enrollmentType?: Maybe<PdpEnrollmentType>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  insulinSavings?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  pdpQuoteId?: Maybe<Scalars['UUID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  planName?: Maybe<Scalars['String']['output']>;
  planYear?: Maybe<PlanYear>;
  started?: Maybe<Scalars['Instant']['output']>;
  status: PdpEnrollmentStatus;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum PdpEnrollmentStatus {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum PdpEnrollmentType {
  PdpNew = 'PDP_NEW',
  PdpReplacement = 'PDP_REPLACEMENT',
  PdpUnlikeChange = 'PDP_UNLIKE_CHANGE'
}

export type PdpEnrollmentViewOutput = {
  __typename?: 'PdpEnrollmentViewOutput';
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  insulinSavings?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  pdpQuoteId?: Maybe<Scalars['UUID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type PdpFilterInput = {
  isImported?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PdpQuoteOutput = {
  __typename?: 'PdpQuoteOutput';
  cId?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['UUID']['output'];
  countyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  id: Scalars['UUID']['output'];
  insulinSavings?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Instant']['output'];
  userId?: Maybe<Scalars['UUID']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum PlanYear {
  Year2021 = 'Year2021',
  Year2022 = 'Year2022',
  Year2023 = 'Year2023',
  Year2024 = 'Year2024',
  Year2025 = 'Year2025'
}

export type PreferredDrugInput = {
  dosageRxcui: Scalars['String']['input'];
  frequency: CalcDrugFrequencyPeriodInput;
  productRxcui: Scalars['String']['input'];
  purchaseFrequency: FrequencyPeriodInput;
  quantity: Scalars['BigDecimal']['input'];
};

export type PreferredDrugViewOutput = {
  __typename?: 'PreferredDrugViewOutput';
  dosageRxcui: Scalars['String']['output'];
  frequency: CalcDrugFrequencyPeriodViewOutput;
  productRxcui: Scalars['String']['output'];
  purchaseFrequency: FrequencyPeriodViewOutput;
  quantity: Scalars['BigDecimal']['output'];
};

export enum PreferredLanguageDto {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export type ProfileAttributeInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum ProfileOption {
  CreateEnrollment = 'CREATE_ENROLLMENT',
  RegisterUser = 'REGISTER_USER',
  RequestCall = 'REQUEST_CALL',
  UnsupportedZip = 'UNSUPPORTED_ZIP'
}

export enum ProfileSource {
  ApplicationOnboarding = 'APPLICATION_ONBOARDING',
  B2B = 'B2B',
  BlueInk = 'BLUE_INK',
  CallRequest = 'CALL_REQUEST',
  DrugOptimizer = 'DRUG_OPTIMIZER',
  EnrollmentForm = 'ENROLLMENT_FORM',
  ForbesOnboarding = 'FORBES_ONBOARDING',
  MaMarketplace = 'MA_MARKETPLACE',
  MedicarecOnboarding = 'MEDICAREC_ONBOARDING',
  MgMarketplace = 'MG_MARKETPLACE',
  OutOfService = 'OUT_OF_SERVICE',
  PdpMarketplace = 'PDP_MARKETPLACE',
  RenegadeOnboarding = 'RENEGADE_ONBOARDING',
  ScheduleOnboarding = 'SCHEDULE_ONBOARDING',
  UhcOnboarding = 'UHC_ONBOARDING',
  UserFlowV1 = 'USER_FLOW_V1',
  UserFlowV1_5 = 'USER_FLOW_V1_5',
  UserFlowV2 = 'USER_FLOW_V2'
}

export type PurchasedProductInput = {
  clientId: Scalars['UUID']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isPrimaryPerson: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  type: CoverRightProductDto;
};

export type PurchasedProductOutput = {
  __typename?: 'PurchasedProductOutput';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isPrimaryPerson: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  type: CoverRightProductDto;
};

export type Query = {
  __typename?: 'Query';
  affiliationPartnerships: Array<AffiliationPartnershipOutput>;
  agentAssignedToClient: AgentViewOutput;
  agentByContext: AgentOutput;
  agentById: AgentOutput;
  agentGmailAuthUrl: Scalars['String']['output'];
  agentGmailMessages: AgentEmailPageOutput;
  agentPerformanceAnalytics: Array<AgentPerformanceMetricOutput>;
  agents: PageableAgentOutput;
  allEnrollments: PageableEnrollmentListItemOutput;
  assignmentHistory: Array<AssignmentHistoryOutput>;
  attemptedLeads: PageableAttemptedLeadOutput;
  availableAgents: Array<AgentViewOutput>;
  calendlyInvitee: InviteePersonalInfoOutput;
  callHistoryItems: PageableCallHistoryOutput;
  checkUserExistenceByEmail: Scalars['Boolean']['output'];
  clientCalendlyAppointments: Array<AppointmentOutput>;
  clientGroupByClientId?: Maybe<ClientGroupOutput>;
  clientIdByProfileId: Scalars['UUID']['output'];
  clientLeadSources: Array<LeadSourceOutput>;
  clientMedigapEnrollments: Array<MedigapEnrollmentOutput>;
  clientPdpEnrollments: Array<PdpEnrollmentOutput>;
  clientViewByPin?: Maybe<ClientViewOutput>;
  clientViews: PageableClientViewOutput;
  compareOfferHistory: Array<CompareOfferHistoryOutput>;
  contacted: PageableClientViewOutput;
  continueDrugCostActivationLinkSyncCustomerIo: Scalars['Boolean']['output'];
  continueSyncCustomerIo: Scalars['Boolean']['output'];
  csrCalendlyAppointments: Array<AppointmentOutput>;
  csrTrackingReport: Array<CsrTrackingReportOutput>;
  drugCostActivationLink: Scalars['String']['output'];
  drugCostsStatusMessage?: Maybe<Scalars['String']['output']>;
  emailTemplate: EmailTemplateDto;
  emailTemplates: PageableEmailTemplateOutput;
  enrollments: PageableEnrollmentOutput;
  flexpaFHIRResource: Scalars['String']['output'];
  followUps: PageableClientViewOutput;
  getMyEnrollment?: Maybe<EnrollmentOutput>;
  getMyMedigapEnrollments?: Maybe<MedigapEnrollmentOutput>;
  gmailMessage: AgentEmailOutput;
  gmailMessagesByClient: Array<AgentEmailOutput>;
  gmailMessagesByEmail: Array<AgentEmailOutput>;
  hasEnrollments: Scalars['Boolean']['output'];
  isAgentGmailSetUp: Scalars['Boolean']['output'];
  isFlexpaConnected: Scalars['Boolean']['output'];
  isPhoneValid: Scalars['Boolean']['output'];
  isReplayAssignmentHistoryRunning: Scalars['Boolean']['output'];
  medigapEnrollments: PageableMedigapEnrollmentOutput;
  myClientId: Scalars['UUID']['output'];
  myEnrollments: Array<EnrollmentListItemOutput>;
  pdpEnrollment: PdpEnrollmentOutput;
  pdpEnrollments: PageablePdpEnrollmentOutput;
  pushTemporalData?: Maybe<Scalars['String']['output']>;
  replayAssignmentHistoryProcessedCount: Scalars['Int']['output'];
  runClientDrugCostJob: Scalars['Boolean']['output'];
  runReplayAssignmentHistory: Scalars['Boolean']['output'];
  runReplayAssignmentHistoryForClient: Scalars['Boolean']['output'];
  salesQualified: PageableClientViewOutput;
  scopeOfAppointment?: Maybe<ScopeOfAppointmentOutput>;
  sendInBlueHistoryByEmail: SendInBlueContactWithHistory;
  shortenedCostActivationLink?: Maybe<Scalars['String']['output']>;
  startSyncCustomerIo: Scalars['Boolean']['output'];
  startSyncDrugCostActivationLinkCustomerIo: Scalars['Boolean']['output'];
  stopDrugCostActivationLinkSyncCustomerIo: Scalars['Boolean']['output'];
  stopSyncCpausedustomerIo: Scalars['Boolean']['output'];
  syncDrugCostActivationLinkByClientId: Scalars['Boolean']['output'];
  syncedCountSyncCustomerIo: Scalars['Int']['output'];
  syncedDrugCostActivationLinkCountSyncCustomerIo: Scalars['Int']['output'];
  tag: TagOutput;
  tags: Array<TagOutput>;
  userProfile?: Maybe<UserProfileOutput>;
  userProfiles: PageableUserProfileOutput;
};


export type QueryAgentAssignedToClientArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryAgentByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAgentGmailMessagesArgs = {
  agentId: Scalars['UUID']['input'];
  page: PageInput;
};


export type QueryAgentPerformanceAnalyticsArgs = {
  agentId: Scalars['UUID']['input'];
};


export type QueryAgentsArgs = {
  filter: AgentFilterInput;
  page: PageInput;
  sort?: InputMaybe<AgentSortInput>;
};


export type QueryAllEnrollmentsArgs = {
  filter: AllEnrollmentsFilterInput;
  page: PageInput;
};


export type QueryAssignmentHistoryArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryAttemptedLeadsArgs = {
  filter: AttemptedLeadFilterInput;
  pageInput: PageInput;
  sort?: InputMaybe<AttemptedLeadSortInput>;
};


export type QueryCalendlyInviteeArgs = {
  eventUrl: Scalars['String']['input'];
  inviteeUrl: Scalars['String']['input'];
};


export type QueryCallHistoryItemsArgs = {
  clientId: Scalars['UUID']['input'];
  page: PageInput;
};


export type QueryCheckUserExistenceByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryClientCalendlyAppointmentsArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryClientGroupByClientIdArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryClientIdByProfileIdArgs = {
  profileId: Scalars['UUID']['input'];
};


export type QueryClientLeadSourcesArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryClientMedigapEnrollmentsArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryClientPdpEnrollmentsArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryClientViewByPinArgs = {
  pin: Scalars['String']['input'];
};


export type QueryClientViewsArgs = {
  filterInput?: InputMaybe<ClientViewFilterInput>;
  pageInput: PageInput;
  sort?: InputMaybe<ClientViewSortInput>;
};


export type QueryCompareOfferHistoryArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryContactedArgs = {
  agentId: Scalars['UUID']['input'];
  pageInput: PageInput;
  sort?: InputMaybe<ClientViewSortInput>;
};


export type QueryContinueDrugCostActivationLinkSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryContinueSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryCsrCalendlyAppointmentsArgs = {
  agentId: Scalars['UUID']['input'];
  from: Scalars['LocalDate']['input'];
  to: Scalars['LocalDate']['input'];
};


export type QueryCsrTrackingReportArgs = {
  from: Scalars['LocalDate']['input'];
  to: Scalars['LocalDate']['input'];
};


export type QueryDrugCostActivationLinkArgs = {
  shortenedUrl: Scalars['String']['input'];
};


export type QueryEmailTemplateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryEmailTemplatesArgs = {
  pageInput: PageInput;
};


export type QueryEnrollmentsArgs = {
  input?: InputMaybe<EnrollmentFilterInput>;
  page: PageInput;
};


export type QueryFlexpaFhirResourceArgs = {
  resourceName: Scalars['String']['input'];
};


export type QueryFollowUpsArgs = {
  agentId: Scalars['UUID']['input'];
  excludedTagsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  includedTagsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  pageInput: PageInput;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ClientViewSortInput>;
};


export type QueryGmailMessageArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryGmailMessagesByClientArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryGmailMessagesByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryIsAgentGmailSetUpArgs = {
  agentId: Scalars['UUID']['input'];
};


export type QueryIsPhoneValidArgs = {
  phone: Scalars['String']['input'];
};


export type QueryIsReplayAssignmentHistoryRunningArgs = {
  key: Scalars['String']['input'];
};


export type QueryMedigapEnrollmentsArgs = {
  filter?: InputMaybe<MedigapFilterInput>;
  page: PageInput;
};


export type QueryPdpEnrollmentArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryPdpEnrollmentsArgs = {
  filter?: InputMaybe<PdpFilterInput>;
  page: PageInput;
};


export type QueryPushTemporalDataArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryReplayAssignmentHistoryProcessedCountArgs = {
  key: Scalars['String']['input'];
};


export type QueryRunClientDrugCostJobArgs = {
  key: Scalars['String']['input'];
};


export type QueryRunReplayAssignmentHistoryArgs = {
  key: Scalars['String']['input'];
};


export type QueryRunReplayAssignmentHistoryForClientArgs = {
  clientId: Scalars['UUID']['input'];
  key: Scalars['String']['input'];
};


export type QuerySalesQualifiedArgs = {
  agentId: Scalars['UUID']['input'];
  excludedTagsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  includedTagsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  pageInput: PageInput;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ClientViewSortInput>;
};


export type QueryScopeOfAppointmentArgs = {
  soaId: Scalars['UUID']['input'];
};


export type QuerySendInBlueHistoryByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryShortenedCostActivationLinkArgs = {
  clientId: Scalars['UUID']['input'];
};


export type QueryStartSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryStartSyncDrugCostActivationLinkCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryStopDrugCostActivationLinkSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryStopSyncCpausedustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QuerySyncDrugCostActivationLinkByClientIdArgs = {
  clientId: Scalars['UUID']['input'];
  requestKey: Scalars['String']['input'];
};


export type QuerySyncedCountSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QuerySyncedDrugCostActivationLinkCountSyncCustomerIoArgs = {
  requestKey: Scalars['String']['input'];
};


export type QueryTagArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserProfilesArgs = {
  filterInput?: InputMaybe<UserProfileFilterInput>;
  page: PageInput;
};

export enum Rating {
  None = 'NONE',
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export type SlaFilterInput = {
  agent?: InputMaybe<Scalars['UUID']['input']>;
  excludeBadNumber?: InputMaybe<Scalars['Boolean']['input']>;
  excludeDoNotCall?: InputMaybe<Scalars['Boolean']['input']>;
  excludeNotAttempted?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<DateRangeInput>;
  within2Hours?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SlaOutput = {
  __typename?: 'SLAOutput';
  agent?: Maybe<AgentViewOutput>;
  callsCount: Scalars['Int']['output'];
  clientId: Scalars['UUID']['output'];
  createdAt?: Maybe<Scalars['Instant']['output']>;
  firstCallAgentName?: Maybe<Scalars['String']['output']>;
  firstCallAt?: Maybe<Scalars['Instant']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  secondCallAgentName?: Maybe<Scalars['String']['output']>;
  secondCallAt?: Maybe<Scalars['Instant']['output']>;
  status: StatusDto;
  successfulCallsCount: Scalars['Int']['output'];
  tags: Array<AssignedTagViewOutput>;
};

export enum SnpType {
  CSnp = 'C_SNP',
  DSnp = 'D_SNP',
  ISnp = 'I_SNP'
}

export type ScopeOfAppointmentAdminInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ScopeOfAppointmentOutput = {
  __typename?: 'ScopeOfAppointmentOutput';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum ScopeOfAppointmentStatusDto {
  Opened = 'OPENED',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type ScopeOfAppointmentUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SendInBlueContactWithHistory = {
  __typename?: 'SendInBlueContactWithHistory';
  history: Array<Maybe<SendInBlueHistoryItem>>;
};

export type SendInBlueHistoryItem = {
  __typename?: 'SendInBlueHistoryItem';
  date: Scalars['Instant']['output'];
  fromEmail: Scalars['String']['output'];
  status: SendInBlueHistoryStatus;
  subject: Scalars['String']['output'];
  toEmail: Scalars['String']['output'];
};

export enum SendInBlueHistoryStatus {
  Blocked = 'BLOCKED',
  Clicks = 'CLICKS',
  Delivered = 'DELIVERED',
  Invalid = 'INVALID',
  Opened = 'OPENED',
  Unsubscribed = 'UNSUBSCRIBED'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Attempted = 'ATTEMPTED',
  Contacted = 'CONTACTED',
  Disenrolled = 'DISENROLLED',
  Disqualified = 'DISQUALIFIED',
  Draft = 'DRAFT',
  Duplicated = 'DUPLICATED',
  FollowUp = 'FOLLOW_UP',
  NotYetEngaged = 'NOT_YET_ENGAGED',
  OutOfService = 'OUT_OF_SERVICE',
  SalesQualified = 'SALES_QUALIFIED',
  Test = 'TEST'
}

export enum StatusDto {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Attempted = 'ATTEMPTED',
  Contacted = 'CONTACTED',
  Disenrolled = 'DISENROLLED',
  Disqualified = 'DISQUALIFIED',
  Draft = 'DRAFT',
  Duplicated = 'DUPLICATED',
  FollowUp = 'FOLLOW_UP',
  NotYetEngaged = 'NOT_YET_ENGAGED',
  OutOfService = 'OUT_OF_SERVICE',
  SalesQualified = 'SALES_QUALIFIED',
  Test = 'TEST'
}

export enum StatusOutput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export type Subscription = {
  __typename?: 'Subscription';
  agentCallAgenda: AgentCallAgendaOutput;
  agentCallAgendaWholeState: CurrentAgentCallAgendaStateOutput;
  appointments: Array<AppointmentOutput>;
  clientCard: ClientViewOutput;
  greetings: Scalars['String']['output'];
  newLeadsStatistics: NewLeadsStatisticsOutput;
  slas: PageableSlaOutput;
  urgentCallRequests: Array<UrgentRequestCallOutput>;
};


export type SubscriptionAppointmentsArgs = {
  agentId: Scalars['UUID']['input'];
};


export type SubscriptionClientCardArgs = {
  data: ClientDetailsSubscriptionInput;
};


export type SubscriptionSlasArgs = {
  filter: SlaFilterInput;
  pageInput: PageInput;
};

export type TagAssignedToClientInput = {
  expiresAt?: InputMaybe<Scalars['Instant']['input']>;
  id: Scalars['UUID']['input'];
};

export type TagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type TagOutput = {
  __typename?: 'TagOutput';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type TagViewOutput = {
  __typename?: 'TagViewOutput';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export enum TrackingIdTypeDto {
  Google = 'GOOGLE',
  Opix = 'OPIX'
}

export enum UrgentCallTypeDto {
  AsSoonAsPossible = 'AS_SOON_AS_POSSIBLE',
  ThisAfternoon = 'THIS_AFTERNOON',
  ThisMorning = 'THIS_MORNING'
}

export type UrgentRequestCallOutput = {
  __typename?: 'UrgentRequestCallOutput';
  clientId: Scalars['UUID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  urgentCallType?: Maybe<UrgentCallTypeDto>;
};

export type UserProfileFilterInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfileInput = {
  adviserEmail?: InputMaybe<Scalars['String']['input']>;
  adviserName?: InputMaybe<Scalars['String']['input']>;
  agentId?: InputMaybe<Scalars['UUID']['input']>;
  aptNumber?: InputMaybe<Scalars['String']['input']>;
  attributes?: InputMaybe<Array<ProfileAttributeInput>>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  cId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enrolled?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<FormName>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hasNoPreferredDoctors?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoPreferredPharmacies?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoPrescriptionsDrugs?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceType?: InputMaybe<Scalars['String']['input']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  medicareNumber?: InputMaybe<Scalars['String']['input']>;
  medicarePartAStartedAt?: InputMaybe<Scalars['String']['input']>;
  medicarePartBStartedAt?: InputMaybe<Scalars['String']['input']>;
  medigapQuoteId?: InputMaybe<Scalars['UUID']['input']>;
  options?: InputMaybe<Array<ProfileOption>>;
  pdpQuoteId?: InputMaybe<Scalars['UUID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['UUID']['input']>;
  quoteId?: InputMaybe<Scalars['UUID']['input']>;
  source?: InputMaybe<ProfileSource>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RequestStatusDto>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  tobacco?: InputMaybe<Scalars['Boolean']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfileOutput = {
  __typename?: 'UserProfileOutput';
  age?: Maybe<Scalars['Int']['output']>;
  aptNumber?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  cId?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hasNoPreferredDoctors?: Maybe<Scalars['Boolean']['output']>;
  hasNoPreferredPharmacies?: Maybe<Scalars['Boolean']['output']>;
  hasNoPrescriptionsDrugs?: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  medicareNumber?: Maybe<Scalars['String']['output']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']['output']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']['output']>;
  medigapQuoteId?: Maybe<Scalars['UUID']['output']>;
  pdpQuoteId?: Maybe<Scalars['UUID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileId: Scalars['UUID']['output'];
  quoteId?: Maybe<Scalars['UUID']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  tobacco?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type UserProfileSaveOutput = {
  __typename?: 'UserProfileSaveOutput';
  clientId?: Maybe<Scalars['UUID']['output']>;
  enrollmentId?: Maybe<Scalars['UUID']['output']>;
  profileId: Scalars['UUID']['output'];
  userId?: Maybe<Scalars['UUID']['output']>;
  userToken?: Maybe<UserToken>;
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAgentManager = 'ROLE_AGENT_MANAGER',
  RoleCsr = 'ROLE_CSR',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type UserToken = {
  __typename?: 'UserToken';
  accessToken: Scalars['String']['output'];
  idToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type PatchClientPersonalInfoMutationVariables = Exact<{
  clientId: Scalars['UUID']['input'];
  preferredDrugs?: InputMaybe<Array<PreferredDrugInput> | PreferredDrugInput>;
  preferredPharmacies?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  drugDeliveryType?: InputMaybe<ClientDrugDeliveryType>;
}>;


export type PatchClientPersonalInfoMutation = { __typename?: 'Mutation', patchClientPersonalInfo: boolean };

export type CalendlyInviteeQueryVariables = Exact<{
  eventUrl: Scalars['String']['input'];
  inviteeUrl: Scalars['String']['input'];
}>;


export type CalendlyInviteeQuery = { __typename?: 'Query', calendlyInvitee: { __typename?: 'InviteePersonalInfoOutput', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', userProfile?: { __typename?: 'UserProfileOutput', tobacco?: boolean | null, birthDate?: any | null, email?: string | null, firstName?: string | null, gender?: string | null, lastName?: string | null, phoneNumber?: string | null, quoteId?: any | null, pdpQuoteId?: any | null, medigapQuoteId?: any | null, state?: string | null, userId?: any | null, zip?: string | null, countyName?: string | null, profileId: any, clientId?: any | null, medicareNumber?: string | null, medicarePartAStartedAt?: string | null, medicarePartBStartedAt?: string | null, age?: number | null, fullName?: string | null } | null };

export type AgentByIdQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type AgentByIdQuery = { __typename?: 'Query', agentById: { __typename?: 'AgentOutput', b2bMeetingUrl?: string | null, email: string, firstName: string, id: any, lastName: string, picUrl?: string | null, phone?: string | null, enrollmentMeetingUrl?: string | null, planReviewUrl?: string | null, consultationUrl?: string | null } };

export type AgentAssignedToClientQueryVariables = Exact<{
  clientId: Scalars['UUID']['input'];
}>;


export type AgentAssignedToClientQuery = { __typename?: 'Query', agentAssignedToClient: { __typename?: 'AgentViewOutput', email: string, firstName: string, id: any, lastName: string, picUrl?: string | null, phone?: string | null, enrollmentMeetingUrl?: string | null, planReviewUrl?: string | null, consultationUrl?: string | null } };

export type SaveUserProfileMutationVariables = Exact<{
  data: UserProfileInput;
}>;


export type SaveUserProfileMutation = { __typename?: 'Mutation', saveUserProfile: { __typename?: 'UserProfileSaveOutput', enrollmentId?: any | null, profileId: any, userId?: any | null, userToken?: { __typename?: 'UserToken', accessToken: string, idToken: string, refreshToken: string } | null } };

export type SetNewPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
}>;


export type SetNewPasswordMutation = { __typename?: 'Mutation', setNewPassword: boolean };

export type HasEnrollmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasEnrollmentsQuery = { __typename?: 'Query', hasEnrollments: boolean };

export type MyEnrollmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyEnrollmentsQuery = { __typename?: 'Query', myEnrollments: Array<{ __typename?: 'EnrollmentListItemOutput', applicationDate?: any | null, bidId?: string | null, carrierName?: string | null, effectiveDate?: any | null, enrollmentType?: string | null, mgPlanKey?: string | null, mgPlanName?: string | null, naic?: string | null, planName?: string | null, planYear?: string | null, type: EnrollmentType, id: any, zip?: string | null, county?: string | null }> };


export const PatchClientPersonalInfoDocument = gql`
    mutation patchClientPersonalInfo($clientId: UUID!, $preferredDrugs: [PreferredDrugInput!], $preferredPharmacies: [String!], $drugDeliveryType: ClientDrugDeliveryType) {
  patchClientPersonalInfo(
    clientId: $clientId
    preferredDrugs: $preferredDrugs
    preferredPharmacies: $preferredPharmacies
    drugDeliveryType: $drugDeliveryType
  )
}
    `;
export type PatchClientPersonalInfoMutationFn = Apollo.MutationFunction<PatchClientPersonalInfoMutation, PatchClientPersonalInfoMutationVariables>;

/**
 * __usePatchClientPersonalInfoMutation__
 *
 * To run a mutation, you first call `usePatchClientPersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchClientPersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchClientPersonalInfoMutation, { data, loading, error }] = usePatchClientPersonalInfoMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      preferredDrugs: // value for 'preferredDrugs'
 *      preferredPharmacies: // value for 'preferredPharmacies'
 *      drugDeliveryType: // value for 'drugDeliveryType'
 *   },
 * });
 */
export function usePatchClientPersonalInfoMutation(baseOptions?: Apollo.MutationHookOptions<PatchClientPersonalInfoMutation, PatchClientPersonalInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchClientPersonalInfoMutation, PatchClientPersonalInfoMutationVariables>(PatchClientPersonalInfoDocument, options);
      }
export type PatchClientPersonalInfoMutationHookResult = ReturnType<typeof usePatchClientPersonalInfoMutation>;
export type PatchClientPersonalInfoMutationResult = Apollo.MutationResult<PatchClientPersonalInfoMutation>;
export type PatchClientPersonalInfoMutationOptions = Apollo.BaseMutationOptions<PatchClientPersonalInfoMutation, PatchClientPersonalInfoMutationVariables>;
export const CalendlyInviteeDocument = gql`
    query calendlyInvitee($eventUrl: String!, $inviteeUrl: String!) {
  calendlyInvitee(eventUrl: $eventUrl, inviteeUrl: $inviteeUrl) {
    email
    firstName
    lastName
    phone
  }
}
    `;

/**
 * __useCalendlyInviteeQuery__
 *
 * To run a query within a React component, call `useCalendlyInviteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendlyInviteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendlyInviteeQuery({
 *   variables: {
 *      eventUrl: // value for 'eventUrl'
 *      inviteeUrl: // value for 'inviteeUrl'
 *   },
 * });
 */
export function useCalendlyInviteeQuery(baseOptions: Apollo.QueryHookOptions<CalendlyInviteeQuery, CalendlyInviteeQueryVariables> & ({ variables: CalendlyInviteeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>(CalendlyInviteeDocument, options);
      }
export function useCalendlyInviteeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>(CalendlyInviteeDocument, options);
        }
export function useCalendlyInviteeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>(CalendlyInviteeDocument, options);
        }
export type CalendlyInviteeQueryHookResult = ReturnType<typeof useCalendlyInviteeQuery>;
export type CalendlyInviteeLazyQueryHookResult = ReturnType<typeof useCalendlyInviteeLazyQuery>;
export type CalendlyInviteeSuspenseQueryHookResult = ReturnType<typeof useCalendlyInviteeSuspenseQuery>;
export type CalendlyInviteeQueryResult = Apollo.QueryResult<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile {
  userProfile {
    tobacco
    birthDate
    email
    firstName
    gender
    lastName
    phoneNumber
    quoteId
    pdpQuoteId
    medigapQuoteId
    state
    userId
    zip
    countyName
    profileId
    clientId
    medicareNumber
    medicarePartAStartedAt
    medicarePartBStartedAt
    age @client
    fullName @client
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export function useUserProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileSuspenseQueryHookResult = ReturnType<typeof useUserProfileSuspenseQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const AgentByIdDocument = gql`
    query agentById($id: UUID!) {
  agentById(id: $id) {
    b2bMeetingUrl
    email
    firstName
    id
    lastName
    picUrl
    phone
    enrollmentMeetingUrl
    planReviewUrl
    consultationUrl
  }
}
    `;

/**
 * __useAgentByIdQuery__
 *
 * To run a query within a React component, call `useAgentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgentByIdQuery(baseOptions: Apollo.QueryHookOptions<AgentByIdQuery, AgentByIdQueryVariables> & ({ variables: AgentByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentByIdQuery, AgentByIdQueryVariables>(AgentByIdDocument, options);
      }
export function useAgentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentByIdQuery, AgentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentByIdQuery, AgentByIdQueryVariables>(AgentByIdDocument, options);
        }
export function useAgentByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AgentByIdQuery, AgentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AgentByIdQuery, AgentByIdQueryVariables>(AgentByIdDocument, options);
        }
export type AgentByIdQueryHookResult = ReturnType<typeof useAgentByIdQuery>;
export type AgentByIdLazyQueryHookResult = ReturnType<typeof useAgentByIdLazyQuery>;
export type AgentByIdSuspenseQueryHookResult = ReturnType<typeof useAgentByIdSuspenseQuery>;
export type AgentByIdQueryResult = Apollo.QueryResult<AgentByIdQuery, AgentByIdQueryVariables>;
export const AgentAssignedToClientDocument = gql`
    query agentAssignedToClient($clientId: UUID!) {
  agentAssignedToClient(clientId: $clientId) {
    email
    firstName
    id
    lastName
    picUrl
    phone
    enrollmentMeetingUrl
    planReviewUrl
    consultationUrl
  }
}
    `;

/**
 * __useAgentAssignedToClientQuery__
 *
 * To run a query within a React component, call `useAgentAssignedToClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentAssignedToClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentAssignedToClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useAgentAssignedToClientQuery(baseOptions: Apollo.QueryHookOptions<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables> & ({ variables: AgentAssignedToClientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>(AgentAssignedToClientDocument, options);
      }
export function useAgentAssignedToClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>(AgentAssignedToClientDocument, options);
        }
export function useAgentAssignedToClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>(AgentAssignedToClientDocument, options);
        }
export type AgentAssignedToClientQueryHookResult = ReturnType<typeof useAgentAssignedToClientQuery>;
export type AgentAssignedToClientLazyQueryHookResult = ReturnType<typeof useAgentAssignedToClientLazyQuery>;
export type AgentAssignedToClientSuspenseQueryHookResult = ReturnType<typeof useAgentAssignedToClientSuspenseQuery>;
export type AgentAssignedToClientQueryResult = Apollo.QueryResult<AgentAssignedToClientQuery, AgentAssignedToClientQueryVariables>;
export const SaveUserProfileDocument = gql`
    mutation saveUserProfile($data: UserProfileInput!) {
  saveUserProfile(input: $data) {
    enrollmentId
    profileId
    userId
    userToken {
      accessToken
      idToken
      refreshToken
    }
  }
}
    `;
export type SaveUserProfileMutationFn = Apollo.MutationFunction<SaveUserProfileMutation, SaveUserProfileMutationVariables>;

/**
 * __useSaveUserProfileMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileMutation, { data, loading, error }] = useSaveUserProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserProfileMutation, SaveUserProfileMutationVariables>(SaveUserProfileDocument, options);
      }
export type SaveUserProfileMutationHookResult = ReturnType<typeof useSaveUserProfileMutation>;
export type SaveUserProfileMutationResult = Apollo.MutationResult<SaveUserProfileMutation>;
export type SaveUserProfileMutationOptions = Apollo.BaseMutationOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>;
export const SetNewPasswordDocument = gql`
    mutation setNewPassword($newPassword: String!) {
  setNewPassword(newPassword: $newPassword)
}
    `;
export type SetNewPasswordMutationFn = Apollo.MutationFunction<SetNewPasswordMutation, SetNewPasswordMutationVariables>;

/**
 * __useSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordMutation, { data, loading, error }] = useSetNewPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useSetNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetNewPasswordMutation, SetNewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNewPasswordMutation, SetNewPasswordMutationVariables>(SetNewPasswordDocument, options);
      }
export type SetNewPasswordMutationHookResult = ReturnType<typeof useSetNewPasswordMutation>;
export type SetNewPasswordMutationResult = Apollo.MutationResult<SetNewPasswordMutation>;
export type SetNewPasswordMutationOptions = Apollo.BaseMutationOptions<SetNewPasswordMutation, SetNewPasswordMutationVariables>;
export const HasEnrollmentsDocument = gql`
    query hasEnrollments {
  hasEnrollments
}
    `;

/**
 * __useHasEnrollmentsQuery__
 *
 * To run a query within a React component, call `useHasEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEnrollmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasEnrollmentsQuery(baseOptions?: Apollo.QueryHookOptions<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>(HasEnrollmentsDocument, options);
      }
export function useHasEnrollmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>(HasEnrollmentsDocument, options);
        }
export function useHasEnrollmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>(HasEnrollmentsDocument, options);
        }
export type HasEnrollmentsQueryHookResult = ReturnType<typeof useHasEnrollmentsQuery>;
export type HasEnrollmentsLazyQueryHookResult = ReturnType<typeof useHasEnrollmentsLazyQuery>;
export type HasEnrollmentsSuspenseQueryHookResult = ReturnType<typeof useHasEnrollmentsSuspenseQuery>;
export type HasEnrollmentsQueryResult = Apollo.QueryResult<HasEnrollmentsQuery, HasEnrollmentsQueryVariables>;
export const MyEnrollmentsDocument = gql`
    query myEnrollments {
  myEnrollments {
    applicationDate
    bidId
    carrierName
    effectiveDate
    enrollmentType
    mgPlanKey
    mgPlanName
    naic
    planName
    planYear
    type
    id
    zip
    county
  }
}
    `;

/**
 * __useMyEnrollmentsQuery__
 *
 * To run a query within a React component, call `useMyEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEnrollmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEnrollmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>(MyEnrollmentsDocument, options);
      }
export function useMyEnrollmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>(MyEnrollmentsDocument, options);
        }
export function useMyEnrollmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>(MyEnrollmentsDocument, options);
        }
export type MyEnrollmentsQueryHookResult = ReturnType<typeof useMyEnrollmentsQuery>;
export type MyEnrollmentsLazyQueryHookResult = ReturnType<typeof useMyEnrollmentsLazyQuery>;
export type MyEnrollmentsSuspenseQueryHookResult = ReturnType<typeof useMyEnrollmentsSuspenseQuery>;
export type MyEnrollmentsQueryResult = Apollo.QueryResult<MyEnrollmentsQuery, MyEnrollmentsQueryVariables>;