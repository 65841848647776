import { ProviderOutput } from '@coverright/data-access/types/medicare';
import * as React from 'react';
import { TextInput } from '@coverright/ui/inputs';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useLogEvent } from '@coverright/shared/analytics';
import { AutocompleteRenderInputParams } from '@mui/material';

interface Props {
  params: AutocompleteRenderInputParams;
}

export function DoctorAutocompleteInput({ params }: Props) {
  const logEvent = useLogEvent();

  return (
    <TextInput
      autoFocus
      placeholder={"Enter doctor's name"}
      name={'search-doctor-input'}
      label={'Search Doctor Name'}
      data-test={'search-doctor-input'}
      className={'mb-0'}
      onClick={() => {
        logEvent('click', {
          element_title: 'Search Doctor Name',
          element_id: 'search-doctor-input',
          element_type: 'input',
        });
      }}
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <MagnifyingGlass
            className={'ml-16'}
            size={24}
            color="#1C434F"
            weight="regular"
          />
        ),
      }}
    />
  );
}
