import { styled, SxProps } from '@mui/material';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import * as React from 'react';
import * as _ from 'lodash';
import { useIsV3Theme } from './';

export interface ResponsiveTableColumn<T> {
  title: ((i: number) => string | JSX.Element) | string | JSX.Element;
  value: (v: T, i: number) => string | JSX.Element;
}

interface ResponsiveTableProps<T> {
  columns: ResponsiveTableColumn<T>[];
  rows: T[];
  sx?: SxProps;
  footer?: JSX.Element;
}

// todo move styles to theme


export function ResponsiveTable<T>(props: ResponsiveTableProps<T>) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  return useMemo(() => {
    return phone ? <MobileView {...props} /> : <DesktopView {...props} />;
  }, [phone, props]);
}

function MobileView<T>(props: ResponsiveTableProps<T>) {
  return (
    <Stack spacing={2}>
      {props.rows.map((r, i) => (
        <ThemedTable
          key={'table' + i}
          sx={props?.sx}
          cellPadding={8}
          cellSpacing={0}
        >
          <thead>
            <tr>
              <td>
                {_.isFunction(props.columns[0].title)
                  ? props.columns[0].title(0)
                  : props.columns[0].title}
              </td>
              <td>{props.columns[0].value(r, i)}</td>
            </tr>
          </thead>

          <tbody>
            {props.columns.slice(1).map((c, j) => (
              <tr key={'row' + j}>
                <td>{_.isFunction(c.title) ? c.title(j) : c.title}</td>
                <td>{c.value(r, i)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>{props.footer}</tfoot>
        </ThemedTable>
      ))}
    </Stack>
  );
}

function DesktopView<T>(props: ResponsiveTableProps<T>) {
  return (
    <ThemedTable
      sx={props?.sx}
      cellPadding={8}
      cellSpacing={0}
    >
      <thead>
        <tr>
          {props.columns.map((c, i) => (
            <td key={'col' + i}>
              {_.isFunction(c.title) ? c.title(i) : c.title}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((r, i) => (
          <tr key={'row' + i}>
            {props.columns.map((c, j) => (
              <td key={'row' + i + 'col' + j}>{c.value(r, i)}</td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>{props.footer}</tfoot>
    </ThemedTable>
  );
}

const ThemedTable = styled('table', {
  name: 'ResponsiveTable', // The component name
  slot: 'root', // The slot name
})(({theme}) => ({
  width: '100%',
  '*': {
    fontSize: 12,
  },
  [theme.breakpoints.up('sm')]: {
    tableLayout: 'fixed'
  },
  [theme.breakpoints.down('sm')]: {
    '& tbody tr td': {
      padding: '13px 0',
    },
    '& tbody tr td:first-of-type': {
      paddingRight: '10px',
    },
  },
  '& td': {
    verticalAlign: 'top',
    borderBottom: '1px solid #E8ECED',
    padding: '13px 0 13px 15px',
    '& *': {
      maxWidth: 300,
    },
  },
  '& thead td': {
    backgroundColor: '#F0F7FA',
    fontWeight: 600,
  },
  '& tbody td': {
    backgroundColor: '#fff',
  },
}))
