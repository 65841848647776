import * as React from "react";
import { Box } from '@mui/material';
import { withErrorBoundary } from '@coverright/ui/shared';


interface LayoutWithNotificationProps {
  children: JSX.Element,
  NotificationComponent: JSX.Element,
  showNotification: boolean
}

const Layout = ({children, NotificationComponent, showNotification}: LayoutWithNotificationProps) => (
  <Box display={'flex'} flex={1} flexDirection={'column'}>
    {showNotification && <Box sx={{height: '50px'}}>
      <Box sx={{position: 'fixed', top: '0', left: '0', right: '0', zIndex: 10000}}>
        {NotificationComponent}
      </Box>
    </Box>}
    <Box display={'flex'} flexDirection={'column'}>
      {children}
    </Box>
  </Box>
)

export const LayoutWithNotification = withErrorBoundary(Layout, true);
