import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { DrugPackageOutput, QueryPackageDescriptionByRxcuiArgs } from '@coverright/data-access/types/medicare';

export  function useGetPackagesDescription() {
  return useLazyQuery<{packageDescriptionByRxcui: DrugPackageOutput}, QueryPackageDescriptionByRxcuiArgs>(
    gql(getPackageRequest),
    {
      fetchPolicy: 'no-cache'
    }
  );
}

const getPackageRequest = `
query ($rxcui: String!) {
  packageDescriptionByRxcui(rxcui: $rxcui) {
    packageDescription
    unitType
  }
}
`;
