import { IZipCountyFips } from '@coverright/data-access/medicare';

export const getState = (
  countyName: string,
  counties: IZipCountyFips[]
): string =>
  counties.find((c) => c.countyname === countyName)?.state || 'unknown';

const sessionKey = 'NOT_SERVED_SENT';

const getSentRequests = (): string[] =>
  JSON.parse(sessionStorage.getItem(sessionKey) || '[]');

export const addZipToSentRequests = (zip: string) =>
  sessionStorage.setItem(
    sessionKey,
    JSON.stringify([...getSentRequests(), zip])
  );

export const isRequestSent = (zip: string) => getSentRequests().includes(zip);
