import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { CreateAccountForm } from '@coverright/widgets';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import paths from '../../../config/router-paths';
import { getDisclamers, getStoredUser } from '@coverright/utils';
import { useMaPlansCount } from '@coverright/data-access/medicare';

export function SignUpPersonalInfo() {
  const navigate = useNavigate();
  const { maPlansCount, maOrgCount } = useMaPlansCount(getStoredUser()?.zip);

  const onSubmit = () => {
    navigate(
      paths.plans +
      getStoredUser()?.zip +
      '/' +
      getStoredUser()?.countyName +
      document.location.search
    );
  };

  return (
    <div>
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Box sx={{ p: '40px', width: 1, maxWidth: 609 }}>
          <Typography
            className={'bold'}
            align={'center'}
            sx={{ fontSize: 26, lineHeight: '32px' }}
          >
            Provide your contact information
          </Typography>
          <div className={'h-40'} />
          <CreateAccountForm
            onLoadingChange={() => {
            }}
            columns={1.5}
            signInLink={''}
            onSuccess={onSubmit}
            setFormik={() => {
            }}
            source={ProfileSource.MaMarketplace}
            showPhone
          />
          <Box sx={{ height: '40px' }} />
          <div>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
              <img src={'/assets/img/question.svg'} width={24} height={24} />
              <Typography className={'fs-16 lh-24 bold'}>
                We never sell your information
              </Typography>
            </Box>
            <Typography sx={{ color: '#4D4D4D' }} className={'fs-16 lh-24'}>
              We never sell your information or personal details to third
              parties. Your information is stored on HIPAA-compliant data
              storage with 256-bit encryption.
            </Typography>
          </div>
          <Box sx={{ height: '20px' }} />
          <Button
            sx={{ minWidth: 250, width: '100%', borderRadius: '8px' }}
            onClick={() => onSubmit()}
            variant={'contained'}
            size={'large'}
            color={'primary'}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Box>

      <div>
        <Box sx={{ maxWidth: 586, m: 'auto' }}>
          <div className={'ph-35 mt-16'}>
            <Typography className={'fs-11 lh-13 thin'} color={'textPrimary'}>
              {getDisclamers('PERSONAL_INFO', {maPlansCount, maOrgCount})}
            </Typography>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default SignUpPersonalInfo;
