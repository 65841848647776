import React from "react";
import { Box, Card, useMediaQuery, useTheme } from '@mui/material';
import MonthlyCostCalculator from '../plan-list/components/MonthlyCostCalculator';
import HealthcarePreferences from '../plan-list/components/HealthcarePreferences';

const FavoritesSideContent = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('lg'));

  return <Box sx={{minWidth: 304, maxWidth: 304, display: 'flex', flexDirection: 'column', gap: '24px'}}>
    <Card sx={{p: 3, mt: 3}}>
      <HealthcarePreferences />
    </Card>
    <Card sx={{p: 3}}>
      <MonthlyCostCalculator />
    </Card>
  </Box>
}

export default FavoritesSideContent;
