import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { QueryIsPhoneValidArgs } from '@coverright/data-access/types/enrollment';
import { getNumbersFromString } from '@coverright/utils';

export function useIsPhoneValid(options?: LazyQueryHookOptions<{isPhoneValid: boolean}, QueryIsPhoneValidArgs>) {
  return useLazyQuery<{isPhoneValid: boolean}, QueryIsPhoneValidArgs>(
    gql(request),
    {
      ...options,
      client: enrollmentClient
    }
  );
}

export function validatePhone(value?: string) {
  if (!value) return false

  if (/\+1 \(\d{3}\) \d{3}-\d{4}/gm.test(value.toString())) {
    value = '+' + value.replace(/\D/g, '');
  }

  if (/\+\d{11}/.test(value)) {
    return new Promise((resolve, reject) => {
      if (value === '+16460000000') {
        resolve(true);
      } else {
        enrollmentClient.query<{ isPhoneValid: boolean }, QueryIsPhoneValidArgs>({
          query: gql(request),
          variables: { phone: value || '' }
        }).then(res => {
          resolve(res.data.isPhoneValid)
        })
      }
    }) as any
  }
}

const request = `
query ($phone: String!) {
  isPhoneValid(phone: $phone)
}
`;
