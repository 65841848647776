import React from 'react';
import { InputProps } from '../text-input';
import { AdornmentWrapper } from '../ui/adornment-wrapper';
import { ErrorAdornment } from '../ui/error-adornment';
import { LoadingAdornment } from '../ui/loading-adornment';
import { TickAdornment } from '../ui/tick-adornment';

export function getEndAdornment(props: InputProps, isValid: boolean) {
  if (props.InputProps?.endAdornment) {
    if (props.error) {
      return <AdornmentWrapper>
        {!!props.loading && <LoadingAdornment sx={props.endAdornmentSx} />}
        <ErrorAdornment sx={props.endAdornmentSx} />
        {props.InputProps?.endAdornment}
      </AdornmentWrapper>
    } else if (isValid && props.tickPosition !== 'start') {
      return <AdornmentWrapper>
        {!!props.loading && <LoadingAdornment sx={props.endAdornmentSx} />}
        {!props.hideTick && <TickAdornment sx={props.endAdornmentSx} />}
        {props.InputProps?.endAdornment}
      </AdornmentWrapper>
    } else {
      return props.InputProps?.endAdornment
    }
  } else {
    if (props.error) {
      if (props.loading) {
        return <AdornmentWrapper>
          <LoadingAdornment sx={props.endAdornmentSx} />
          <ErrorAdornment sx={props.endAdornmentSx} />
        </AdornmentWrapper>
      }
      return <ErrorAdornment sx={props.endAdornmentSx} />
    } else if (isValid) {
      if (props.tickPosition !== 'start' && !props.hideTick) {
        if (props.loading) {
          return <AdornmentWrapper>
            <LoadingAdornment sx={props.endAdornmentSx} />
            <TickAdornment sx={props.endAdornmentSx} />
          </AdornmentWrapper>
        }
        return <TickAdornment sx={props.endAdornmentSx} />
      } else if (props.loading) {
        return <LoadingAdornment sx={props.endAdornmentSx} />
      }
    } else {
      if (props.loading) {
        return <LoadingAdornment sx={props.endAdornmentSx} />
      }
    }
  }
  return undefined;
}
