import React from "react";
import { Typography } from '@mui/material';

export const PlanParam = React.memo((props: PlanParamProps) => <div className={`${props.className}`}>
  {props.label && <Typography className={`fs-11 ${props.labelClassName}`}>{props.label}:</Typography>}
  {typeof props.value === 'string' && <Typography className={`fs-12 dark-green ${props.valueClassName} medium`}>{props.value}</Typography>}
  {typeof props.value !== 'string' && <>{props.value}</>}
</div>);

interface PlanParamProps {
  value: string | JSX.Element
  label?: string,
  className?: string,
  valueClassName?: string,
  labelClassName?: string,
}

