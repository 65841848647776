import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveCompareOfferArgs } from '@coverright/data-access/types/medicare';

export function useSaveCompareOffer(options?: MutationHookOptions<{saveCompareOffer: string}, MutationSaveCompareOfferArgs>) {
  return useMutation<{saveCompareOffer: string}, MutationSaveCompareOfferArgs>(gql(mutation), {
    ...options,
  })
}

const mutation = `
mutation ($compareOffer: CompareOfferInput!) {
  saveCompareOffer(compareOffer: $compareOffer)
}
`;
