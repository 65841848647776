import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CRModal } from '@coverright/ui/shared';
import {
  ProviderAddressOutput,
  ProviderOutput,
} from '@coverright/data-access/types/medicare';
import { handleCollectionChange } from '@coverright/utils';
import { V3Checkbox } from '@coverright/ui/inputs';
import { ModalCloseButton } from '@coverright/shared/ui';
import { useAddresses } from '../api/use-addresses';

type AddressSelectionModalProps = {
  onClose: (addresses?: ProviderAddressOutput[]) => void;
  doctor: ProviderOutput;
  addresses: ProviderAddressOutput[];
};

export default function AddressSelectionModal(
  props: AddressSelectionModalProps
) {
  const [selected, setSelected] = React.useState(
    props.doctor.addresses.map((a) => a.id)
  );

  const addresses = useAddresses(props.doctor.npi || '', props.addresses);

  const handleSelectClick = () => {
    props.onClose(addresses.filter((a) => selected.includes(a.id)));
  };

  const specialty = props.doctor.addresses[0]?.specialty;

  return (
    <CRModal
      width={667}
      open
      onClose={() => props.onClose()}
      paperPadding={'24px'}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '-8px',
            mr: '-8px',
          }}
        >
          <ModalCloseButton onClick={() => props.onClose()} />
        </Box>

        <Typography variant={'h4'} className={'lh-32 mt-24'} align={'center'}>
          Where do you normally see this doctor?
        </Typography>

        <Typography variant={'h4'} className={'lh-32 mt-24'} align={'center'}>
          {props.doctor.name}
        </Typography>

        {specialty && (
          <Typography className={'mt-8'} align={'center'}>
            {specialty}
          </Typography>
        )}

        <Box sx={{ height: 400, my: 3 }}>
          {!!addresses.length && (
            <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
              {addresses.map((address) => (
                <V3Checkbox
                  variant={'standart'}
                  key={address.id}
                  name={address.id}
                  label={[address.addressLine1, address.addressLine2].join(
                    ', '
                  )}
                  checked={selected.includes(address.id)}
                  onChange={() => {
                    setSelected((prev) =>
                      handleCollectionChange(address.id, prev)
                    );
                  }}
                />
              ))}
            </Box>
          )}
        </Box>

        <Box display={'flex'} mt={'42px'} justifyContent={'center'}>
          <Button
            sx={{ minWidth: 250 }}
            variant={'contained'}
            disabled={!selected.length}
            onClick={handleSelectClick}
          >
            Select
          </Button>
        </Box>
      </>
    </CRModal>
  );
}
