import { Collapse, Grid } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as _ from 'lodash';
import * as React from 'react';
import { validatePhone } from '@coverright/data-access/enrollment';
import {
  ProfileAttributeInput,
  ProfileSource,
} from '@coverright/data-access/types/enrollment';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { useLogEvent } from '@coverright/shared/analytics';
import { getStoredUser, setStoredUser, useDebouncedMemoF } from '@coverright/utils';
import { GraphQLErrorType } from '@coverright/data-access/apollo-clients';
import { FormikTextInput, PhoneNumberInput } from '@coverright/ui/inputs';
import { ForwardedRef, forwardRef } from 'react';
import { useCreateProfile } from './api/use-create-profile';

const validationSchema = {
  email: yup
    .string()
  //  .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
};
const phoneField = yup
  .string()
  .required('Phone number is required')
  .test('test-phone', 'Please enter valid phone number', validatePhone);

type CreateAccountFormProps = {
  setFormik?: (formik: any) => void;
  onSuccess: () => void;
  onError?: (e?: any) => void;
  columns?: number;
  signInLink: string;
  showPhone: boolean;
  source: ProfileSource;
  onLoadingChange: (value: boolean) => void;
  agentId?: string;
  adviserName?: string;
  adviserEmail?: string;
  showAll?: boolean;
  attributes?: ProfileAttributeInput[];
};

export const CreateAccountForm = forwardRef(
  (props: CreateAccountFormProps, ref: ForwardedRef<HTMLFormElement>) => {
    const { init } = React.useContext(KeycloakContext);
    const logEvent = useLogEvent();

    const createProfile = useCreateProfile();

    const formik = useFormik({
      initialValues: {
        email: getStoredUser()?.email || '',
        firstName: getStoredUser()?.firstName || '',
        lastName: getStoredUser()?.lastName || '',
        phoneNumber: getStoredUser()?.phoneNumber || '',
      },
      validationSchema: yup.object(
        props.showPhone
          ? { ...validationSchema, phoneNumber: phoneField }
          : validationSchema
      ),
      onSubmit: (values) => {
        if (formik.isValid) {
          onSubmit(values);
        }
      },
      validateOnChange: true,
    });

    const onSubmit = async (values: typeof formik.initialValues) => {
      props.onLoadingChange(true);
      setStoredUser({ ...getStoredUser(), ...values });
      try {
        const userProfile = await createProfile({
          email: _.trim(values.email),
          firstName: _.trim(values.firstName),
          lastName: _.trim(values.lastName),
          phoneNumber: '+' + values.phoneNumber.replace(/\D/g, ''),
          source: props.source,
          agentId: props.agentId,
          adviserEmail: props.adviserEmail,
          adviserName: props.adviserName,
          attributes: props.attributes,
        });

        const userToken = userProfile?.userToken;

        if (userToken) {
          await init(userToken);
          props.onSuccess();
        } else {
          props.onError && props.onError();
        }
      } catch (error: any) {
        if (error?.graphQLErrors) {
          switch (error.graphQLErrors[0]?.extensions?.type) {
            case GraphQLErrorType.INVALID_EMAIL:
            case GraphQLErrorType.ALREADY_EXISTS: {
              formik.setFieldError('email', 'IN_USE');
              logEvent('Error - user already exists', {
                email: _.trim(values.email),
              });
              break;
            }
          }
        }
        props.onError && props.onError();
      } finally {
        props.onLoadingChange(false);
      }
    };

    return (
      <form ref={ref} onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          columns={props.columns === 1.5 ? undefined : props.columns}
        >
          <Grid item xs={12} md={props.columns === 1.5 ? 6 : 12}>
            <FormikTextInput
              formik={formik}
              onClick={() =>
                logEvent('click', {
                  element_title: 'First Name',
                  element_id: 'firstName',
                  element_type: 'input',
                })
              }
              name={'firstName'}
            />
          </Grid>
          <Grid item xs={12} md={props.columns === 1.5 ? 6 : 12}>
            <FormikTextInput
              formik={formik}
              onClick={() =>
                logEvent('click', {
                  element_title: 'Last Name',
                  element_id: 'lastName',
                  element_type: 'input',
                })
              }
              name={'lastName'}
            />
          </Grid>
          <Grid item xs={12} md={props.columns === 1.5 ? 12 : 6}>
            <Collapse
              in={
                props.showAll ||
                (!!formik.values.lastName && !!formik.values.firstName)
              }
            >
              <FormikTextInput
                formik={formik}
                onClick={() =>
                  logEvent('click', {
                    element_title: 'Email',
                    element_id: 'email',
                    element_type: 'input',
                  })
                }
                valid={/^[.-]?\w+@[.-]?\w+(\.\w{2,3})+$/.test(formik.values.email)}
                placeholder={'Enter your email address'}
                helperText={
                  formik.touched.email && formik.errors.email ? (
                    formik.errors.email === 'IN_USE' ? (
                      <>
                        Looks like that email is already in use. Please{' '}
                        <a href={props.signInLink} style={{ color: 'red' }}>
                          sign in
                        </a>
                      </>
                    ) : (
                      formik.errors.email
                    )
                  ) : undefined
                }
                name={'email'}
              />
            </Collapse>
          </Grid>
          {props.showPhone && (
            <Grid item xs={12} md={props.columns === 1.5 ? 12 : 6}>
              <Collapse in={props.showAll || !!formik.values.email}>
                <PhoneNumberInput
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  onClick={() =>
                    logEvent('click', {
                      element_title: 'Phone Number',
                      element_id: 'phoneNumber',
                      element_type: 'input',
                    })
                  }
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="(123) 456-7890"
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </Collapse>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
);
