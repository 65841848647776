
import * as _ from 'lodash';
import { KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import {
  getStoredUser,
  removeClientId,
  resetStoredUser,
  setMedigapQuoteId,
  setPdpQuoteId,
  setQuoteId,
  setStoredUser
} from '@coverright/utils';
import { useClientView } from '@coverright/data-access/enrollment';
import { ClientViewOutput } from '@coverright/data-access/types/enrollment';
import * as React from 'react';

interface AdminContextState {
  clientId: string;
  clientView: ClientViewOutput;
  clear: () => void;
}

export const AdminContext = React.createContext<AdminContextState | null>(null);

interface AdminContextProviderProps {
  clientId?: string;
}

export function AdminContextProvider(props: React.PropsWithChildren<AdminContextProviderProps>) {
  const [isCleared, setIsCleared] = React.useState(false);

  const {authenticated, keycloak} = React.useContext(KeycloakContext);

  const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN) || keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_TECH_STAFF);

  const clear = React.useCallback(() => {
    setIsCleared(true)
    removeClientId();
  }, [setIsCleared]);

  if (authenticated && isAdmin && props.clientId && !isCleared) {
    return <AdminContextProviderWithSubscription clientId={props.clientId} onCleared={clear}>
      {props.children}
    </AdminContextProviderWithSubscription>
  } else {
    return <AdminContext.Provider value={null}>
      {props.children}
    </AdminContext.Provider>
  }
}

function AdminContextProviderWithSubscription(props: React.PropsWithChildren<{clientId: string, onCleared: () => void}>) {
  const [prevView, setPrevView] = React.useState<ClientViewOutput>();
  const clientView = useClientView(props.clientId);

  const [adminState, setAdminState] = React.useState<AdminContextState | null>(null);

  const clear = React.useCallback(() => {
    resetStoredUser();
    document.location.href = document.location.href.split("?")[0];
    props.onCleared();
  }, []);

  React.useEffect(() => {
    if (clientView != null && !_.isEqual(clientView, prevView)) {
      setAdminState({
        clientId: props.clientId,
        clientView: clientView,
        clear: clear
      });
    }
    setPrevView(clientView);
    if (clientView) {
      setStoredUser({
        ...getStoredUser(),
        email: clientView.email || undefined,
        firstName: clientView.firstName || undefined,
        lastName: clientView.lastName || undefined,
        zip: clientView.zip || undefined,
        gender: clientView.gender || undefined,
        tobacco: typeof clientView.tobacco !== 'undefined' ? String(clientView.tobacco) : undefined,
        countyName: clientView.countyName || undefined,
        birthDate: clientView.birthDate || undefined,
        profileId: clientView.profileId || undefined,
        phoneNumber: clientView.phoneNumber || undefined,
        state: clientView.state || undefined,
        userId: clientView.userId || undefined,
      })
      const urlParams = new URLSearchParams(window.location.search);
      if (clientView.quoteId && !urlParams.has('adminQuote')) {
        setQuoteId(clientView.quoteId);
      }
      if (clientView.medigapQuote?.id && !urlParams.has('adminQuote')) {
        setMedigapQuoteId(clientView.medigapQuote?.id);
      }
      if (clientView.pdpQuote?.id && !urlParams.has('adminQuote')) {
        setPdpQuoteId(clientView.pdpQuote?.id);
      }
    }
  }, [clientView]);

  return adminState ? <AdminContext.Provider value={adminState}>
    {props.children}
  </AdminContext.Provider> : null
}
